<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：客户·公共的搜索页面
开始时间：2017-11-9
开发人员：陈立珍
最后修改：2018-7-23
备注说明：如需修改请联系开发人员
-->

<template>
	<div id="search" class="crm-search">
		<!--搜索框-->
		<div class="common-crm-search">
			<el-input class="searchInput" size="small" v-model="value4" placeholder="请输入客户相关的信息..." @focus='sfShow' @blur='sfHide'></el-input>
			<span class="searchArrow">
				<span @click='saShow' class="cus-searchArrow-1"><i class="icon iconfont icon-xiangxia-xiao"></i></span>
					<span class="crm-search-btn search-btn" @click='querySimple'>
					<i class="icon iconfont icon-sousuo"></i>
				</span>
			</span>
		</div>
		<!--高级设置sa=searchArrow-->
		<div class="sa dn">
			<div class="sa-close" @click='saHide'></div>
			<div class="sbk1">
				<table cellpadding="0" cellspacing="0" class="w100 sa-table">
					<tr>
						<td class="sa-td"><span>客户信息</span></td>
						<td>
							<input type="text" v-model="value1" class="bks2" placeholder="请输入客户相关信息..." />
						</td>
					</tr>
					<tr>
						<td class="sa-td"><span>联系人信息</span></td>
						<td><input type="text" v-model="value2" class="bks2" placeholder="请输入联系人相关信息..." />
						</td>
					</tr>
					<!--<tr>
						<td class="sa-td">客户分类</td>
						<td class="sa-td">
			              <select v-model="selected">
			                <option value="0">请选择类别</option>
			                <option v-for="option in customerclassifyall" :value="option.id">{{option.name}}</option>
			              </select>
						</td>
					</tr>-->
					<!--<tr>
						<td class="sa-td">客户状态</td>
						<td class="sa-td">
			              <select v-model="selected2">
			                <option value="0">请选择类别</option>
			                <option v-for="option in customerstatusall" :value="option.id">{{option.name}}</option>
			              </select>
						</td>
					</tr>-->
					<tr>
						<td class="sa-td"><span>录入时间</span>
						</td>
						<td>
							<div class="q-left">
								<el-date-picker style="width: 205px;" v-model="value3" type="daterange" placeholder="选择日期范围" :editable="false" :picker-options="pickerOptions0"></el-date-picker>
							</div>
						</td>
					</tr>
				</table>
			</div>
			<div class="sbk2">
				<div class="btnbox">
					<div class="btangz">
						<div class="btan-1" @click='queryHigh'>
							<a>
								<span>查 询</span>
							</a>
						</div>
						<div class="btan-2" @click='reset'>
							<a>
								<span>重 置</span>
							</a>
						</div>
						<div class="btan-2" @click='saHide'>
							<a>
								<span>取 消</span>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!--搜索框focus时，下拉选项,sf=searchFocus-->
		<div class="sf dn">
			<ul>
				<li>包含<span style="color: #1965B6;">Search</span>的邮件<span style="color: #bfbfbf;">(全文搜索)</span></li>
				<li>包含<span style="color: #1965B6;">Search</span>的邮件<span style="color: #bfbfbf;">(主题)</span></li>
				<li>包含<span style="color: #1965B6;">Search</span><span>(发件人+收件人)</span></li>
				<li>包含<span style="color: #1965B6;">Search</span><span>(主题+发件人)</span></li>
				<li @click='saShow'>高级搜索</li>
			</ul>
		</div>
	</div>
</template>
<script>
	import store from '../../../vuex/store.js'
	import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
	import '../../../vuex/mutations.js';
	export default {
		f: false,
		name: 'mailSearch',
		data() {
			return {
				value1: '', //客户信息输入值
				value2: '', //联系人信息输入值
				value3: '', //日期输入值
				value4: '', //简单搜索输入值
				//selected:0,//客户分类选择id
				//selected2:0,//客户状态选择id
				pickerOptions0: {
					disabledDate(time) {
						return time.getTime() > Date.now();
					}
				},
			}
		},
		computed: {
			...mapState({
				customerclassifyall: state => state.customerclassifyall,
				customerstatusall: state => state.customerstatusall,
			}),
		},
		methods: {
			...mapMutations({
				getCustomerClassifyAll: 'getCustomerClassifyAll',
				getCustomerStatusAll: 'getCustomerStatusAll',
			}),
			//展示高级搜索
			saShow() {
				if(this.f) {
					$('.sa').hide(400)
				} else {
					$('.sa').show(400)
				}
				this.f = !this.f
			},
			//隐藏高级搜索
			saHide() {
				$('.sa').hide(400)
			},
			sfShow() {
				$('.sa').hide(400)
			},
			sfHide() {

			},
			//重置
			reset() {
				this.value1 = '';
				this.value2 = '';
				this.value3 = '';
				//this.selected = 0;
				//this.selected2 = 0;
			},
			//高级查询
			queryHigh() {
				if(this.value1.trim() == '' && this.value2.trim() == '') {
					this.$message({
						showClose: true,
						message: '客户相关信息和联系人相关信息必须输入一项！',
						type: 'warning',
						duration: 2000
					});
					return;
				}
				var cus_info = this.value1.trim();
				var link_info = this.value2.trim();
				var start_date = 'None';
				var end_date = 'None';
				if(this.value1.trim() == '') {
					cus_info = 'None';
				}
				if(this.value2.trim() == '') {
					link_info = 'None';
				}
				if(this.value3 != '') {
					var myDate = new Date(this.value3.toString().split(",")[0]);
					var year = myDate.getFullYear().toString();
					var month = (myDate.getMonth() + 1).toString();
					var day = myDate.getDate().toString();
					if(month.length == 1) {
						month = "0" + month;
					}
					if(day.length == 1) {
						day = "0" + day;
					}
					start_date = year + "-" + month + "-" + day;
					myDate = new Date(this.value3.toString().split(",")[1]);
					year = myDate.getFullYear().toString();
					month = (myDate.getMonth() + 1).toString();
					day = myDate.getDate().toString();
					if(month.length == 1) {
						month = "0" + month;
					}
					if(day.length == 1) {
						day = "0" + day;
					}
					end_date = year + "-" + month + "-" + day;
				}
				var info = cus_info + "###" + link_info + "###" + start_date + "###" + end_date;
				this.$emit('queryCustomerHigh', info);
				$('.sa').hide(400);
			},
			//简单查询
			querySimple() {
				if($.trim(this.value4) == '') {
					this.$message({
						showClose: true,
						message: '请输入您要搜索的信息！',
						type: 'warning',
						duration: 2000
					});
					return;
				}
				this.$emit('queryCustomerSimple', $.trim(this.value4));
			}
		},
		mounted: function() {
			//		if(this.customerclassifyall == ''){
			//      	this.getCustomerClassifyAll();
			//    	}
			//  	if(this.customerstatusall == ''){
			//      	this.getCustomerStatusAll();
			//    	}
			$('body').click(function(e) {
				var target = $(e.target);
				// 如果#overlay或者#btn下面还有子元素，可使用
				// !target.is('#btn *') && !target.is('#overlay *')
				if(!target.is('#search *') && !target.is('.search *')) {
					if($('.sa').is(':visible')) {
						$('.sa').hide(400)
					}
				}
			});
		}
	}
</script>

<style lang="less">
	@radius: 2px;
	.crm-search {
		position: relative;
		z-index: 15;
		background-color: #fff;
		.sf {
			display: none;
			position: absolute;
			top: 28px;
			right: 40px;
			width: 250px;
			font-size: 12px;
			padding: 5px 0;
			background: #FFF;
			z-index: 9999;
			text-align: left;
			border-radius: @radius;
			box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
		}
		.sf ul li {
			padding-left: 30px;
			padding-right: 20px;
			line-height: 30px;
			white-space: nowrap;
			cursor: pointer;
		}
		.sf ul li:last-child {
			line-height: 40px;
			border-top: 1px solid #ddd;
			padding-left: 30px;
		}
		/*搜索框*/
		.common-crm-search {
			position: absolute;
			right: 40px;
			top: -5px;
			cursor: pointer;
			// border: 1px solid black;
		}
		.common-crm-search .searchInput {
			float: left;
			width: 220px;
			height: 30px;
			margin-right: 20px;
			// border: 1px solid blue;
		}
		.common-crm-search .searchArrow {
			position: relative;
			margin-left: -22px;
			float: left;
			z-index: 2;
			// border: 1px solid red;
		}
		.cus-searchArrow-1{
			position: absolute;
  			display: inline-block;
  			margin: 1px -20px;
		}
		.common-crm-search .searchArrow .icon-xiangxia-xiao {
			color: #989696;
			line-height: 2;
		}
		.common-crm-search .searchArrow .crm-search-btn {
			display: inline-block;
			line-height: 1.15;
			white-space: nowrap;
			cursor: pointer;
			height: 30px;
			width: 40px;
		}
		.common-crm-search .searchArrow .crm-search-btn:hover {
			background-color: #4daff7;
		}
		.common-crm-search .searchArrow .search-btn {
			color: #fff;
			background-color: #20a0ff;
			border-color: #20a0ff;
			text-align: center;
		}
		.common-crm-search .searchArrow .search-btn .icon-sousuo {
			font-size: 26px;
		}
		.common-crm-search .el-input--small .el-input__inner {
			margin-top: 0px;
		}
		/*高级设置*/
		.sa {
			display: none;
			position: absolute;
			top: 28px;
			right: 40px;
			width: 400px;
			font-size: 12px;
			padding: 10px;
			border: 1px solid #666;
			background: #FFF;
			z-index: 9999;
			text-align: left;
			border-radius: @radius;
			box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
		}
		.sa-close {
			position: absolute;
			width: 30px;
			height: 30px;
			right: 0px;
			top: 0px;
			cursor: pointer;
			background: url('../../../../static/img/common/tipbg.jpg') no-repeat;
			z-index: 9999;
		}
		.sbk1 {
			margin-left: 15px;
			margin-top: 10px;
		}
		.sa-td {
			height: 50px;
			width: 20%;
			color: #666;
			line-height: 20px;
		}
		.sa-td select {
			height: 30px;
			width: 120px;
			border: 1px solid #bfcbd9;
			border-radius: @radius;
			outline: none;
		}
		.sa-td input {
			vertical-align: middle;
		}
		.bks2 {
			width: 205px;
			height: 30px;
			padding-left: 10px;
			border: 1px solid #BFCBD9;
			border-radius: @radius;
			outline: none;
		}
		.sa-td select:focus,
		.bks2:focus {
			border-color: #20A0FF;
		}
		.q-left {
			float: left;
			margin-right: 10px;
			color: #666;
			line-height: 25px;
		}
		.bks3 {
			width: 80px;
			height: 25px;
			border: 1px solid #cfcfcf;
		}
		.btnbox {
			float: right;
			text-align: right;
			padding-right: 20px;
			height: 40px;
			line-height: 40px;
			clear: both;
		}
		.btangz {
			cursor: pointer;
			margin-left: 55px;
		}
		.btangz .btan-1 {
			float: left;
		}
		.btangz .btan-1 a {
			padding: 4px 10px;
			color: #FFFFFF;
			border: 1px solid #20a0ff;
			background: #20a0ff;
			border-radius: @radius;
		}
		.btangz .btan-2 {
			float: left;
			margin-left: 15px;
		}
		.btangz .btan-2 a {
			padding: 4px 10px;
			color: #222222;
			border: 1px solid #aaaaaa;
			background: #ffffff;
			border-radius: @radius;
		}
		.btangz .btan-1 a:hover,
		.btangz .btan-2 a:hover {
			box-shadow: 0px 0px 5px #ccc;
		}
	}
</style>