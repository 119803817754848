<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：客户·客户详细信息·关联产品弹窗
开始时间：2017-11-9
开发人员：陈立珍
最后修改：2018-7-23
备注说明：如需修改请联系开发人员
-->

<template>
  <div id="product-moreInfo">
    <div class="product-moreInfo">
      <el-dialog
        title="关联产品"
        class='yourName product-info dialogTwo'
        size="tiny"
        :visible.sync="test"
        :before-close="proInfoClose"
        :modal="false"
        :modal-append-to-body="true"
        :lock-scroll="true"
        :close-on-click-modal="false">
        <table width="100%" cellpadding="0" cellspacing="0" border="0">
          <thead>
            <tr>
              <th>产品名称</th>
              <th>产品创建时间</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="td in productList">
              <td class="crm-m-width limit-show">
              		<el-tooltip class="item" effect="dark" :open-delay="300" :content="td.name" placement="top-start">
      							<span>{{td.name}}</span>
									</el-tooltip>
              </td>
              <td class="crm-m-width limit-show">
              	<el-tooltip class="item" effect="dark" :open-delay="300" :content="td.date" placement="top-start">
      							<span>{{td.date}}</span>
									</el-tooltip>
							</td>
            </tr>
          </tbody>
        </table>
        <!--底部确认取消按钮-->
        <span slot="footer" class="dialog-footer">
          <el-button @click="proInfoClose">关 闭</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
  export default{
    name:"product-moreInfo",
    data() {
      return {
        test:true
      }
    },
    methods:{
    	//触发父组件关闭弹窗
      proInfoClose(){
        this.$emit('proInfoClose')
      }
    },
    props:[
      'productList'
    ],
    mounted(){
      function clearTransform(){
        $('.el-dialog').css({
          'transform': 'none',
        })
      };
      dialogDrag();
      clearTransform(dialogCenter());
    }
  }
</script>

<style lang="less">
  @backGray:#eef1f6;
  @borderGray:#d3d3d3;
  #product-moreInfo{
    .product-moreInfo{
      table{
        overflow: hidden;
        border-top: 1px solid #d3d3d3;
        border-bottom: 1px solid #d3d3d3;
        border-radius: 2px;
        -moz-box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);

        th{
          background-color: @backGray;
          color: #999;
        }
        th,td{
          width:50%;
          height:36px;
          line-height: 36px;
          font-weight: normal;
          text-align: center;
          border-right: 1px solid @borderGray;
          border-bottom: 1px solid @borderGray;
          &:nth-child(odd){
            border-left:1px solid @borderGray;
         }
        }
        .crm-m-width{
  				max-width: 230px;
 				}
 				.limit-show{
 					overflow: hidden;
					text-overflow:ellipsis;
					white-space: nowrap;
 				}
      }
      .el-dialog{
        width:500px;
        height:auto;
      }
    }
  }

</style>
