<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：客户·客户详细信息·上传头像弹窗
开始时间：2017-11-9
开发人员：陈立珍
最后修改：2018-7-23
备注说明：如需修改请联系开发人员
-->

<!--上传头像-->
<template>
  <div id="picUpload">
    <div class="picUpload-box">
      <el-dialog
        title="请选择图片"
        class='cusUploadHead dialogTwo'
        size="tiny"
        :visible.sync="test"
        :before-close="uploadClose"
        :modal="false"
        :modal-append-to-body="true"
        :lock-scroll="true"
        :close-on-click-modal="false">
        <popupLoading></popupLoading>
        <form method="post" id="cus_imgForm">
        	<input type="file" id="cus_imgid" name="customerfile" accept=".png,.jpg,.gif,.jpeg"/>
        </form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="uploadSubmit">确 定</el-button>
          <el-button @click="uploadClose">取 消</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
	import popupLoading from '@/components/publicComponent/popupLoading'
  export default{
    name:"picUpload",
    data() {
      return {
        test:true
      }
    },
    props:[
	    'customerId',
	  ],
	  components: {
		popupLoading
	},
    methods:{
    	//提交
    	uploadSubmit(){
    		//判断上传文件类型
				var filename=$("#cus_imgid").val();
				if(filename==null||filename==""){
					this.$message({
				    showClose: true,
				    message: '请选择图片！',
				    type: 'warning',
				    duration:2000
				  });
					return false;
				}
				var type=filename.substring(filename.lastIndexOf("\.")+1,filename.length);
				if (type.toUpperCase()!="GIF"&&type.toUpperCase()!="JPG"&&type.toUpperCase()!="PNG"){
					this.$message({
				    showClose: true,
				    message: '图片格式不支持,只支持GIF、JPG、PNG！',
				    type: 'warning',
				    duration:2000
				  });
					return false;
				}
				var lef = this;
				var option = {
					url: backUrl + 'cus_uploadImg.action?token='+token,
					dataType: 'json',
					type:'POST',
//					async: false,
					data: {
						customerFileName:$("#cus_imgid").val(),
						customerid:lef.customerId
					},
					beforeSend: function() {
					lef.$store.state.popupLoading = true;
				},
				complete: function() {
					lef.$store.state.popupLoading = false;
				},
					success: function (msg) {
						if(msg!=null&&msg!=""){
							lef.$emit('imageSuccess',msg);
						}else{
							lef.$message({
						    showClose: true,
						    message: '上传头像失败！',
						    type: 'warning',
						    duration:2000
						  });
						}
					}
				};
				myAjaxSubmit('cus_imgForm',option);
    	},
    	//触发父组件关闭弹窗
      uploadClose(){
        this.$emit('uploadClose')
      }
    },
    mounted(){
      function clearTransform(){
        $('.el-dialog').css({
          'transform': 'none',
        })
      };
      dialogDrag();
      clearTransform(dialogCenter());
    }
  }
</script>

<style lang="less">
  #picUpload{
    .dialog-masks{
      z-index: 2000;
      background:rgba(0,0,0,0.2);
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      position: fixed;
      overflow: auto;
      margin: 0;
    }
    .picUpload-box{
      position:fixed;
      z-index:2001;
    }
  }
	.cusUploadHead .el-dialog{
	  width:360px !important;
	  height:140px !important;
	}
	.cusUploadHead  .el-dialog__body{
	  overflow: hidden !important;
	}
</style>
