<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：客户·客户详细信息·新建/编辑订单弹窗
开始时间：2017-11-9
开发人员：陈立珍
最后修改：2018-7-23
备注说明：如需修改请联系开发人员
-->

<template>
  <div>
    <table class="crm-tab3" width="100%" cellpadding="6" cellspacing="8">
      <tr>
        <td class="cu_tbg">
          <span>订单类型</span>
        </td>
        <td>
          <select v-model="orderType">
            <option value="正式订单">正式订单</option>
            <option value="样品订单">样品订单</option>
          </select>
        </td>
        <td class="cu_tbg">
          <span>成交产品</span>
        </td>
        <td v-if="!productLoading">
          <com-product
            :choiceProductArr="productids"
            @cofirmProduct="cofirmProduct"
            @getProductInfo="getProductInfo"
          ></com-product>
        </td>
        <td v-if="productLoading ">
          <div>
            <i class="el-icon-loading"></i>
          </div>
        </td>
        <!-- <td><span @click="selectProduct" class="pSelect" title="选择关联产品">{{productNum==0?'请选择':'选择了'+productNum+'个产品'}}</span></td> -->
        <!--<td v-if="isRecordsInfo"><span @click="selectProduct" class="pSelect" title="查看成交产品">查看</span></td>-->
      </tr>
      <!--选择的订单列表-->
      <tr v-for="(pn,index) in selectPLists" v-show="selectPLists.length > 0">
        <td class="cu_tbg">
          <div
            style="max-width: 48px;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;"
            :title="pn.name"
          >{{pn.name}}</div>
        </td>
        <td>
          <span class="cu_tbg">金额&nbsp;&nbsp;&nbsp;</span>
          <input
            type="text"
            name="productMoney"
            value
            style="width: 65px;"
            @blur="proBlur1($event,3)"
          />&nbsp;
          <span>万</span>
          <select class="select-currency" name="pmoneyType">
            <option
              v-for="currency in currencyList"
              :value="currency.cid"
              :title="currency.name"
            >{{currency.name1}}</option>
          </select>
          <span class="rightError" v-show="proMoneyShow"></span>
        </td>
        <td class="cu_tbg">
          <span>数&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;量</span>
        </td>
        <td>
          <input
            type="text"
            name="productNumber"
            value
            style="width: 110px;"
            @blur="proBlur1($event,4)"
          />&nbsp;
          <select class="select-currency" name="productNumType" style="width: 85px;">
            <option v-for="ca in calculateList" :value="ca.caid" :title="ca.name">{{ca.name1}}</option>
          </select>
          <span class="rightError" v-show="proNumShow"></span>
        </td>
      </tr>
      <tr>
        <td class="cu_tbg">
          <span>成交时间</span>
        </td>
        <td>
          <el-date-picker
            v-model="dateValue"
            type="date"
            :editable="false"
            :clearable="false"
            class="money-input"
          ></el-date-picker>
        </td>
        <td class="cu_tbg">
          <span>成交总额</span>
        </td>
        <td>
          <input type="text" v-model="value1" style="width: 110px;" @blur="proBlur1($event,1)" />&nbsp;
          <span>万</span>
          <select class="select-currency" v-model="currencyall">
            <option
              v-for="currency in currencyList"
              :value="currency.cid"
              :title="currency.name"
            >{{currency.name1}}</option>
          </select>
          <span class="rightError" v-show="tagAmountShow1" id="titleShow1"></span>
        </td>
      </tr>
      <tr>
        <td class="cu_tbg">
          <span>物流方式</span>
        </td>
        <td>
          <select v-model="logisticsway">
            <option value="0">请选择</option>
            <option
              v-for="logistics1 in logisticsWList"
              :value="logistics1.id"
              :title="logistics1.name"
            >{{logistics1.name1}}</option>
          </select>
        </td>
        <td class="cu_tbg">
          <span>物流公司</span>
        </td>
        <td>
          <select v-model="logisticscompany">
            <option value="0">请选择</option>
            <option
              v-for="logistics2 in logisticsCList"
              :value="logistics2.id"
              :title="logistics2.name"
            >{{logistics2.name1}}</option>
          </select>
        </td>
      </tr>
      <tr>
        <td class="cu_tbg">
          <span>物流费用</span>
        </td>
        <td>
          <input
            type="text"
            v-model="logisticsMoney"
            style="width: 103px;"
            @blur="proBlur1($event,2)"
          />&nbsp;
          <select class="select-currency" v-model="lomoneyunit" style="width: 85px;">
            <option value="0">请选择</option>
            <option
              v-for="currency in currencyList"
              :value="currency.cid"
              :title="currency.name"
            >{{currency.name1}}</option>
          </select>
          <span class="rightError" v-show="tagAmountShow2" id="titleShow2"></span>
        </td>
      </tr>
      <tr>
        <td class="cu_tbg" colspan="1">
          <span>订单状态</span>
        </td>
        <td colspan="3" style="line-height: 25px;">
          <el-checkbox-group v-model="orderState">
            <el-checkbox
              v-for="(order,index) in orderStatusList"
              :label="order.id"
              :key="index"
            >{{order.name}}</el-checkbox>
          </el-checkbox-group>
        </td>
      </tr>
      <!--自定义字段-->
      <tr class="order_myfield">
        <td class="cu_tbg">
          <span>自定义字段</span>
        </td>
        <td>
          <i class="o_add" @click="addfield($event)" title="添加自定义字段"></i>
        </td>
      </tr>
      <tr class="oaddNum" v-show="definedShow" v-for="(field,index) in fieldLists" :oaddNum="index">
        <td class="cu_tbg">
          <span></span>
        </td>
        <td>
          <span class="cu_tbg">名称&nbsp;&nbsp;&nbsp;</span>
          <input
            type="text"
            class="orderfinedname"
            :value="field.defined_key"
            style="width: 182px;"
          />&nbsp;
        </td>
        <td class="cu_tbg">
          <span>对应值</span>
        </td>
        <td>
          <input type="text" class="orderfinedvalue" :value="field.defined_value" />&nbsp;&nbsp;
          <i class="o_recycle" @click="removefield($event)" title="删除自定义字段"></i>
        </td>
      </tr>
      <tr>
        <td class="cu_tbg">
          <span>备注信息</span>
        </td>
        <td colspan="3">
          <textarea v-model="remark" style="width: 546px;" cols="30" rows="10" class="f-content"></textarea>
        </td>
      </tr>
      <!--上传文件-->
      <tr
        v-show="fileButton && fileList.length < 5 && userparamlist.param213 != undefined && userparamlist.param213 != 0"
      >
        <td>上传文件</td>
        <td>
          <input type="file" name="updateFileXX" id="updateFileXX" value accept :accept="fileType" />
        </td>
      </tr>
    </table>
    <!--上传的文件列表-->
    <div
      class="fileTable"
      v-if="userparamlist.param213 != undefined && userparamlist.param213 != 0"
    >
      <table
        class="crm-connectionTab"
        cellspacing="0"
        cellpadding="0"
        width="100%"
        style="margin-bottom: 20px;"
        v-show="fileTableShow"
      >
        <tbody>
          <tr>
            <th class="th" width="5%"></th>
            <th class="th" width="20%" style="min-width: 220px;">文件名</th>
            <th class="th" width="18%">上传时间</th>
            <th class="th" width="15%">大小</th>
            <th class="th" width="25%" style="border-right: 1px solid #d3d3d3;">操作</th>
          </tr>
          <tr v-for="(tab,index) in fileList" v-if="fileList.length > 0" class="fileListTr">
            <td class="td" style="border-left: 1px solid #d3d3d3;border-bottom: 1px solid #d3d3d3;"></td>
            <td class="td" style="min-width: 230px;">
              <el-tooltip
                class="item"
                effect="dark"
                :open-delay="300"
                :content="tab.name"
                placement="top"
                v-show="!tab.change"
              >
                <p
                  style="overflow: hidden;text-overflow:ellipsis;white-space: nowrap;width: 220px;"
                >{{tab.name}}</p>
              </el-tooltip>
              <div v-if="tab.change">
                <!--修改的文件名-->
                <input type="text" v-model="fileName" style="display: inline-block;width: 170px;" />
                <!--确认修改-->
                <span class="file-new" @click="fileNameInput(true,index,tab.id,tab.folderId)">
                  <i class="el-icon-check"></i>
                </span>
                <!--取消修改-->
                <span
                  class="file-new"
                  style="padding: 1px 3px;"
                  @click="fileNameInput(false,index,tab.id,tab.folderId)"
                >
                  <i class="el-icon-close"></i>
                </span>
              </div>
            </td>
            <td class="td">
              <el-tooltip
                class="item"
                effect="dark"
                :open-delay="300"
                :content="tab.time1"
                placement="top"
              >
                <span>{{tab.time}}</span>
              </el-tooltip>
            </td>
            <td class="td">{{tab.size}}</td>
            <td class="td" style="border-right: 1px solid #d3d3d3;">
              <i
                title="查看"
                @click="lookFile(tab.token,tab.name)"
                class="icon iconfont icon-eyes alibabaicon"
              ></i> &nbsp;
              <i
                title="编辑"
                v-show="fileNameChanging"
                @click="fileNameChange(tab.name,index)"
                class="icon iconfont icon-xingzhuang29 alibabaicon"
              ></i> &nbsp;
              <i
                title="删除"
                @click="deleteFile(tab.id,index)"
                class="icon iconfont icon-lajitong1 alibabaicon"
              ></i>
            </td>
          </tr>
          <tr v-if="fileList.length == 0">
            <td colspan="100%" class="noData-box">
              <div class="noData-bg"></div>
              <span class="noData">暂无数据</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!--预览-->
    <div>
      <transition name="fade">
        <div class="lookFj" v-show="lookFj">
          <div class="lf-header w100">
            <div
              class="lfh-title"
              :title="looFjName"
              style="overflow: hidden;text-overflow:ellipsis;white-space: nowrap;width: 300px;"
            >{{looFjName}}</div>
            <div class="lfh-download" @click="downloadCommonMethod([fileToken,0])">
              <i class="icon iconfont icon-download" style="font-size: 18px;"></i>
              <span>下载</span>
            </div>
            <div class="lfh-close" @click="lookFj = false">
              <i class="icon iconfont icon-close color-white"></i>
              <span>关闭</span>
            </div>
          </div>

          <div class="lf-content w100">
            <iframe :src="lookFjUrl" width="90%" height="100%" class="lfc-main"></iframe>
          </div>
        </div>
      </transition>
    </div>
    <!--引入选择关联产品组件-->
    <!--<small-layer class="smallLayer" v-if="showC" 
			@cntClose="showC = false" @cntConfirm="cntConfirm" 
			:showType="4" :productids2="productids2" 
			:connect-tab="false" cnt-title="选择关联产品" 
    :has-button="true"></small-layer>-->

    <relatedProduct
      v-if="showC"
      :productids2="productids2"
      :productType="6"
      @closePro="showC = false"
      @cntConfirm="cntConfirm"
    ></relatedProduct>
  </div>
</template>
<script>
import store from "../../../vuex/store.js";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import "../../../vuex/mutations.js";
import smallLayer from "./smallLayer.vue";
import relatedProduct from "./layer_related_product.vue";
import comProduct from "@/components/common_file/com_product/comProduct";
export default {
  name: "newFollow",
  data() {
    return {
      currencyid: 0, //币种id
      orderType: "正式订单",
      productids: "", //选择的关联产品id,多个以逗号分割
      productids2: "", //选择的关联产品id,多个以逗号分割
      productNum: 0, //选择的关联产品数量
      dateValue: "", //成交时间
      value1: "", //成交金额
      //value2: '', //购买数量
      remark: "", //备注信息
      createDate: "",
      showC: false,
      pickerOptions1: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            }
          }
        ]
      },
      currencyList: [], //成交货币集合
      fileList: [],
      fileType: "",
      fileTableShow: false,
      fileButton: false,
      lookFj: false,
      looFjName: "预览",
      lookFjUrl: "",
      fileToken: "",
      fileName: "",
      fileChangeType: "",
      fileNameChanging: true,
      selectPLists: [], //选中产品的集合
      productLoading: true, //判断在编辑状态下，加载完产品的信息后再显示产品组件

      fieldLists: [], //自定义字段集合
      orderState: [], //订单状态
      times: 0, //点击次数
      calculateList: [], //计量单位集合
      logisticsCList: [], //物流公司集合
      logisticsWList: [], //物流方式集合
      orderStatusList: [], //订单状态集合
      buyNum: "", //购买数量
      tagAmountShow1: false, //成交总额验证
      tagAmountShow2: false, //物流费用验证
      proMoneyShow: false, //产品金额验证
      proNumShow: false, //产品数量验证
      logisticsMoney: "", //物流费用,
      lomoneyunit: 0, //物流费用单位
      currencyall: 0, //成交总额单位
      logisticsway: 0, //物流方式
      logisticscompany: 0, //物流公司
      regu: /^[1-9]\d*$/, //验证数量的正则表达式
      definedShow: false //自定义字段
    };
  },
  props: ["isRecordLayer", "isRecordsInfo", "saleId", "customerids", "userId"],
  components: {
    "small-layer": smallLayer,
    relatedProduct: relatedProduct,
    comProduct
  },
  computed: {
    ...mapState({
      tradecurrencyall: state => state.tradecurrencyall,
      relationProduct: state => state.relationProduct,
      uploadtype: state => state.fileUpdate.uploadtype,
      userinfo: state => state.userinfo,
      userparamlist: state => state.userparamlist,
      currentyear: state => state.currentyear,
      currentmonth: state => state.currentmonth,
      currentday: state => state.currentday,
      calculateInfo: state => state.calculateInfo,
      logisticsCInfo: state => state.logisticsCInfo,
      logisticsWInfo: state => state.logisticsWInfo,
      orderStatusInfo: state => state.orderStatusInfo
    })
  },
  watch: {
    //			value1(value) {
    //				this.value1 = value.replace(/[^\d\.]/g, '');
    //			},
    //			logisticsMoney(value) {
    //				this.logisticsMoney = value.replace(/[^\d\.]/g, '');
    //			}
  },
  methods: {
    ...mapMutations({
      getTradeCurrencyAll: "getTradeCurrencyAll",
      getRelationProduct: "getRelationProduct",
      find_limitUploadFormat: "find_limitUploadFormat",
      downloadCommonMethod: "downloadCommonMethod",
      getCurrentTime: "getCurrentTime",
      getCalculateAllInfo: "getCalculateAllInfo",
      getLogisticsCompanyAllInfo: "getLogisticsCompanyAllInfo",
      getLogisticsWayAllInfo: "getLogisticsWayAllInfo",
      getOrderStatusAllInfo: "getOrderStatusAllInfo",
      updateFollowcount : "updateFollowcount"
    }),
    //修改文件名
    fileNameChange(name, index) {
      let self = this;
      this.fileName = name.split(".")[0];
      this.fileChangeType = name.split(".")[1];
      self.fileList[index].change = true;
      self.fileNameChanging = false;
    },
    //保存文件名
    fileNameInput(type, index, id, folderId) {
      let self = this;
      if (type) {
        let name = this.fileName + "." + this.fileChangeType;
        $.ajax({
          type: "POST",
          url: diskUrl + "/file/rename",
          async: false,
          cache: false,
          data: {
            LoginUserToken: token,
            rootId: -1,
            fileId: id,
            parentId: folderId,
            name: name
          },
          success: function(msg) {
            if (msg.code == 200) {
              self.$message({
                showClose: true,
                message: "文件名修改成功！",
                type: "success"
              });
              self.fileList[index].name = name;
            }
          }
        });
      }
      self.fileList[index].change = false;
      self.fileNameChanging = true;
    },
    //预览
    lookFile(fileToken, name) {
      this.looFjName = name;
      this.fileToken = fileToken;
      let self = this;
      $.ajax({
        type: "POST",
        url: diskUrl + "/file/previewUrl/get",
        async: false,
        cache: false,
        data: {
          type: 0,
          token: fileToken
        },
        success: function(msg) {
          self.lookFjUrl = msg.data;
        }
      });
      this.lookFj = true;
    },
    //删除文件
    deleteFile(id, index) {
      let self = this;
      $.ajax({
        type: "POST",
        url: diskUrl + "/file/change",
        async: false,
        catch: false,
        data: {
          LoginUserToken: token,
          companyId: self.userinfo.company_id,
          rootId: -1,
          type: 0,
          ids: id
        },
        success: function(msg) {
          if (msg.code == 200) {
            self.fileList.splice(index, 1);
            self.$message({
              showClose: true,
              message: "文件删除成功！",
              type: "success"
            });
          }
        }
      });
    },
    //确认关联产品
    cntConfirm(pdate) {
      this.selectPLists = pdate;
      var proids = "";
      $.each(pdate, function(index, row) {
        proids += row.id + ",";
      });
      proids = proids.substring(0, proids.length - 1);
      this.productids = proids;
      if (proids != "") {
        this.productNum = this.productids.split(",").length;
      } else {
        this.productNum = 0;
      }
      //弹窗选中
      var production_value = "";
      $.each(pdate, function(i, row) {
        var pid = row.id;
        var pname = row.name;
        production_value += pid + "###" + pname + ",";
      });
      production_value = production_value.substring(
        0,
        production_value.length - 1
      );
      this.productids2 = production_value;
    },
    //获取选中的产品id
    cofirmProduct(ids) {
      this.productids = ids;
    },
    //获取产品的信息
    getProductInfo(info) {
      this.selectPLists = info;
    },
    //是否是数字整数
    verifyNumber(index, value1) {
      var resultstr = $.trim(value1);
      if (index == 1) {
        //成交总额
        this.tagAmountShow1 = true;
        if (!$.isNumeric(resultstr) || resultstr <= 0) {
          $("#titleShow1").removeClass("right-span");
          $("#titleShow1").addClass("error-span");
          $("#titleShow1").attr("title", "成交总额只能是数字,且大于0!");
          return false;
        } else {
          $("#titleShow1").addClass("right-span");
          $("#titleShow1").removeClass("error-span");
          $("#titleShow1").attr("title", "输入正确!");
          return true;
        }
      } else if (index == 2) {
        //物流费用
        this.tagAmountShow2 = true;
        if (!$.isNumeric(resultstr) || resultstr <= 0) {
          $("#titleShow2").removeClass("right-span");
          $("#titleShow2").addClass("error-span");
          $("#titleShow2").attr("title", "物流费用只能是数字,且大于0!");
          return false;
        } else {
          $("#titleShow2").addClass("right-span");
          $("#titleShow2").removeClass("error-span");
          $("#titleShow2").attr("title", "输入正确!");
          return true;
        }
      } else if (index == 3) {
        //产品金额
        if (!$.isNumeric(resultstr) || resultstr <= 0) {
          return false;
        } else {
          return true;
        }
      } else {
        //产品数量
        if (!$.isNumeric(resultstr) || resultstr <= 0) {
          return false;
        } else {
          return true;
        }
      }
    },
    //验证产品金额/数量
    get_validateProduct(form) {
      var lef = this;
      var productMoney = "",
        productNum = "";
      var result = true;
      var tag2 = true,
        tag3 = true;
      if (form == 1) {
        //产品金额
        $("input[name='productMoney']").each(function() {
          productMoney = $(this).val();
          tag2 = lef.verifyNumber(3, productMoney);
          if (!tag2) {
            lef.$message({
              showClose: true,
              message: "产品金额只能是数字,且大于0!",
              type: "warning",
              duration: 2000
            });
            result = false;
          }

          if (productMoney == "") {
            lef.$message({
              showClose: true,
              message: "产品金额不能为空!",
              type: "warning",
              duration: 2000
            });
            result = false;
          }
        });
      } else {
        $("input[name='productNumber']").each(function() {
          productNum = $(this).val();
          tag3 = lef.verifyNumber(4, productNum);
          if (!tag3) {
            lef.$message({
              showClose: true,
              message: "产品数量只能正整数!",
              type: "warning",
              duration: 2000
            });
            result = false;
          }
          if (productNum == "") {
            lef.$message({
              showClose: true,
              message: "产品数量不能为空!",
              type: "warning",
              duration: 2000
            });
            result = false;
          }
        });
      }

      return result;
    },

    //添加订单提交
    subButton(customerids) {
      var lef = this;
      var tag1 = true; //成交总额
      var tag2 = true; //产品金额
      var tag3 = true; //产品数量
      var tag4 = true; //物流费用
      //转化预成交时间
      var myDate = new Date(this.dateValue);
      var year = myDate.getFullYear().toString();
      var month = (myDate.getMonth() + 1).toString();
      var day = myDate.getDate().toString();
      if (month.length == 1) {
        month = "0" + month;
      }
      if (day.length == 1) {
        day = "0" + day;
      }
      this.dateValue = year + "-" + month + "-" + day;
      //成交总额
      tag1 = this.verifyNumber(1, this.value1);
      if ($.trim(this.value1) == "") {
        this.$message({
          showClose: true,
          message: "成交总额不能为空!",
          type: "warning",
          duration: 2000
        });
        return;
      } else {
        if (!tag1) {
          this.$message({
            showClose: true,
            message: "成交总额只能是数字,且大于0!",
            type: "warning",
            duration: 2000
          });
          return;
        }
      }
      //产品金额/单位和数量/单位
      if (this.productids.length != 0) {
        tag2 = this.get_validateProduct(1);
      }
      if (!tag2) {
        return;
      }
      //产品数量
      if (this.productids.length != 0) {
        tag3 = this.get_validateProduct(2);
      }
      if (!tag3) {
        return;
      }
      //当物流费用不为空的时候，验证物流费用
      if (this.logisticsMoney.length != 0) {
        tag4 = this.verifyNumber(2, this.logisticsMoney);
        if (!tag4) {
          this.$message({
            showClose: true,
            message: "物流费用只能是数字,且大于0!",
            type: "warning",
            duration: 2000
          });
          return;
        }
      }
      //物流单位
      if (this.logisticsMoney.length != 0) {
        if (this.lomoneyunit == 0) {
          this.$message({
            showClose: true,
            message: "请选择物流费用单位!",
            type: "warning",
            duration: 2000
          });
          return;
        }
      } else {
        this.lomoneyunit = 0;
      }
      if (this.value1.length > 9) {
        this.$message({
          showClose: true,
          message: "成交总额不能超过9个字符！",
          type: "warning",
          duration: 2000
        });
        return;
      }
      if (this.logisticsMoney.length > 9) {
        this.$message({
          showClose: true,
          message: "物流费用不能超过9个字符！",
          type: "warning",
          duration: 2000
        });
        return;
      }
      //获取自定义字段
      var orderField = $(".oaddNum");
      var arr = [];
      if (orderField.length > 0) {
        var obj = {};
        var err = 0;
        for (var j = 0, len = orderField.length; j < len; j++) {
          obj = {};
          obj.name = orderField
            .eq(j)
            .find(".orderfinedname")
            .val();
          obj.value = orderField
            .eq(j)
            .find(".orderfinedvalue")
            .val();
          arr.push(obj);
          if (
            (obj.name.trim().length > 0 && obj.value.trim().length == 0) ||
            (obj.name.trim().length == 0 && obj.value.trim().length > 0)
          ) {
            lef.$message({
              showClose: true,
              message: "自定义字段名称和对应值必须同时填写!",
              type: "warning",
              duration: 2000
            });
            err = 1;
            break;
          }
        }
        if (err == 1) {
          return;
        }
      }
      //自定义字段拼接
      var nameStr = "";
      if (arr.length != 0) {
        $.each(arr, function(i, row) {
          nameStr += row.name + "###" + row.value + "@@G@@";
        });
        nameStr = nameStr.substring(0, nameStr.length - 5); //截取最后5个字符
      }
      //备注信息
      if ($.trim(this.remark).length > 1000) {
        this.$message({
          type: "warning",
          showClose: true,
          message: "备注信息不能超过1000个字符！",
          duration: 2000
        });
        return;
      }
      //产品拼接
      var productStr = "";
      let ids = lef.commonJs.returnString(lef.productids);
      var idArr = ids.split(",");
      if (this.productids.length != 0) {
        $.each($("input[name='productMoney']"), function(index, row) {
          var productInfo = "";
          var id = idArr[index];
          var pMoney = $(this).val();
          var pMoneyType = $("select[name='pmoneyType']")[index].value;
          var pNumber = $("input[name='productNumber']")[index].value;
          var pNumberType = $("select[name='productNumType']")[index].value;
          productInfo =
            id +
            "," +
            pMoney +
            "," +
            pMoneyType +
            "," +
            pNumber +
            "," +
            pNumberType;
          productStr += productInfo + "###";
        });
      }
      productStr = productStr.substring(0, productStr.length - 3); //截取最后3个字符
      //订单状态
      var orderStatus = lef.orderState + ",";
      orderStatus = orderStatus.substring(0, orderStatus.length - 1); //截取最后5个字符
      var fileSsaleId = lef.saleId,
        files = document.getElementById("updateFileXX").files[0];
      if (lef.saleId > 0) {
        //编辑
        $.ajax({
          type: "POST",
          cache: false,
          url: backUrl + "cusPR_editProductSale.action",
          data: {
            token: token,
            productids: productStr,
            customer_ids: customerids,
            "crmproductsales2.id": lef.saleId,
            "crmproductsales2.create_date": lef.createDate,
            "crmproductsales2.record_type": lef.orderType,
            "crmproductsales2.contract_time": lef.dateValue,
            "crmproductsales2.transcation_ammount": parseFloat(lef.value1),
            "crmproductsales2.money_type": lef.currencyall,
            //"crmproductsales2.buy_number": lef.value2,
            "crmproductsales2.sales_desc": lef.remark,
            "crmproductsales2.customer_field": nameStr,
            "crmproductsales2.logistics_way_id": lef.logisticsway,
            "crmproductsales2.logistics_company_id": lef.logisticscompany,
            "crmproductsales2.logistics_money": lef.logisticsMoney,
            "crmproductsales2.logistics_money_type": lef.lomoneyunit,
            "crmproductsales2.order_status": orderStatus
          },
          beforeSend: function() {
            lef.$store.state.popupLoading = true;
          },
          complete: function() {
            lef.$store.state.popupLoading = false;
          },
          success: function(msg) {
            if (msg == 1) {
              lef.$message({
                showClose: true,
                message: "编辑成功！",
                type: "success",
                duration: 2000
              });
              if (files != "" && files != undefined && files != null) {
                lef.updateFileOrder(files, fileSsaleId);
              }
              lef.$emit("submitok");
            } else {
              lef.$message({
                showClose: true,
                message: "编辑失败！",
                type: "warning",
                duration: 2000
              });
            }
          },
          error: function(e) {
            lef.$message({
              showClose: true,
              message: "编辑失败！",
              type: "warning",
              duration: 2000
            });
          }
        });
      } else {
        if (
          this.customerids &&
          this.customerids.toString().indexOf(",") == -1
        ) {
          $.ajax({
            type: "POST",
            cache: false,
            url: backUrl + "cusPR_addProductSalesOne.action",
            data: {
              token: token,
              productids: productStr,
              customer_ids: customerids,
              "crmproductsales2.id": 0,
              "crmproductsales2.record_type": lef.orderType,
              "crmproductsales2.contract_time": lef.dateValue,
              "crmproductsales2.transcation_ammount": parseFloat(lef.value1),
              "crmproductsales2.money_type": lef.currencyall,
              //								"crmproductsales2.buy_number": lef.value2,
              "crmproductsales2.sales_desc": lef.remark,
              "crmproductsales2.customer_field": nameStr,
              "crmproductsales2.logistics_way_id": lef.logisticsway,
              "crmproductsales2.logistics_company_id": lef.logisticscompany,
              "crmproductsales2.logistics_money": lef.logisticsMoney,
              "crmproductsales2.logistics_money_type": lef.lomoneyunit,
              "crmproductsales2.order_status": orderStatus
            },
            beforeSend: function() {
              lef.$store.state.popupLoading = true;
            },
            complete: function() {
              lef.$store.state.popupLoading = false;
            },
            success: function(msg) {
              if (msg > 0) {
                fileSsaleId = msg;
                if (files != "" && files != undefined && files != null) {
                  lef.updateFileOrder(files, fileSsaleId);
                }
                lef.$message({
                  showClose: true,
                  message: "添加成功！",
                  type: "success",
                  duration: 2000
                });
                lef.$emit("submitok");
                // 统计跟进记录
                lef.updateFollowcount(customerids);
              } else {
                lef.$message({
                  showClose: true,
                  message: "添加失败！",
                  type: "warning",
                  duration: 2000
                });
              }
            },
            error: function(e) {
              lef.$message({
                showClose: true,
                message: "添加失败！",
                type: "warning",
                duration: 2000
              });
            }
          });
        } else {
          $.ajax({
            type: "POST",
            cache: false,
            url: backUrl + "cusPR_addProductSale.action",
            data: {
              token: token,
              productids: productStr,
              customer_ids: customerids,
              "crmproductsales2.id": 0,
              "crmproductsales2.record_type": lef.orderType,
              "crmproductsales2.contract_time": lef.dateValue,
              "crmproductsales2.transcation_ammount": parseFloat(lef.value1),
              "crmproductsales2.money_type": lef.currencyall,
              //"crmproductsales2.buy_number": lef.value2,
              "crmproductsales2.sales_desc": lef.remark,
              "crmproductsales2.customer_field": nameStr,
              "crmproductsales2.logistics_way_id": lef.logisticsway,
              "crmproductsales2.logistics_company_id": lef.logisticscompany,
              "crmproductsales2.logistics_money": lef.logisticsMoney,
              "crmproductsales2.logistics_money_type": lef.lomoneyunit,
              "crmproductsales2.order_status": orderStatus
            },
            beforeSend: function() {
              lef.$store.state.popupLoading = true;
            },
            complete: function() {
              lef.$store.state.popupLoading = false;
            },
            success: function(msg) {
              if (msg == 1) {
                lef.$message({
                  showClose: true,
                  message: "添加成功！",
                  type: "success",
                  duration: 2000
                });
                lef.$emit("submitok");
              } else {
                lef.$message({
                  showClose: true,
                  message: "添加失败！",
                  type: "warning",
                  duration: 2000
                });
              }
            },
            error: function(e) {
              lef.$message({
                showClose: true,
                message: "添加失败！",
                type: "warning",
                duration: 2000
              });
            }
          });
        }
      }
    },
    //上传文件
    updateFileOrder(files, fileSsaleId) {
      let lef = this;
      //上传附件
      let formData = new FormData();
      formData.append("LoginUserToken", token);
      formData.append("companyId", lef.userinfo.company_id);
      formData.append("domain", lef.userinfo.domain);
      formData.append("rootId", -1);
      formData.append("parentId", -1);
      formData.append("file", files);
      formData.append("customerId", lef.customerids);
      formData.append("customerUserId", lef.userId);
      formData.append("from", 2);
      formData.append("targetId", fileSsaleId);

      $.ajax({
        url: diskUrl + "file/uploadFile",
        type: "POST",
        crossDomain: true,
        xhrFields: {
          withCredentials: true
        },
        headers: {
          LoginUserToken: token
        },
        data: formData,
        async: true,
        cache: false,
        contentType: false, //不设置内容类型
        processData: false //不处理数据
      })
        .done(function(resp) {
          if (resp.code == 100 || resp.code == 300) {
            setTimeout(() => {
              lef.$message({
                showClose: true,
                message: resp.msg,
                type: "warning"
              });
            }, 500);
          }
        })
        .fail(function(err) {
          lef.$message({
            showClose: true,
            message: "上传文件失败！",
            type: "warning"
          });
        });
    },
    //选择关联产品
    selectProduct() {
      this.showC = true;
    },
    //获取关联产品信息
    getProductList(type, id) {
      var lef = this;
      var param = [type, id];
      this.getRelationProduct(param);
      if (this.relationProduct != "" && this.relationProduct.length > 0) {
        $.each(lef.relationProduct, function(i, row) {
          lef.productids += row.product_id + ",";
          lef.selectPLists.push({
            id: row.product_id,
            name: row.product_name
          });
          setTimeout(function() {
            $("input[name='productMoney']:eq(" + i + ")").val(
              row.product_money
            );
            $("select[name='pmoneyType']:eq(" + i + ")").val(
              row.product_money_type
            );
            $("input[name='productNumber']:eq(" + i + ")").val(row.product_num);
            $("select[name='productNumType']:eq(" + i + ")").val(
              row.product_num_type
            );
          }, 0);
        });
        lef.productids = lef.productids.substring(0, lef.productids.length - 1);
        lef.productNum = lef.productids.split(",").length;

        var production_value = "";
        $.each(lef.selectPLists, function(i, row) {
          production_value += row.id + "###" + row.name + ",";
        });
        production_value = production_value.substring(
          0,
          production_value.length - 1
        );
        this.productids2 = production_value;
        this.productLoading = false;
      }
    },
    //获取订单信息
    getSaleInfo() {
      var lef = this;
      $.ajax({
        type: "POST",
        url: backUrl + "cusPR_findSalesByid.action",
        async: false,
        cache: false,
        dataType: "json",
        data: {
          token: token,
          saleid: lef.saleId
        },
        success: function(msg) {
          lef.currencyall = msg.money_type;
          lef.orderType = msg.record_type;
          lef.dateValue = msg.contract_time;
          lef.value1 = msg.transcation_ammount;
          //lef.value2 = msg.buy_number;
          lef.remark = msg.sales_desc;
          lef.createDate = msg.create_date;
          lef.logisticsway = msg.logistics_way_id;
          lef.logisticscompany = msg.logistics_company_id;
          lef.logisticsMoney = msg.logistics_money;
          if (msg.logistics_money == 0) {
            lef.logisticsMoney = "";
          }
          lef.lomoneyunit = msg.logistics_money_type;
          var orderStatus = msg.order_status;
          if (orderStatus != "" || orderStatus != undefined) {
            $.each(orderStatus.split(","), function(i, row) {
              lef.orderState.push(parseInt(row));
            });
          }
          if (msg.customer_field == "" || msg.customer_field == undefined) {
            lef.definedShow = false;
          } else {
            lef.definedShow = true;
            var defineds = msg.customer_field.split("@@G@@");
            $.each(defineds, function(index, row1) {
              var defined = row1.split("###");
              lef.fieldLists.push({
                defined_key: defined[0],
                defined_value: defined[1]
              });
            });
          }
          lef.getProductList(4, lef.saleId);
        }
      });
    },
    //处理时间
    loadTime() {
      this.getCurrentTime();
      var nowdate =
        this.currentyear + "-" + this.currentmonth + "-" + this.currentday;
      this.dateValue = nowdate;
    },
    //添加自定义字段
    addfield(event) {
      var self = this;
      var target = $(event.currentTarget);
      var target_tr = target
        .parent()
        .parent()
        .siblings();
      if (target_tr.hasClass("oaddNum")) {
        this.times++;
      } else {
        this.times = 0;
      }
      var _html = "";
      _html =
        "<tr class='oaddNum' oaddNum='" +
        this.times +
        "'>" +
        "<td class='cu_tbg '><span></span></td>" +
        "<td><span class='cu_tbg '>名称&nbsp;&nbsp;&nbsp;</span><input type='text' class='orderfinedname' value='' style='width: 182px;'/>&nbsp;</td>" +
        "<td class='cu_tbg '><span>对应值</span></td>" +
        "<td><input type='text' class='orderfinedvalue' value=''/>&nbsp;&nbsp;<i class='o_recycle' id='addtrid" +
        this.times +
        "' title='删除自定义字段'></i></td>" +
        "</tr>";
      target
        .parents()
        .find(".order_myfield")
        .after(_html);

      $("#addtrid" + this.times).click(function(e) {
        var target2 = $(e.currentTarget)
          .parent()
          .parent();
        var addNum = target2.attr("oaddNum");
        target2.remove();
      });
    },
    //删减自定义字段
    removefield(e) {
      var target2 = $(e.currentTarget)
        .parent()
        .parent();
      target2.remove();
    },
    //验证输入是否正确
    proBlur1(e, index) {
      var value = $(e.target).val();
      var judge = $(e.target).siblings(".rightError");
      if (index == 1) {
        //成交总额
        this.tagAmountShow1 = true;
      } else if (index == 2) {
        //物流费用
        if (value != "") {
          this.tagAmountShow2 = true;
        }
      } else if (index == 3) {
        //产品金额
        this.proMoneyShow = true;
      } else {
        //产品数量
        this.proNumShow = true;
      }
      if (!$.isNumeric(value) || value <= 0) {
        judge.removeClass("right-span");
        judge.addClass("error-span");
        judge.attr("title", "只能是数字,且大于0!");
      } else {
        judge.addClass("right-span");
        judge.removeClass("error-span");
        judge.attr("title", "输入正确!");
      }
    }
  },
  mounted() {
    this.loadTime();
    var self = this;
    //判断是否开通了文件管理
    if (
      this.userparamlist.param213 != undefined &&
      this.userparamlist.param213 != 0
    ) {
      if (this.customerids.toString().indexOf(",") == -1) {
        this.fileButton = true;
      }
      if (this.saleId > 0) {
        $.ajax({
          type: "get",
          url: diskUrl + "/file/list",
          async: false,
          cache: false,
          data: {
            LoginUserToken: token,
            companyId: self.userinfo.company_id,
            folderId: -1,
            currentPage: 1,
            pageSize: 5,
            customerId: self.customerids,
            targetId: self.saleId
          },
          success: function(msg) {
            if (msg.code == 200) {
              self.fileList = [];
              $.each(msg.data.list, (i, v) => {
                //文件大小
                let filesizeM = v.size / 1024 / 1024,
                  failsize,
                  filesizeK = v.size / 1024;
                if (filesizeM > 1) {
                  failsize = filesizeM.toFixed(2) + "M";
                } else {
                  failsize = filesizeK.toFixed(2) + "K";
                }

                self.fileList.push({
                  name: v.name,
                  time: v.createTime.substring(0, 10),
                  time1: v.createTime,
                  size: failsize,
                  token: v.token,
                  id: v.id,
                  folderId: v.folderId,
                  change: false
                });
              });
            }
          }
        });
        this.fileTableShow = true;
      }
    }
    //加载货币信息
    if (this.tradecurrencyall == "") {
      this.getTradeCurrencyAll();
    }
    $.each(this.tradecurrencyall.crmcurrencylist, function(i, row) {
      var name1 = row.name;
      if (name1.length > 12) {
        name1 = name1.substring(0, 12) + "...";
      }
      self.currencyList.push({
        cid: row.id,
        name: row.name,
        name1: name1
      });
    });
    //总额选中
    this.currencyall = self.currencyList[0].cid;
    //编辑
    if (this.saleId > 0) {
      this.getSaleInfo();
    } else {
      this.productLoading = false;
    }
    //上传文件类型
    if (this.uploadtype == "") {
      this.find_limitUploadFormat();
    }
    var acceptType = this.uploadtype.docs + "," + this.uploadtype.images;
    var str1 = acceptType.split(",");
    for (var i in str1) {
      this.fileType += "." + str1[i] + ",";
    }
    this.fileType = this.fileType.substring(0, this.fileType.length - 1);

    //计量单位
    if (this.calculateInfo == "") {
      this.getCalculateAllInfo();
    }
    $.each(this.calculateInfo, function(i, row) {
      var name1 = row.name;
      if (name1.length > 4) {
        name1 = name1.substring(0, 4) + "...";
      }
      self.calculateList.push({
        caid: row.id,
        name: row.name,
        name1: name1
      });
    });
    //物流公司
    if (this.logisticsCInfo == "") {
      this.getLogisticsCompanyAllInfo();
    }
    $.each(this.logisticsCInfo, function(i, row) {
      var name1 = row.name;
      if (name1.length > 12) {
        name1 = name1.substring(0, 12) + "...";
      }
      self.logisticsCList.push({
        id: row.id,
        name: row.name,
        name1: name1
      });
    });
    //物流方式
    if (this.logisticsWInfo == "") {
      this.getLogisticsWayAllInfo();
    }
    $.each(this.logisticsWInfo, function(i, row) {
      var name1 = row.name;
      if (name1.length > 12) {
        name1 = name1.substring(0, 12) + "...";
      }
      self.logisticsWList.push({
        id: row.id,
        name: row.name,
        name1: name1
      });
    });
    //订单状态
    if (this.orderStatusInfo == "") {
      this.getOrderStatusAllInfo();
    }
    $.each(this.orderStatusInfo, function(i, row) {
      self.orderStatusList.push({
        id: row.id,
        name: row.name
      });
    });
  }
};
</script>
<style lang="less">
.crm-tab3 {
  td {
    font-size: 12px;
    text-align: left;
    input {
      vertical-align: middle;
      margin: 0;
    }
    select {
      width: 197px;
      height: 30px;
    }
    input {
      height: 30px;
      width: 197px;
      font-size: 14px;
      margin: 0;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      border: 1px solid #ddd;
    }
  }
  .cu-t {
    border-bottom: 1px solid #ddd;
  }
  .usz {
    width: 60px;
    height: 28px;
  }
  .rsp {
    margin-left: 5px;
  }
  .remind {
    margin: 0 10px;
  }
  .cu_tbg {
    width: 72px;
    height: 30px;
  }
  .pSelect {
    cursor: pointer;
    color: #20a0ff;
  }
  .money-input {
    width: 200px !important;
  }
}

.smallLayer {
  .el-dialog {
    width: 225px !important;
  }
}

.money-input .el-date-editor.el-input {
  width: 214px !important;
  height: 30px;
}
</style>

<style scoped>
.file-new {
  padding: 1px 1px;
  cursor: pointer;
  position: relative;
  border: 1px solid #bfcbd9;
  border-radius: 2px;
}

.file-new i {
  color: #3b8cff;
}

.lookFj {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 99999;
}

.lf-header {
  height: 54px;
  background: #20a0ff;
}

.lfh-title {
  color: #fff;
  position: absolute;
  left: 20px;
  top: 18px;
  font-size: 16px;
  max-width: 1100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.lfh-download {
  position: absolute;
  top: 18px;
  right: 100px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
}

.lfh-close {
  position: absolute;
  top: 18px;
  font-size: 16px;
  right: 15px;
  width: 60px;
  height: 30px;
  color: #fff;
  text-align: center;
  cursor: pointer;
}

.lf-content {
  position: absolute;
  top: 56px;
  bottom: 0;
  text-align: center;
}

.lfc-main {
  background-color: #fff;
  border: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
/**/

.ct3-send {
  width: 68px;
  height: 26px;
  border: none;
  border-radius: 2px;
  font-size: 12px;
  color: #fff;
  background-color: #20a0ff;
  cursor: pointer;
}

.ct3-send:hover {
  background: #4db3ff;
  border-color: #4db3ff;
  color: #fff;
}

.fileListTr > td {
  border-bottom: 1px solid #d3d3d3 !important;
}

.fileTable .crm-connectionTab {
  font-size: 12px;
}

.fileTable .crm-connectionTab tr:hover {
  background-color: #eef1f6;
}

.fileTable .crm-connectionTab tr:first-child:hover {
  background-color: transparent;
}

.fileTable .crm-connectionTab .th {
  border-bottom: 1px solid #d3d3d3;
  line-height: 40px;
  height: 40px;
  text-align: left;
  font-weight: normal;
  color: #444;
  background: #eef1f6;
  border-top: 1px solid #d3d3d3;
  color: #999;
}

.fileTable .crm-connectionTab .th:first-child {
  border-left: 1px solid #d3d3d3;
}

.fileTable .crm-connectionTab .th .spot {
  display: inline-block;
  width: 9px;
  height: 9px;
  background-color: red;
  border-radius: 50%;
  cursor: pointer;
}

.fileTable.crm-connectionTab .th .more-linkinfo-btn {
  cursor: pointer;
  color: #20a0ff;
}

.fileTable .crm-connectionTab .th .more-linkinfo-btn:hover {
  background-color: #20a0ff;
  color: #fff;
  border-radius: 2px;
}

.fileTable .crm-connectionTab .th select {
  border: 1px solid #bfcbd9;
  border-radius: 2px;
  height: 25px;
  width: 105px;
  color: #999;
}

.fileTable .crm-connectionTab .td {
  border-bottom: 1px solid #d3d3d3;
  line-height: 40px;
  height: 40px;
  text-align: left;
  font-weight: normal;
  color: #444;
}

.fileTable .crm-connectionTab .td:first-child {
  border-left: 1px solid #d3d3d3;
}

.fileTable .crm-connectionTab .td .spot {
  display: inline-block;
  width: 9px;
  height: 9px;
  background-color: red;
  border-radius: 50%;
}

.fileTable .crm-connectionTab .td .more-linkinfo-btn {
  cursor: pointer;
  color: #20a0ff;
}

.fileTable .crm-connectionTab .td .more-linkinfo-btn:hover {
  background-color: #20a0ff;
  color: #fff;
  border-radius: 2px;
}

.select-currency {
  height: 30px;
  width: 70px;
  font-size: 12px;
  margin: 0;
  box-sizing: border-box;
  border: 1px solid #ddd;
  padding-top: 0px !important;
}
</style>
<style>
.o_add {
  user-select: none;
  cursor: pointer;
  display: inline-block;
  width: 12px;
  height: 12px;
  background: url("../../../../static/img/crm_images/icon/right.png");
}

.o_recycle {
  user-select: none;
  cursor: pointer;
  display: inline-block;
  width: 12px;
  height: 12px;
  background: url("../../../../static/img/crm_images/icon/wrong.png");
}
</style>