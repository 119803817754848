<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：客户·客户详细信息·新建跟进弹窗
开始时间：2017-11-9
开发人员：陈立珍
最后修改：2018-7-23
备注说明：如需修改请联系开发人员
-->

<template>
  <div id="newFollow" v-loading="addLoading" element-loading-text="正在新增跟进计划/记录，请稍后！">
    <table width="100%" cellspacing="0" cellpadding="0" class="newTab1">
      <tr>
        <td class="newF-usz">跟进类型</td>
        <td colspan="3">
          <el-radio-group v-model="radio1">
            <el-radio :label="-1">计划</el-radio>
            <el-radio :label="2">记录</el-radio>
          </el-radio-group>
        </td>
      </tr>
      <tr>
        <td class="newF-usz">跟进方式</td>
        <td colspan="3">
          <select v-model="wayid" id="wayname" class="add_select">
            <option value="0">请选择</option>
            <option v-for="way in addFollowWayList" :value="way.id" :title="way.name">{{way.name1}}</option>
          </select>
          <span class="rsp" v-show="picked">
            <el-checkbox v-model="isAuto">是否自动执行</el-checkbox>
          </span>
        </td>
      </tr>
      <tr>
        <td class="newF-usz">跟进时间</td>
        <td colspan="3">
          <span v-show="radio1==-1">
            <el-date-picker
              style="width: 140px;"
              v-model="dateValue"
              placeholder="选择日期"
              type="date"
              :clearable="false"
              :editable="false"
              :picker-options="pickerOptions0"
            ></el-date-picker>
          </span>
          <span v-show="radio1!=-1">
            <el-date-picker
              style="width: 140px;"
              v-model="dateValue"
              placeholder="选择日期"
              type="date"
              :clearable="false"
              :editable="false"
              :picker-options="pickerOptions2"
            ></el-date-picker>
          </span>
          <el-time-picker
            style="width: 140px;"
            v-model="timeValue"
            :picker-options="{selectableRange: '00:00:00 - 23:59:59'}"
            placeholder="选择时间"
            :editable="false"
            :clearable="false"
          ></el-time-picker>
          <span class="rsp" v-show="radio1==-1">
            <span class="remind">提醒</span>
            <select v-model="remindid">
              <option value="0">请选择</option>
              <option value="1">10分钟前</option>
              <option value="2">30分钟前</option>
              <option value="3">1小时前</option>
              <option value="4">2小时前</option>
              <option value="5">6小时前</option>
              <option value="6">1天前</option>
            </select>
          </span>
        </td>
      </tr>
      <tr>
        <td class="newF-usz">销售进程</td>
        <td colspan="3">
          <select v-model="processid" class="add_select">
            <option value="0">请选择</option>
            <option
              v-for="process in addSalesProcessList"
              :value="process.id"
              :title="process.name"
            >{{process.name1}}</option>
          </select>
        </td>
      </tr>
      <tr v-show="!isAuto">
        <td class="cu_tbg">跟进内容</td>
        <td colspan="3">
          <div contenteditable="true" id="followContentId" class="f-content"></div>
        </td>
      </tr>
      <tr v-show="isAuto" class="editor-title-box">
        <td class="newF-usz">邮件主题</td>
        <td>
          <input type="text" class="editor-title" v-model="subject" />
          <select v-model="modulid" class="editor-title-option">
            <option value="0">（不使用模板）</option>
            <option
              v-for="model in modellist2"
              :value="model.id"
              :title="model.title"
            >{{model.title1}}</option>
          </select>
        </td>
      </tr>
      <tr v-show="isAuto">
        <td class="newF-usz">邮件内容</td>
        <td>
          <textarea id="mailBodyId" name="mailBodyId" style="width:100%;height:350px;"></textarea>
        </td>
      </tr>
      <tr>
        <td class="newF-usz">关联产品</td>
        <td colspan="3">
          <!-- <span
            @click="selectProduct"
            class="pSelect"
            title="选择关联的产品"
          >{{productNum==0?'请选择':'选择了'+productNum+'个产品'}}</span> -->
          <com-product :choiceProductArr="productids" @cofirmProduct="cofirmProduct"></com-product>
        </td>
      </tr>
    </table>
    <!-- 选择关联产品 -->
    <!--<small-layer
        class="smallLayer"
        v-if="showC"
        @cntClose="showC = false"
        @cntConfirm="cntConfirm"
        :showType="4"
      	:productIds="productids"
      	:productids2="productids2"
        :connect-tab="false"
        cnt-title="选择关联产品"
        :has-button="true">
    </small-layer>-->
    <!-- 关联产品 -->
    <!-- <relatedProduct
      v-if="showC"
      :productids2="productids2"
      :productType="1"
      @closePro="showC = false"
      @cntConfirm="cntConfirm"
    ></relatedProduct> -->
  </div>
</template>
<script>
import store from "../../../vuex/store.js";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import "../../../vuex/mutations.js";
import smallLayer from "./smallLayer.vue";
import relatedProduct from "./layer_related_product.vue";
import comProduct from "@/components/common_file/com_product/comProduct";
var editor = "";
export default {
  name: "newFollow",
  props: [
    "followupType" //客户id,多个以逗号分割
  ],
  data() {
    return {
      productids: "", //选择的关联产品id,多个以逗号分割
      productids2: "",
      productNum: 0, //选择的关联产品数量
      radio1: -1, //跟进类型,-1计划,2记录
      dateValue: "", //跟进日期
      timeValue: "", //跟进时间
      wayid: 0, //跟进方式id
      processid: 0, //销售进程id
      remindid: 0, //提醒类型
      picked: false, //是否显示自动执行
      isAuto: false, //是否自动执行
      modulid: 0, //模板id
      subject: "", //邮件主题

      defaultMsg: "",
      config: {
        initialFrameWidth: 527,
        initialFrameHeight: 268
      },
      showC: false,
      pickerOptions1: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            }
          }
        ]
      },
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      pickerOptions2: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      addFollowWayList: [],
      addSalesProcessList: [],
      addLoading: false,
      modellist2: [] //模板
    };
  },
  components: {
    smallLayer,
    relatedProduct,
    comProduct
  },
  computed: {
    ...mapState({
      userinfo: state => state.userinfo,
      followupwayall: state => state.followupwayall,
      saleprocessall: state => state.saleprocessall,
      modellist: state => state.modellist,
      userparamlist: state => state.userparamlist,
      companyparamlist: state => state.companyparamlist,
      customerEmailInfo: state => state.customerEmailInfo,
      mailaccountlist: state => state.mailaccountlist
    })
  },
  methods: {
    ...mapMutations({
      getFollowupWayAll: "getFollowupWayAll",
      getSaleProcessAll: "getSaleProcessAll",
      getModellist: "getModellist",
      fun_findAccountlist: "fun_findAccountlist",
      updateFollowcount: "updateFollowcount"
    }),
    ...mapActions(["getComsterInfo",'getAccountSent']),
    //确认关联产品
    cntConfirm(proids) {
      var pro_ids = "";
      $.each(proids, function(index, row) {
        pro_ids += row.id + ",";
      });
      pro_ids = pro_ids.substring(0, pro_ids.length - 1);
      this.productids = pro_ids;
      if (proids != "") {
        this.productNum = this.productids.split(",").length;
      } else {
        this.productNum = 0;
      }
      //弹窗选中
      var production_value = "";
      $.each(proids, function(i, row) {
        var pid = row.id;
        var pname = row.name;
        production_value += pid + "###" + pname + ",";
      });
      production_value = production_value.substring(
        0,
        production_value.length - 1
      );
      this.productids2 = production_value;
    },
    //选择的产品id（产品组件)
    cofirmProduct(ids){
      this.productids = ids
    },
    //添加跟进提交
    subButton(customerids) {
      //跟进内容
      var content = $("#followContentId").html();
      if (content.length > 2000) {
        this.$message({
          type: "warning",
          showClose: true,
          message: "跟进内容不能超过2000个字符！",
          duration: 2000
        });
        return;
      }
      if (this.isAuto) {
        //发邮件跟进
        if (this.dateValue == "") {
          this.$message({
            showClose: true,
            message: "请选择跟进日期！",
            type: "warning",
            duration: 2000
          });
          return;
        }
        if (this.timeValue == "") {
          this.$message({
            showClose: true,
            message: "请选择跟进时间！",
            type: "warning",
            duration: 2000
          });
          return;
        }
        var followDate = new Date(this.dateValue);
        var followTime = new Date(this.timeValue);
        var year = followDate.getFullYear();
        var month = followDate.getMonth();
        var day = followDate.getDate();
        var hour = followTime.getHours();
        var minu = followTime.getMinutes();
        var second = followTime.getSeconds();
        var selectDate = new Date(year, month, day, hour, minu, second);
        var nowDate = new Date();
        if (selectDate.getTime() - nowDate.getTime() <= 3600000) {
          this.$message({
            showClose: true,
            message: "跟进时间必须大于当前1个小时！",
            type: "warning",
            duration: 2000
          });
          return;
        }
        if (this.processid == 0) {
          this.$message({
            showClose: true,
            message: "请选择销售进程！",
            type: "warning",
            duration: 2000
          });
          return;
        }
        if (this.subject.trim() == "") {
          this.$message({
            showClose: true,
            message: "请输入邮件主题！",
            type: "warning",
            duration: 2000
          });
          return;
        }
        if (editor.html().trim() == "") {
          this.$message({
            showClose: true,
            message: "请输入邮件内容！",
            type: "warning",
            duration: 2000
          });
          return;
        }
        var yearStr = year.toString();
        var monthStr = (month + 1).toString();
        if (month + 1 < 10) {
          monthStr = "0" + (month + 1).toString();
        }
        var dayStr = day.toString();
        if (day < 10) {
          dayStr = "0" + day;
        }
        var dateStr = yearStr + "-" + monthStr + "-" + dayStr;
        var hourStr = hour.toString();
        if (hour < 10) {
          hourStr = "0" + hour;
        }
        var minuStr = minu.toString();
        if (minu < 10) {
          minuStr = "0" + minu;
        }
        var secondStr = second.toString();
        if (second < 10) {
          secondStr = "0" + second;
        }
        var timeStr = hourStr + ":" + minuStr + ":" + secondStr;

        var savepath = this.userparamlist.param111;
        if (savepath == "" || savepath == null) {
          savepath = this.companyparamlist.param113;
        }
        var lef = this;
        $.ajax({
          type: "POST",
          url: backUrl + "cusfu3_addFollowupsEmail.action",
          async: true,
          cache: false,
          dataType: "json",
          data: {
            token: token,
            customer_ids: customerids,
            subject: lef.subject,
            context: editor.html(),
            savepath: savepath,
            "customerfollowup.create_user_id": lef.userinfo.user_id,
            "customerfollowup.followup_content": "",
            "customerfollowup.followup_time": dateStr + " " + timeStr,
            "customerfollowup.followup_classify": -1,
            "customerfollowup.followup_way": lef.wayid,
            "customerfollowup.remind_time_classify": lef.remindid,
            "customerfollowup.remind_time": "",
            "customerfollowup.purpose_process": lef.processid,
            "customerfollowup.focus_product": lef.commonJs.returnString(lef.productids),
            "customerfollowup.is_msg_remind": 0,
            "customerfollowup.is_email_remind": 0,
            "customerfollowup.mail_id": 0
          },
          beforeSend: function() {
            lef.addLoading = true;
            lef.$emit("changeDisabled");
          },
          complete: function() {
            lef.addLoading = false;
          },
          success: function(msg) {
            if (msg.result > 0) {
              lef.$message({
                showClose: true,
                message: "添加跟进计划成功，系统将在跟进时间发送该邮件！",
                type: "success",
                duration: 2000
              });
                // 统计跟进记录
                lef.updateFollowcount(customerids);
							  //获取客户信息
                lef.getComsterInfo(customerids);
                let linkmanid = 0;
                let time = "";
                let email = "";
                $.each(lef.customerEmailInfo, (j, jelem) => {
                  if (jelem.is_main_linkman == 1) {
                    linkmanid = jelem.linkman_id;
                    email = jelem.email;
                    return false;
                  }
                });
                if (lef.mailaccountlist == "") {
                  lef.fun_findAccountlist();
                }
                let defaultId = 0;
                let poporimapType = 0;
                let userEmail = "";
                $.each(lef.mailaccountlist, (f, row) => {
                  if (row.defalut_show == 1) {
                    defaultId = row.account_id;
                    poporimapType = row.poporimap;
                    userEmail = row.mail_account;
                    return false;
                  } else if (row.is_default == 0) {
                    defaultId = row.account_id;
                    poporimapType = row.poporimap;
                    userEmail = row.mail_account;
                    return false;
                  }
                });
                lef.getAccountSent({
                  customer_id: customerids,
                  linkmanid: linkmanid,
                  mail_uid: msg.resultstr,
                  receive_mail: email,
                  send_time: dateStr + " " + timeStr,
                  account_id: defaultId,
                  mail_account: userEmail,
                  poporimap: poporimapType
								});
								 lef.$emit("submitok");
              lef.$emit("changeDisabled");
              
            } else {
              lef.$message({
                showClose: true,
                message: "系统异常，请稍后重试！",
                type: "warning",
                duration: 2000
              });
            }
          }
        });
      } else {
        //不发邮件跟进
        if (this.radio1 == -1) {
          //跟进计划
          if (this.dateValue == "") {
            this.$message({
              showClose: true,
              message: "请选择跟进日期！",
              type: "warning",
              duration: 2000
            });
            return;
          }
          if (this.timeValue == "") {
            this.$message({
              showClose: true,
              message: "请选择跟进时间！",
              type: "warning",
              duration: 2000
            });
            return;
          }
          var followDate = new Date(this.dateValue);
          var followTime = new Date(this.timeValue);
          var year = followDate.getFullYear();
          var month = followDate.getMonth();
          var day = followDate.getDate();
          var hour = followTime.getHours();
          var minu = followTime.getMinutes();
          var second = followTime.getSeconds();
          var selectDate = new Date(year, month, day, hour, minu, second);
          var nowDate = new Date();
          if (selectDate.getTime() - nowDate.getTime() <= 3600000) {
            this.$message({
              showClose: true,
              message: "跟进时间必须大于当前1个小时！",
              type: "warning",
              duration: 2000
            });
            return;
          }
          if (this.processid == 0) {
            this.$message({
              showClose: true,
              message: "请选择销售进程！",
              type: "warning",
              duration: 2000
            });
            return;
          }
          if (content.length == 0) {
            this.$message({
              showClose: true,
              message: "跟进内容不能为空！",
              type: "warning",
              duration: 2000
            });
            return;
          }
          var yearStr = year.toString();
          var monthStr = (month + 1).toString();
          if (month + 1 < 10) {
            monthStr = "0" + (month + 1).toString();
          }
          var dayStr = day.toString();
          if (day < 10) {
            dayStr = "0" + day;
          }
          var dateStr = yearStr + "-" + monthStr + "-" + dayStr;
          var hourStr = hour.toString();
          if (hour < 10) {
            hourStr = "0" + hour;
          }
          var minuStr = minu.toString();
          if (minu < 10) {
            minuStr = "0" + minu;
          }
          var secondStr = second.toString();
          if (second < 10) {
            secondStr = "0" + second;
          }
          var timeStr = hourStr + ":" + minuStr + ":" + secondStr;
          var lef = this;
          $.ajax({
            type: "POST",
            url: backUrl + "cusfu3_addFollowups.action",
            async: true,
            cache: false,
            dataType: "json",
            data: {
              token: token,
              customer_ids: customerids,
              "customerfollowup.create_user_id": lef.userinfo.user_id,
              "customerfollowup.followup_content": content,
              "customerfollowup.followup_time": dateStr + " " + timeStr,
              "customerfollowup.followup_classify": -1,
              "customerfollowup.followup_way": lef.wayid,
              "customerfollowup.remind_time_classify": lef.remindid,
              "customerfollowup.remind_time": "",
              "customerfollowup.purpose_process": lef.processid,
              "customerfollowup.focus_product": lef.commonJs.returnString(lef.productids),
              "customerfollowup.is_msg_remind": 0,
              "customerfollowup.is_email_remind": 0,
              "customerfollowup.mail_id": 0,
              upuserid: lef.userinfo.user_id
            },
            beforeSend: function() {
              lef.addLoading = true;
              lef.$emit("changeDisabled");
            },
            complete: function() {
              lef.addLoading = false;
            },
            success: function(msg) {
              if (msg == 1) {
                lef.$message({
                  showClose: true,
                  message: "添加成功！",
                  type: "success",
                  duration: 2000
                });
                lef.$emit("submitok");
                // 统计跟进记录
                lef.updateFollowcount(customerids);
              } else {
                lef.$message({
                  showClose: true,
                  message: "添加失败！",
                  type: "warning",
                  duration: 2000
                });
              }
            },
            error: function(e) {
              lef.$message({
                showClose: true,
                message: "添加失败！",
                type: "warning",
                duration: 2000
              });
            }
          });
        } else {
          //跟进记录
          if (this.dateValue == "") {
            this.$message({
              showClose: true,
              message: "请选择跟进日期！",
              type: "warning",
              duration: 2000
            });
            return;
          }
          if (this.timeValue == "") {
            this.$message({
              showClose: true,
              message: "请选择跟进时间！",
              type: "warning",
              duration: 2000
            });
            return;
          }
          var followDate = new Date(this.dateValue);
          var followTime = new Date(this.timeValue);
          var year = followDate.getFullYear();
          var month = followDate.getMonth();
          var day = followDate.getDate();
          var hour = followTime.getHours();
          var minu = followTime.getMinutes();
          var second = followTime.getSeconds();
          var selectDate = new Date(year, month, day, hour, minu, second);
          var nowDate = new Date();
          if (selectDate.getTime() > nowDate.getTime()) {
            this.$message({
              showClose: true,
              message: "跟进时间不能大于当前时间！",
              type: "warning",
              duration: 2000
            });
            return;
          }
          if (this.processid == 0) {
            this.$message({
              showClose: true,
              message: "请选择销售进程！",
              type: "warning",
              duration: 2000
            });
            return;
          }
          if (content.length == 0) {
            this.$message({
              showClose: true,
              message: "跟进内容不能为空！",
              type: "warning",
              duration: 2000
            });
            return;
          }
          var yearStr = year.toString();
          var monthStr = (month + 1).toString();
          if (month + 1 < 10) {
            monthStr = "0" + (month + 1).toString();
          }
          var dayStr = day.toString();
          if (day < 10) {
            dayStr = "0" + day;
          }
          var dateStr = yearStr + "-" + monthStr + "-" + dayStr;
          var hourStr = hour.toString();
          if (hour < 10) {
            hourStr = "0" + hour;
          }
          var minuStr = minu.toString();
          if (minu < 10) {
            minuStr = "0" + minu;
          }
          var secondStr = second.toString();
          if (second < 10) {
            secondStr = "0" + second;
          }
          var timeStr = hourStr + ":" + minuStr + ":" + secondStr;
          var lef = this;
          $.ajax({
            type: "POST",
            url: backUrl + "cusfu3_addFollowups.action",
            async: true,
            cache: false,
            dataType: "json",
            data: {
              token: token,
              customer_ids: customerids,
              "customerfollowup.create_user_id": lef.userinfo.user_id,
              "customerfollowup.followup_content": content,
              "customerfollowup.followup_time": dateStr + " " + timeStr,
              "customerfollowup.followup_classify": 2,
              "customerfollowup.followup_way": lef.wayid,
              "customerfollowup.remind_time_classify": 0,
              "customerfollowup.remind_time": "",
              "customerfollowup.purpose_process": lef.processid,
              "customerfollowup.focus_product": lef.commonJs.returnString(lef.productids),
              "customerfollowup.is_msg_remind": 0,
              "customerfollowup.is_email_remind": 0,
              "customerfollowup.mail_id": 0,
              upuserid: lef.userinfo.user_id
            },
            beforeSend: function() {
              lef.addLoading = true;
              lef.$emit("changeDisabled");
            },
            complete: function() {
              lef.addLoading = false;
            },
            success: function(msg) {
              if (msg == 1) {
                lef.$message({
                  showClose: true,
                  message: "添加成功！",
                  type: "success",
                  duration: 2000
                });
                lef.$emit("recordok");
                // 统计跟进记录
                lef.updateFollowcount(customerids);
              } else {
                lef.$message({
                  showClose: true,
                  message: "添加失败！",
                  type: "warning",
                  duration: 2000
                });
              }
            },
            error: function(e) {
              lef.$message({
                showClose: true,
                message: "添加失败！",
                type: "warning",
                duration: 2000
              });
            }
          });
        }
      }
    },
    //选择关联产品弹窗
    selectProduct() {
      this.showC = true;
    }
  },
  mounted() {
    //加载跟进方式
    if (this.followupwayall == "") {
      this.getFollowupWayAll();
    }
    var self = this;
    $.each(this.followupwayall, function(i, row) {
      var name1 = row.name;
      if (name1.length > 9) {
        name1 = name1.substring(0, 9) + "...";
      }
      self.addFollowWayList.push({
        id: row.id,
        name: row.name,
        name1: name1
      });
    });
    $.each(this.saleprocessall, function(i, row) {
      var name1 = row.name;
      if (name1.length > 9) {
        name1 = name1.substring(0, 9) + "...";
      }
      self.addSalesProcessList.push({
        id: row.id,
        name: row.name,
        name1: name1
      });
    });
    //加载销售进程
    if (this.getSaleProcessAll == "") {
      this.getSaleProcessAll();
    }
    //加载用户模板
    if (this.modellist == "") {
      this.getModellist();
    }
    $.each(this.modellist, function(i, row) {
      var title1 = row.title;
      if (title1.length > 9) {
        title1 = title1.substring(0, 9) + "...";
      }
      self.modellist2.push({
        title1: title1,
        title: row.title,
        id: row.id
      });
    });
    this.radio1 = this.followupType;
    //初始化编辑器
    setTimeout(function() {
      editor = KindEditor.create('textarea[name="mailBodyId"]', {
        themeType: "simple",
        items: [
          "source",
          "|",
          "fontname",
          "fontsize",
          "|",
          "forecolor",
          "hilitecolor",
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "lineheight",
          "removeformat",
          "|",
          "link",
          "unlink"
        ],
        allowFileManager: true,
        filterMode: false,
        allowImageUpload: true,
        autoHeightMode: false,
        resizeType: 0,
        afterCreate: function() {
          this.loadPlugin("autoheight");
        },
        afterBlur: function() {
          this.sync();
        }
      });
    }, 0);
    console.log(this.followupType);
  },
  watch: {
    radio1() {
      if (
        this.radio1 == -1 &&
        $("#wayname")
          .find("option:selected")
          .text() == "邮件"
      ) {
        this.picked = true;
      } else {
        this.picked = false;
        this.isAuto = false;
      }
    },
    wayid() {
      if (
        this.radio1 == -1 &&
        $("#wayname")
          .find("option:selected")
          .text() == "邮件"
      ) {
        this.picked = true;
      } else {
        this.picked = false;
        this.isAuto = false;
      }
    },
    modulid() {
      if (this.modulid == 0) {
        editor.html("");
      } else {
        var body = "";
        var lef = this;
        $.each(lef.modellist, function(index, row) {
          if (row.id == lef.modulid) {
            body = row.context;
            return false;
          }
        });
        editor.html(body);
      }
    }
  }
};
</script>
<style lang="less">
.newTab1 .pSelect {
  color: #20a0ff;
  cursor: pointer;
}
.el-input__inner {
  height: 30px;
}
.newTab1 td {
  padding: 8px 5px;
}
.newTab1 .newF-usz {
  white-space: nowrap;
}
.f-content {
  resize: none;
  width: 360px;
  height: 100px;
  font-size: 12px;
  font-family: "Microsoft yahei";
  padding: 5px 5px;
  overflow: auto;
  border: 1px solid #bfcbd9;
  cursor: auto;
  margin: 5px 0;
}
.el-time-spinner__item {
  line-height: 30px !important;
}
.newTab1 {
  .editor-title-box {
    .editor-title {
      height: 30px;
      width: 403px;
    }
    .editor-title-option {
      width: 118px;
      height: 30px;
    }
  }
}
.add_select {
  border-radius: 2px;
  height: 30px;
  width: 140px;
}
</style>
