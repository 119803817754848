<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：客户·客户详细信息·邮件信息弹窗
开始时间：2017-11-9
开发人员：陈立珍
最后修改：2018-12-14
备注说明：如需修改请联系开发人员
-->

<template>
	<div id="message-info">
		<el-dialog title="邮件信息" :visible.sync="test" size="tiny" class='message-info-box dialogTwo' :before-close="closeMessageInfo" :modal="false" :modal-append-to-body="true" :lock-scroll="true" :close-on-click-modal="false">
			<div>
				<table width="100%" cellpadding="0" cellspacing="0" border="0" class="messageInfoTab">
					<tbody>
						<tr>
							<td style="text-align: left;">主&nbsp;&nbsp;&nbsp;题：</td>
							<td class="info-title" id="info-title">{{subject}}</td>
						</tr>
						<tr>
							<td style="text-align: left;">发件人：</td>
							<td>{{sendName}}&lt;{{sendAddress}}&gt;</td>
						</tr>
						<tr>
							<td style="text-align: left;">回复人：</td>
							<td>{{replyInfo}}</td>
						</tr>
						<tr>
							<td style="text-align: left;">收件人：</td>
							<td>{{toInfo}}</td>
						</tr>
						<tr v-show="ccInfo != ''">
							<td style="text-align: left;">抄件人：</td>
							<td>{{ccInfo}}</td>
						</tr>
						<tr>
							<td style="text-align: left;">时&nbsp;&nbsp;&nbsp;间：</td>
							<td>{{sendTime}}</td>
						</tr>
						<!--附件-->
						<tr v-if="cusIsAttrShow">
							<td style="text-align: left;">附&nbsp;&nbsp;&nbsp;件：</td>
							<td>
								<div style="color: #20A0FF;" v-show='cusLoading'>
									<i class="el-icon-loading "></i>
									<span>附件较大加载中,请稍候...</span>
								</div>
								<div class="btn-box" style="margin-bottom: 0px;" v-show='!cusLoading'>
									<a href="#crm_mail_attrfile" class="save-btn" title="查看附件">{{attrList.length}}个</a>
								</div>
							</td>
						</tr>
						<!--备注-->
						<tr v-show="showRemark">
							<td style="text-align: left;">备&nbsp;&nbsp;&nbsp;注：</td>
							<td>
								<textarea v-model="remarkInfo" cols="30" rows="10" class="remark"></textarea>
								<div class="btn-box">
									<span @click="saveRemark" class="save-btn">保存</span>
									<span @click="showRemark=false" class="cancel-btn">取消</span>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<!--邮件内容-->
			<div style="margin-left: 20px;" v-loading="translationLoading" element-loading-text="翻译中...">
				<textarea id="mailBodyId" name="mailBodyId"></textarea>
			</div>
			<!--附件区-->
			<div id="crm_mail_attrfile" class="cus_con_t_fj" v-if="cusIsAttrShow">
				<div class="cus_fj_1">
					<span class="cus_fj_t">附件</span>
					<span class="cus_fj_t_num">({{attrList.length}})</span>
					<span class="cus_fj_upload" v-if="attrList.length > 1" @click="cusPick_to_download(data_str)" :title="downloadAll">打包下载</span>
					<span style="float: right; cursor: pointer;">
						<el-tooltip effect="light" placement="top">
						<div slot="content">附件预览格式支持：ppt、pptx、ppsx、potx、pps、pdf、
							dot、dotx、doc、docx、xlsx、xls、txt、jpeg、jpg、gif、png、bmp、tif。</div>
						<i class="icon iconfont icon-icquestioncircleo" style="color: #20A0FF;font-size: 14px;"></i>
						</el-tooltip>
					</span>
				</div>
				<!--附件单个开始-->
				<div class="cus_fj_2" style="position: relative; min-height: 160px;">
					<div v-for="(attr,index) in attrList" class="cus-attr-relative">
						<div class="cus_fj_kj" @mouseenter='cusAttr_mouseover(index)' @mouseleave='cusAttr_mouseleave()'>
							<div class="cus_fj_kj_1" :class="attr.suffix"></div>
							<div class="cus_fj_kj_2">{{attr.filename}}</div>
							<!--样式-->
							<div class="cus-Data" v-show="attrOperateaShow == index">
								<div class="uo-arrow"></div>
								<div class="uo-pd-havedata">
									<ul>
										<li style="border-bottom: 1px solid #8d8f92;">
											<p class="attr_filename" :title="attr.filename">{{attr.filename}}</p>
										</li>
										<li>
											<span class="uo-pd-havedata-main">
												<span @click="attrDownload(attr.downpath)">
												<i class="icon iconfont icon-download attrupload"></i>
												<p style="margin-top: -4px;">下载</p>
												</span>
											</span>
											<span class="uo-pd-havedata-main" v-show="attr.isview">
												<span @click="cusView_file(attr.downpath)">
													<i class="icon iconfont icon-eyes attrupload"></i>
												<p style="margin-top: -4px;">预览</p>
												</span>
											</span>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<!--loading-->
					<div class="myJopLoading1" v-show='cusLoading' style="z-index: 10;">
						<i class="el-icon-loading " style="top: 40%;"></i>
						<span>附件较大加载中,请稍候...</span>
					</div>
				</div>
			</div>
			<!--底部确认取消按钮-->
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="translateClick">{{translateBtnTitle}}</el-button>
		        <el-button type="primary" @click="replyMessageInfo" v-if="showReply">回 复</el-button>
		        <el-button @click="showRemark=true"><a href="#info-title" class="cus-note-btn">备 注</a></el-button>
      </span>
		</el-dialog>

		<!-- 预览-->
		<div>
			<transition name="slide-fade">
				<div class="lookFj" v-show='viewFJ'>
					<div class="lf-header w100">
						<div class="lfh-title" :title="viewHeadTitle">{{viewHeadTitle}}</div>
						<div class="lfh-download ">
							<i class="icon iconfont icon-download" style="font-size: 18px;"></i>
							<span>下载</span>
						</div>
						<div class="lfh-close" @click='viewFJ = false'>
							<i class="icon iconfont icon-close color-white"></i>
							<span>关闭</span>
						</div>
					</div>

					<div class="lf-content w100">
						<iframe :src="viewSrcAttr" width="90%" height="100%" class="lfc-main"></iframe>
					</div>
				</div>
			</transition>
		</div>
		<commonLoading v-if="packagingShow3" @loadingClose="packagingShow3=false" :loadingTitle="loadingTitle" :loadingContent="loadingContent"></commonLoading>
	</div>
</template>

<script>
	import store from '../../../vuex/store.js'
	import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
	import '../../../vuex/mutations.js';
	import commonLoading from '@/components/mail/loading/commonLoading'
	var editor = '';
	export default {
		name: "cm-more-info",
		data() {
			return {
				test: true,
				showRemark: false, //备注是否展示
				showReply: false, //回复是否展示
				remarkInfo: '', //备注内容
				subject: '', //主题
				sendName: '', //发件人
				sendAddress: '', //发件人地址
				replyInfo: '', //回复人
				ccInfo: '', //抄件人
				toInfo: '', //收件人
				sendTime: '', //时间
				//附件集合
				attrList: [], //附件集合
				cusLoading: true, //附件加载框
				cusIsAttrShow: false, //是否显示附件
				downloadAll: "", //下载全部的提示
				data_str: "", //所有打包下载文件的名称
				attrOperateaShow: -1,
				viewFJ: false, //预览附件
				viewHeadTitle: "", //预览附件的标题
				viewSrcAttr: "", //附件内容
				cusid: 0, //客户id
				translateBtnTitle: "译文",
				translateJSON: {}, //编译后的数据
				translationLoading: false, //翻译加载框

				packagingShow3: false, //打包下载加载框
				loadingTitle: "", //标题
				loadingContent: "", //加载框内容
			}
		},
		props: [
			'mailId', //邮件id
			'type', //0收件箱,1发件箱
			'cus_poporimap',
			'cus_mailAccount' //邮箱
		],
		components: {
			commonLoading
		},
		computed: {
			...mapState({
				userinfo: state => state.userinfo,
				userparamlist: state => state.userparamlist,
				companyparamlist: state => state.companyparamlist,
				nAttrMail: state => state.nAttrMail,
				hAttrMail: state => state.hAttrMail,
			}),
		},
		methods: {
			...mapMutations({
				getFollowupWayAll: 'getFollowupWayAll',
				getSaleProcessAll: 'getSaleProcessAll',
				getModellist: 'getModellist',
				parseNoAttrMail: 'parseNoAttrMail',
				parseAttrMail: 'parseAttrMail',
				submitDynamicInfoAsync: 'submitDynamicInfoAsync',
			}),
			//触发父组件关闭弹窗
			closeMessageInfo() {
				this.$emit('closeMessageInfo')
			},
			//回复
			replyMessageInfo() {
				this.$emit('replyMessageInfo', this.sendName + "<" + this.sendAddress + ">", this.mailId)
			},

			//保存备注
			saveRemark() {
				var lef = this;
				if(this.remarkInfo.trim() == '') {
					this.$message({
						showClose: true,
						message: '请输入您要备注的内容！',
						type: 'warning',
						duration: 2000
					});
					return;
				}
				if($.trim(this.remarkInfo).length > 200) {
					this.$message({
						showClose: true,
						message: '备注内容不能超过200个字符！',
						type: 'warning',
						duration: 2000
					});
					return;
				}
				if(this.type == 0) { //收件箱
					var action = "xmailr_updateMailFlag.action";
					var flagData = {
						token: token,
						idstr: this.mailId,
						conditions: 12,
						conditionsvalue: this.remarkInfo
					}
					if(this.cus_poporimap == 1) {
						action = "imap_updateMailFlag.action";
						flagData = {
							token: token,
							idstr: this.mailId,
							condition: 12,
							conditionvalue: this.remarkInfo
						}
					}
					$.ajax({
						type: "POST",
						url: backUrl + action,
						data: flagData,
						dataType: "json",
						cache: false,
						async: false,
						success: function(msg) {
							if(msg == 1) {
								lef.$message({
									showClose: true,
									message: '备注成功！',
									type: 'success',
									duration: 2000
								});
								lef.submitDynamicInfoAsync({
									customer_id: lef.cusid,
									dynamic: lef.remarkInfo
								})
							} else {
								lef.$message({
									showClose: true,
									message: '备注失败！',
									type: 'warning',
									duration: 2000
								});
							}
						},
						error: function(e) {
							lef.$message({
								showClose: true,
								message: '备注失败！',
								type: 'warning',
								duration: 2000
							});
						}
					});
				} else { //发件箱
					var action = 'xmailbase_updateMailFlag.action';
					if(this.cus_poporimap == 1) {
						action = 'imap_updateMailFlag.action';
					}
					$.ajax({
						type: "POST",
						url: backUrl + action,
						data: {
							token: token,
							idstr: lef.mailId,
							condition: 12,
							conditionvalue: lef.remarkInfo
						},
						dataType: "json",
						cache: false,
						async: false,
						success: function(msg) {
							if(msg == 1) {
								lef.$message({
									showClose: true,
									message: '备注成功！',
									type: 'success',
									duration: 2000
								});
								var receivesNum = 0;
								var reData = [];
								if(lef.cus_poporimap == 0) {
									receivesNum = lef.nAttrMail.sendmailbase.detaillist.length;
									$.each(lef.nAttrMail.sendmailbase.detaillist, function(i, row) {
										reData.push({
											email: row.email
										})
									});
								} else {
									var receives = lef.nAttrMail.emlimapdata.receiver;
									if(receives != null && receives != "") {
										if(receives.indexOf(',') == -1) {
											receivesNum = 1;
											reData.push({
												email: receives.split('<')[1].split('>')[0]
											})
										}
									}
								}
								if(receivesNum == 1) {
									$.each(reData, function(index1, row1) {
										//										$.each(lef.mylinkmanlist.crmlinkmanlist, function(index2, row2) {
										//											if(row1.email == row2.email) {
										//												lef.cusid = row2.customer_id;
										//												return false;
										//											}
										//										});
										$.ajax({
											type: "post",
											url: backUrl + "link_findInfoByEmail.action",
											dataType: "json",
											cache: false,
											data: {
												token: token,
												email: $.trim(row1.email),
												full_name: ''
											},
											success: function(msg) {
												lef.cusid = msg.cusid;
												lef.submitDynamicInfoAsync({
													customer_id: msg.cusid,
													dynamic: lef.remarkInfo
												})
											}
										});
									});
								}
							} else {
								lef.$message({
									showClose: true,
									message: '备注失败！',
									type: 'warning',
									duration: 2000
								});
							}
						},
						error: function(e) {
							lef.$message({
								showClose: true,
								message: '备注失败！',
								type: 'warning',
								duration: 2000
							});
						}
					});
				}
			},
			//查看附件hover
			cusAttr_mouseover(index) {
				this.attrOperateaShow = index;
			},
			//鼠标移除事件
			cusAttr_mouseleave() {
				this.attrOperateaShow = -1;
			},
			//附件下载
			attrDownload(path) {
				var urlHref = backUrl + "downloadFile.action?token=" + token + "&fileName=" + path;
				//$("#downloadid").attr("src", urlHref);
				window.open(urlHref);
			},
			//打包下载
			cusPick_to_download(filename) {
				var arr = [];
				var arr2 = "";
				var str1 = filename.split("@G@");
				for(var i = 0; i < str1.length - 1; i++) {
					var file_name1 = str1[i].split('@espeed@')[0];
					var file_name2 = str1[i].split('@espeed@')[1];
					if(arr.indexOf(file_name2) == -1) { //说明没有重复的
						arr.push(file_name2);
						arr2 += file_name1 + "@espeed@" + file_name2 + "@G@";
					} else { //说明有重复的
						var index1 = file_name2.lastIndexOf(".");
						var index2 = file_name2.length;
						var suffix = file_name2.substring(0, index1); //前缀名
						var postf = file_name2.substring(index1, index2); //文件的后缀名
						var params = suffix + "_" + i + postf;
						arr.push(params);
						arr2 += file_name1 + "@espeed@" + params + "@G@";
					}
				}
				var packagingfile = arr2.replace(/&/g, ":36:36:36");
				packagingfile = packagingfile.substring(0, packagingfile.length - 3);

				let self = this;
				this.packagingShow3 = true;
				this.loadingTitle = "打包中";
				this.loadingContent = "正在打包中..."
				setTimeout(function() {
					$.ajax({
						type: "post",
						url: backUrl + 'pkdown_packaging.action',
						dataType: "json",
						async: false,
						data: {
							token: token,
							fileNames: packagingfile,
							subject: self.subject
						},
						success: function(msg) {
							self.packagingShow3 = false;
							var msgStr = msg.replace("#","");
							if(msg != "error") {
								//$("#downloadid").attr("src", backUrl + "downloadFile.action?token=" + token + "&fileName=" + msgStr);
								window.open(backUrl + "downloadFile.action?token=" + token + "&fileName=" + msgStr);
							} else {
								self.$message({
									showClose: true,
									message: "打包错误,请联系客服人员！",
									type: 'error'
								});
							}
						}
					});
				}, 500)
				//				var url = backUrl + "downloadAllFile.action?token=" + token + "&fileNames=" + packagingfile + "&subject=" + this.subject;
				//				$("#downloadid").attr("src", url)
			},
			//预览附件
			cusView_file(filename) {
				var self = this;
				//点击预览
				this.viewFJ = true;
				var attr_str = filename.split("@espeed@");
				//预览附件的标题
				this.viewHeadTitle = attr_str[1];
				//预览src
				this.viewSrcAttr = ''
				//预览-下载
				$('.lfh-download').unbind('click').click(function() {
					self.attrDownload(filename);
				});
				//判断收发
				var isshouorfa = 1; //发
				if(this.type == 0) {
					isshouorfa = 0; //收
				}
				$.ajax({
					url: backUrl + "preview_findFullPath.action",
					cache: false,
					type: "post",
					dataType: "json",
					data: {
						token: token,
						isshouorfa: isshouorfa,
						mail_id: this.mailid,
						fileName: filename,
						poporimap: this.cus_poporimap
					},
					success: function(msg) {
						var attrhz = attr_str[0].split(".");
						var hzm = $.trim(attrhz[1]).toLowerCase();
						var url = "",
							link = "https://view.officeapps.live.com/op/embed.aspx?src= " + msg + "&wdStartOn=1";
						switch(hzm) {
							case "doc":
								url = link;
								break;
							case "docx":
								url = link;
								break;
							case "pptx":
								url = link;
								break;
							case "ppt":
								url = link;
								break;
							case "ppsx":
								url = link;
								break;
							case "potx":
								url = link;
								break;
							case "pps":
								url = link;
								break;
							case "dot":
								url = link;
								break;
							case "dotx":
								url = link;
								break;
							case "jpeg":
								url = msg;
								break;

							case "pdf":
								url = msg;
								break;
							case "psd":
								break;
							case "xls":
								url = link;
								break;
							case "xlsx":
								url = link;
								break;
							case "zip":
								break;
							case "rar":
								break;
							case "txt":
								url = msg;
								break;
							case "jpg":
								url = msg;
								break;
							case "gif":
								url = msg;
								break;
							case "png":
								url = msg;
								break;
							case "bmp":
								url = msg;
								break;
							case "tif":
								url = msg;
								break;
							default:
								break;
						}
						self.viewSrcAttr = url
						self.viewFJ = true
					}
				})
			},
			//加载解析附件(收件箱)
			load_cusParseAttach(mailId) {
				var self = this;
				var savepath = this.userparamlist.param111;
				if(savepath == "" || savepath == null || savepath == undefined) {
					savepath = this.companyparamlist.param113;
				}
				var action = 'xmailr_AnalysisMailAttr.action';
				if(this.cus_poporimap == 1) {
					action = 'imap_AnalysisMailAttr.action';
				}
				$.ajax({
					type: "post",
					url: backUrl + action, //解析邮件基本信息
					dataType: "json",
					data: {
						token: token,
						mailid: mailId,
						savepath: savepath
					},
					beforeSend: function() {
						self.cusLoading = true;
					},
					complete: function() {
						self.cusLoading = false;
					},
					cache: false,
					success: function(msg) {
						if(msg != null && msg != '') {
							var attrNum = msg.length;
							for(var n in msg) {
								self.data_str += msg[n].split(',')[0] + "@G@"
							}
							self.downloadAll = "下载全部" + attrNum + "个附件";
							for(var i = 0; i < attrNum; i++) {
								var filename = $.trim(msg[i].split('@espeed@')[1]),
									newAttr = filename.split("."),
									sm_hzm = $.trim(newAttr[newAttr.length - 1]).toLowerCase();
								var sm_hzcss = 'qita';
								var isview = false; //是否预览
								switch(sm_hzm) {
									case "ppt":
										sm_hzcss = 'ppt';
										isview = true;
										break;
									case "pptx":
										sm_hzcss = 'ppt';
										isview = true;
										break;
									case "pptm":
										sm_hzcss = 'ppt';
										isview = false;
										break;
									case "ppsx":
										sm_hzcss = 'ppt';
										isview = true;
										break;
									case "ppsm":
										sm_hzcss = 'ppt';
										isview = false;
										break;
									case "potx":
										sm_hzcss = 'ppt';
										isview = true;
										break;
									case "potm":
										sm_hzcss = 'ppt';
										isview = false;
										break;
									case "pps":
										sm_hzcss = 'ppt';
										isview = true;
										break;
									case "xlsm":
										sm_hzcss = 'xls';
										isview = false;
										break;
									case "xltx":
										sm_hzcss = 'xls';
										isview = false;
										break;
									case "xltm":
										sm_hzcss = 'xls';
										isview = false;
										break;
									case "xlsb":
										sm_hzcss = 'xls';
										isview = false;
										break;
									case "xlam":
										sm_hzcss = 'xls';
										isview = false;
										break;
									case "xlt":
										hzcss = 'xls';
										isview = false;
										break;
									case "csv":
										sm_hzcss = 'xls';
										isview = false;
										break;
									case "docm":
										sm_hzcss = 'word';
										isview = false;
										break;
									case "dot":
										sm_hzcss = 'word';
										isview = true;
										break;
									case "rtf":
										sm_hzcss = 'word';
										isview = false;
										break;
									case "dotx":
										sm_hzcss = 'word';
										isview = true;
										break;
									case "dotm":
										sm_hzcss = 'word';
										isview = false;
										break;
									case "wps":
										sm_hzcss = 'word';
										isview = false;
										break;
									case "wpt":
										sm_hzcss = 'word';
										isview = false;
										break;
									case "jpeg":
										sm_hzcss = 'jpg';
										isview = true;
										break;
									case "doc":
										sm_hzcss = 'word';
										isview = true;
										break;
									case "docx":
										sm_hzcss = 'word';
										isview = true;
										break;
									case "xlsx":
										sm_hzcss = 'xls';
										isview = true;
										break;
									case "pdf":
										sm_hzcss = 'pdf';
										isview = true;
										break;
									case "psd":
										sm_hzcss = 'psd';
										isview = false;
										break;
									case "xls":
										sm_hzcss = 'xls';
										isview = true;
										break;
									case "zip":
										sm_hzcss = 'zip';
										isview = false;
										break;
									case "rar":
										sm_hzcss = 'rar';
										isview = false;
										break;
									case "txt":
										sm_hzcss = 'txt';
										isview = true;
										break;
									case "jpg":
										sm_hzcss = 'jpg';
										isview = true;
										break;
									case "gif":
										sm_hzcss = 'gif';
										isview = true;
										break;
									case "png":
										sm_hzcss = 'png';
										isview = true;
										break;
									case "bmp":
										sm_hzcss = 'bmp';
										isview = true;
										break;
									case "tif":
										sm_hzcss = 'tif';
										isview = true;
										break;
									default:
										sm_hzcss = 'qita';
										isview = false;
										break;
								}
								self.attrList.push({
									"filename": filename,
									"downpath": msg[i],
									"suffix": sm_hzcss,
									"isview": isview,
								});
							}
							self.cusIsAttrShow = true;
						}
					}
				});
			},

			//解析邮件发件箱附件
			load_cusParseSAttach(mailId) {
				var self = this;
				var savepath = this.userparamlist.param111;
				if(savepath == "" || savepath == null || savepath == undefined) {
					savepath = this.companyparamlist.param113;
				}
				var action = 'xmailbase_AnalysisMailAttr.action';
				if(this.cus_poporimap == 1) {
					action = 'imap_AnalysisMailAttr.action';
				}
				$.ajax({
					type: "post",
					url: backUrl + action, //解析邮件基本信息
					dataType: "json",
					data: {
						token: token,
						mailid: mailId,
						savepath: savepath
					},
					beforeSend: function() {
						self.cusLoading = true;
					},
					complete: function() {
						self.cusLoading = false;
					},
					cache: false,
					success: function(msg) {
						if(msg != null && msg != '') {
							var attrNum = msg.length;
							for(var n in msg) {
								self.data_str += msg[n].split(',')[0] + "@G@"
							}
							self.downloadAll = "下载全部" + attrNum + "个附件";
							for(var i = 0; i < attrNum; i++) {
								var filename = $.trim(msg[i].split('@espeed@')[1]),
									newAttr = filename.split("."),
									sm_hzm = $.trim(newAttr[newAttr.length - 1]).toLowerCase();
								var sm_hzcss = 'qita';
								var isview = false; //是否预览
								switch(sm_hzm) {
									case "ppt":
										sm_hzcss = 'ppt';
										isview = true;
										break;
									case "pptx":
										sm_hzcss = 'ppt';
										isview = true;
										break;
									case "pptm":
										sm_hzcss = 'ppt';
										isview = false;
										break;
									case "ppsx":
										sm_hzcss = 'ppt';
										isview = true;
										break;
									case "ppsm":
										sm_hzcss = 'ppt';
										isview = false;
										break;
									case "potx":
										sm_hzcss = 'ppt';
										isview = true;
										break;
									case "potm":
										sm_hzcss = 'ppt';
										isview = false;
										break;
									case "pps":
										sm_hzcss = 'ppt';
										isview = true;
										break;
									case "xlsm":
										sm_hzcss = 'xls';
										isview = false;
										break;
									case "xltx":
										sm_hzcss = 'xls';
										isview = false;
										break;
									case "xltm":
										sm_hzcss = 'xls';
										isview = false;
										break;
									case "xlsb":
										sm_hzcss = 'xls';
										isview = false;
										break;
									case "xlam":
										sm_hzcss = 'xls';
										isview = false;
										break;
									case "xlt":
										hzcss = 'xls';
										isview = false;
										break;
									case "csv":
										sm_hzcss = 'xls';
										isview = false;
										break;
									case "docm":
										sm_hzcss = 'word';
										isview = false;
										break;
									case "dot":
										sm_hzcss = 'word';
										isview = true;
										break;
									case "rtf":
										sm_hzcss = 'word';
										isview = false;
										break;
									case "dotx":
										sm_hzcss = 'word';
										isview = true;
										break;
									case "dotm":
										sm_hzcss = 'word';
										isview = false;
										break;
									case "wps":
										sm_hzcss = 'word';
										isview = false;
										break;
									case "wpt":
										sm_hzcss = 'word';
										isview = false;
										break;
									case "jpeg":
										sm_hzcss = 'jpg';
										isview = true;
										break;
									case "doc":
										sm_hzcss = 'word';
										isview = true;
										break;
									case "docx":
										sm_hzcss = 'word';
										isview = true;
										break;
									case "xlsx":
										sm_hzcss = 'xls';
										isview = true;
										break;
									case "pdf":
										sm_hzcss = 'pdf';
										isview = true;
										break;
									case "psd":
										sm_hzcss = 'psd';
										isview = false;
										break;
									case "xls":
										sm_hzcss = 'xls';
										isview = true;
										break;
									case "zip":
										sm_hzcss = 'zip';
										isview = false;
										break;
									case "rar":
										sm_hzcss = 'rar';
										isview = false;
										break;
									case "txt":
										sm_hzcss = 'txt';
										isview = true;
										break;
									case "jpg":
										sm_hzcss = 'jpg';
										isview = true;
										break;
									case "gif":
										sm_hzcss = 'gif';
										isview = true;
										break;
									case "png":
										sm_hzcss = 'png';
										isview = true;
										break;
									case "bmp":
										sm_hzcss = 'bmp';
										isview = true;
										break;
									case "tif":
										sm_hzcss = 'tif';
										isview = true;
										break;
									default:
										sm_hzcss = 'qita';
										isview = false;
										break;
								}
								self.attrList.push({
									"filename": filename,
									"downpath": msg[i],
									"suffix": sm_hzcss,
									"isview": isview,
								});
							}
							self.cusIsAttrShow = true;
						}
					}
				});
			},
			//翻译
			translateClick() {
				let self = this;
				var txt = editor.html();
				if(!$.isEmptyObject(self.translateJSON)) {
					if(self.translateBtnTitle == "译文") {
						self.translateBtnTitle = "原文";
						var yiwen = self.translateJSON.dst;
						editor.html(yiwen);
					} else {
						self.translateBtnTitle = "译文";
						var yuanwen = self.translateJSON.src;
						editor.html(yuanwen);
					}
					return false;
				}
				$.ajax({
					type: "post",
					url: backUrl + "preview_baidufanyi.action", //解析邮件基本信息
					dataType: "json",
					data: {
						token: token,
						source: txt+".",
						from: "auto",
						to: "zh"
					},
					beforeSend: function() {
						self.translationLoading = true;
					},
					complete: function() {
						self.translationLoading = false;
					},
					success: function(msg) {
						if(msg.code == 200) {
							self.translateBtnTitle = "原文";
							editor.html(msg.dst)
							self.translateJSON = msg;
						} else {
							self.$message({
								showClose: true,
								message: msg.error_msg,
								type: 'warning'
							});
						}
					}
				});
			},
		},
		mounted() {
			this.translateBtnTitle = "译文";
			this.translateJSON = {}; //编译后的数据
			var lef = this;
			setTimeout(function() {
				//编辑器初始化
				kindEditorType = false;
				editor = KindEditor.create('textarea[name="mailBodyId"]', {
					filterMode: false, //是否开启过滤模式
					resizeType: 0,
					allowPreviewEmoticons: false,
					allowImageUpload: false,
					items: [],
					readonlyMode: true,
					afterBlur: function() {
						this.sync();
					}
				})
			}, 0);
			if(this.type == 0) { //收件箱
				this.showReply = true;
				this.$store.state.is_look = 1;
				this.parseNoAttrMail({
					"id": this.mailId,
					"isjiequ": 0,
					"isreceive": true,
					poporimap: this.cus_poporimap
				});
				//判断pop或者imap
				var from_name = "", //发件人
					from_mail = ""; //发件人邮箱
				var reply_name = "", //回复人名称
					reply_mail = ""; //回复人邮箱
				var send_time = ""; //发送时间
				var receive = "", //收件人
					receive_mail = ""; //收件人邮箱
				var cus_cc = "";
				var remark = ""; //备注
				var attachment_flag = 0; //附件
				if(this.cus_poporimap == 0) {
					this.cusid = this.nAttrMail.receivemail.customer_id; //客户id
					this.subject = this.nAttrMail.receivemail.subject; //主题
					from_name = this.nAttrMail.receivemail.from_name; //发件人
					from_mail = this.nAttrMail.receivemail.from_email; //发件人邮件
					reply_name = this.nAttrMail.receivemail.reply_name;
					reply_mail = this.nAttrMail.receivemail.reply_email;
					send_time = this.nAttrMail.receivemail.send_time;
					receive = this.nAttrMail.receivemail.receiver;
					receive_mail = this.nAttrMail.receivemail.receive_mail;
					cus_cc = this.nAttrMail.receivemail.cc;
					remark = this.nAttrMail.receivemail.remark;
					attachment_flag = this.nAttrMail.receivemail.attachment_flag;
				} else {
					this.cusid = this.nAttrMail.emlimapdata.customer_id; //客户id
					this.subject = this.nAttrMail.emlimapdata.subject; //主题
					var fromer = this.nAttrMail.emlimapdata.fromer;
					if(fromer.indexOf('<') != -1) {
						from_name = fromer.split('<')[0];
						from_mail = fromer.split('<')[1].split('>')[0];
					} else {
						from_name = fromer.split('@')[0];
						from_mail = fromer;
					}
					var replyer = this.nAttrMail.emlimapdata.replyer;
					if(replyer.indexOf('<') != -1) {
						reply_name = replyer.split('<')[0];
						reply_mail = replyer.split('<')[1].split('>')[0];
					} else {
						reply_name = replyer.split('@')[0];
						reply_mail = replyer;
					}
					send_time = this.nAttrMail.emlimapdata.send_time;
					var receiver = this.nAttrMail.emlimapdata.receiver;
					if(receiver != "" && receiver != null) {
						//判断是否有多个
						if(receiver.indexOf(',') != -1) {
							var str = receiver.split(',');
							for(var i = 0; i < str.length; i++) {
								receive += str[i].split('<')[0];
								receive_mail += str[i].split('<')[1].split('>')[0];
							}
						} else {
							receive = receiver.split('<')[0];
							receive_mail += receiver.split('<')[1].split('>')[0];
						}
					} else {
						receive = this.cus_mailAccount.split('@')[0];
						receive_mail = this.cus_mailAccount;
					}
					cus_cc = this.nAttrMail.emlimapdata.cc;
					remark = this.nAttrMail.emlimapdata.remark;
					attachment_flag = this.nAttrMail.emlimapdata.attachment_flag;
				}
				this.sendName = from_name; //发件人
				this.sendAddress = from_mail; //发件人邮件

				if(reply_mail != "") {
					this.replyInfo = reply_name + "<" + reply_mail + ">";;
				} else {
					this.replyInfo = this.sendName + "<" + this.sendAddress + ">";
				}
				this.sendTime = send_time.replace(".0", ""); //时间
				var RnameStr = ""; //收件名称
				if(receive != null && receive != "") {
					if(receive.indexOf(',') != -1) {
						RnameStr = receive.split(",");
					} else {
						RnameStr = receive;
					}
				}
				var RAddressStr = receive_mail.split(","); //收件人邮箱
				// 组装
				var RAddressInfo = "";
				if(RAddressStr.length >= 2) {
					for(var i = 0; i < RAddressStr.length; i++) {
						if(RnameStr[i] != null && RnameStr[i] != "") {
							RAddressInfo += RnameStr[i] + "<" + RAddressStr[i] + ">" + ";";
						} else {
							RAddressInfo += "<" + RAddressStr[i] + ">" + ";";
						}
					}
				} else {
					RAddressInfo += RnameStr + "<" + $.trim(RAddressStr) + ">" + ";";
				}
				this.toInfo = RAddressInfo;
				if(cus_cc != null && cus_cc != "") {
					this.ccInfo = cus_cc;
				}
				if(remark != null && remark != "") {
					this.remarkInfo = remark;
					this.showRemark = true;
				}
				setTimeout(function() {
					if(lef.nAttrMail.resultstr != null &&
						lef.nAttrMail.resultstr != "") {
						var re = /\.\.\/temp\//g;
						editor.html(lef.nAttrMail.resultstr.replace(re, backUrl + 'temp/'));
					} else {
						editor.html("文件已破坏,或邮件无内容!");
					}
				}, 0);
				if(attachment_flag > 0) {
					lef.cusIsAttrShow = true;
					lef.load_cusParseAttach(this.mailId);
				} else {
					lef.cusIsAttrShow = false;
				}
			} else { //发件箱
				this.$store.state.is_look = 1;
				this.showReply = false;
				this.parseNoAttrMail({
					"id": this.mailId,
					"isjiequ": 0,
					"isreceive": false,
					poporimap: this.cus_poporimap
				});

				var send_name = "", //发件人
					send_mail = ''; //收件人
				var reply_mail = ""; //回复人邮箱
				var send_time = "";
				var remark = ""; //备注
				var attachment_flag = 0; //附件

				if(this.cus_poporimap == 0) { //pop
					this.subject = this.nAttrMail.sendmailbase.subject; //主题
					send_name = this.nAttrMail.sendmailbase.sender; //发件人
					send_mail = this.nAttrMail.sendmailbase.send_email; //发件人邮件
					reply_mail = this.nAttrMail.sendmailbase.reply_email;
					send_time = this.nAttrMail.sendmailbase.create_time;

					if(this.nAttrMail.sendmailbase.detaillist != null && this.nAttrMail.sendmailbase.detaillist != "") {
						$.each(lef.nAttrMail.sendmailbase.detaillist,
							function(index, row) {
								if(row.is_cc == 1) { //抄送
									lef.ccInfo += "<" + row.email + ">" + ",";
								} else if(row.is_bcc == 1) { //密送
									//bccemail+="<"+row.email+">"+",";
								} else { //收件人
									lef.toInfo += row.receiver + "<" + row.email + ">" + ";";
								}
							});
					}
					remark = this.nAttrMail.sendmailbase.remark;
					attachment_flag = this.nAttrMail.sendmailbase.attachment_count;
				} else { //imap
					this.subject = this.nAttrMail.emlimapdata.subject; //主题
					var fromer = this.nAttrMail.emlimapdata.fromer;
					if(fromer.indexOf('<') != -1) {
						send_name = fromer.split('<')[0];
						send_mail = fromer.split('<')[1].split('>')[0];
					} else {
						send_name = fromer.split('@')[0];
						send_mail = fromer;
					}
					reply_mail = this.nAttrMail.emlimapdata.replyer;
					send_time = this.nAttrMail.emlimapdata.send_time;

					if(this.nAttrMail.emlimapdata.receiver != null && this.nAttrMail.emlimapdata.receiver != "") {
						this.ccInfo = this.nAttrMail.emlimapdata.cc;
						this.toInfo = this.nAttrMail.emlimapdata.receiver;
					}
					remark = this.nAttrMail.emlimapdata.remark;
					attachment_flag = this.nAttrMail.emlimapdata.attachment_flag;
				}
				this.sendName = send_name;
				this.sendAddress = send_mail;

				if(reply_mail != "") {
					this.replyInfo = reply_mail;
				} else {
					this.replyInfo = this.sendName + "<" + this.sendAddress + ">";
				}
				this.sendTime = send_time.replace(".0", "");

				if(remark != null && remark != "") {
					this.remarkInfo = remark;
					this.showRemark = true;
				}
				setTimeout(function() {
					if(lef.nAttrMail.resultstr != null &&
						lef.nAttrMail.resultstr != "") {
						var re = /\.\.\/temp\//g;
						editor.html(lef.nAttrMail.resultstr.replace(re, backUrl + 'temp/'));
					} else {
						editor.html("文件已破坏,或邮件无内容!");
					}
				}, 0);
				if(attachment_flag > 0) {
					lef.cusIsAttrShow = true;
					lef.load_cusParseSAttach(this.mailId);
				} else {
					lef.cusIsAttrShow = false;
				}
			}

			function clearTransform() {
				$('.el-dialog').css({
					'transform': 'none',
				})
			};
			dialogDrag();
			clearTransform(dialogCenter());
		}
	}
</script>

<style lang="less">
	#message-info>.el-dialog__wrapper>.el-dialog {
		width: 800px;
		.el-dialog__body {
			height: 500px!important;
			overflow: auto!important;
		}
	}
	
	#message-info .ke-container {
		border: none;
	}
	
	#message-info .ke-container .ke-toolbar,
	#message-info .ke-container .ke-statusbar {
		display: none !important;
	}
	
	#mailBodyId {
		width: 100%;
		height: 260px;
	}
	
	#crm_mail_attrfile {
		margin-left: 10px;
		border-top: 1px solid #cad8e1;
		border-left: 1px solid #cad8e1;
		border-right: 1px solid #cad8e1;
		margin-right: 10px;
		background: #f6f7fb;
	}
	
	.cus-note-btn {
		color: #1f2d3d;
	}
	
	.cus-note-btn:hover {
		color: #20a0ff;
		border-color: #20a0ff;
	}
	
	#message-info .cus_con_t_fj {
		border-radius: 2px;
		border-top: 1px solid #cad8e1;
		border-left: 1px solid #cad8e1;
		border-right: 1px solid #cad8e1;
		background: #f6f7fb;
		margin: 10px 0px 0px 10px;
	}
	
	.cus_fj_1 {
		height: 35px;
		padding-left: 17px;
		background: url(../../../../static/img/common/fj.png) no-repeat 0 9px;
		margin: 0 10px;
		line-height: 35px;
		z-index: 1;
		border-bottom: 1px solid #e0e0e0;
	}
	
	.cus_fj_t {
		color: #000;
		font-weight: bold;
		font-size: 14px;
		font-family: "微软雅黑";
	}
	
	.cus_fj_t_num {
		color: #999!important;
		font-size: 12px!important;
		font-weight: normal;
	}
	
	.cus_fj_upload {
		font-size: 12px;
		outline: 0;
		cursor: pointer;
		padding: 2px 4px 4px;
		text-decoration: none;
		border-radius: 3px;
		color: #0F6099;
	}
	
	.cus_fj_upload:hover {
		background-color: #20A0FF;
		color: #FFFFFF;
	}
	
	.fh_zt1 {
		font-weight: blod;
		color: blue;
	}
	
	.cus_fj_2 {
		margin-bottom: 10px;
	}
	
	.cus-attr-relative {
		position: relative;
		display: inline-block;
	}
	
	.cus_fj_kj {
		float: left;
		padding: 15px 10px;
		border: 1px solid #f6f7fb;
		margin-top: 10px;
		margin-left: 30px;
	}
	
	.cus_fj_kj:hover {
		background-color: #E8F0F7;
		border-color: #C2D4E3;
	}
	
	.cus_fj_kj .qita {
		background: url('../../../../static/img/folder.png') no-repeat;
		background-size: 100% 100%;
	}
	
	.cus_fj_kj .ppt {
		background-position: -225px -144px;
	}
	
	.cus_fj_kj .word {
		background-position: -150px -144px;
	}
	
	.cus_fj_kj .pdf {
		background-position: -300px -219px;
	}
	
	.cus_fj_kj .psd {
		background-position: -375px -219px;
	}
	
	.cus_fj_kj .xls {
		background-position: -75px -144px;
	}
	
	.cus_fj_kj .zip {
		background-position: -375px -144px;
	}
	
	.cus_fj_kj .rar {
		background-position: -375px -144px;
	}
	
	.cus_fj_kj .txt {
		background-position: -675px -219px;
	}
	
	.cus_fj_kj .jpg {
		background-position: -375px -294px;
	}
	
	.cus_fj_kj .gif {
		background-position: -450px -294px;
	}
	
	.cus_fj_kj .png {
		background-position: -525px -294px;
	}
	
	.cus_fj_kj .bmp {
		background-position: -600px -294px;
	}
	
	.cus_fj_kj .tif {
		background-position: -675px -294px;
	}
	
	.cus_fj_kj_1 {
		width: 75px;
		height: 75px;
		background: url(../../../../static/img/common/fileico_v4.png) no-repeat;
	}
	
	.cus_fj_kj_2 {
		color: #222222;
		height: 15px;
		width: 100px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		margin-top: 10px;
		font-family: "微软雅黑";
		line-height: 15px;
	}
	
	.cus-Data .uo-arrow {
		position: absolute;
		bottom: 0;
		top: 35px;
		float: left;
		left: 75px;
		width: 0;
		height: 0;
		border-left: 8px solid transparent;
		border-right: 8px solid transparent;
		border-bottom: 8px solid #4b4b4b;
		transform: rotate(180deg);
	}
	
	.cus-Data .uo-pd-havedata {
		max-width: 200px;
		height: 75px;
		position: absolute;
		font-size: 12px;
		float: left;
		left: 10px;
		top: -40px;
		bottom: 0;
		color: #fff;
		z-index: 998;
		background-color: rgba(0, 0, 0, 0.7);
		box-shadow: 0 0 5px #000;
	}
	
	.cus-Data .attr_filename {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		text-align: center;
	}
	
	.cus-Data .uo-pd-havedata ul li {
		position: relative;
		padding: 4px 5px;
		height: auto;
		min-height: 25px;
		text-align: center;
	}
	
	.cus-Data .attrupload {
		font-size: 24px;
		cursor: pointer;
	}
	
	.cus-Data .uo-pd-havedata-main {
		display: inline-block;
		color: #fff;
		cursor: pointer;
		margin-right: 10px;
		width: 40px;
		height: 40px;
	}
	
	.cus-Data .uo-pd-havedata-main:hover {
		border: 1px solid #8d8f92;
	}
	
	.lookFj {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.2);
		z-index: 99999;
	}
	
	.lf-header {
		height: 54px;
		background: #20A0FF;
	}
	
	.lfh-title {
		color: #fff;
		position: absolute;
		left: 20px;
		top: 18px;
		font-size: 16px;
		max-width: 1100px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	
	.lfh-download {
		position: absolute;
		top: 18px;
		right: 100px;
		font-size: 16px;
		color: #fff;
		cursor: pointer;
	}
	
	.lfh-close {
		position: absolute;
		top: 18px;
		font-size: 16px;
		right: 15px;
		width: 60px;
		height: 30px;
		color: #fff;
		text-align: center;
		cursor: pointer;
	}
	
	.lf-content {
		position: absolute;
		top: 56px;
		bottom: 0;
		text-align: center;
	}
	
	.lfc-main {
		background-color: #fff;
		border: none;
	}
	
	.messageInfoTab {
		padding: 0 20px;
		td {
			border-bottom: 1px solid #ddd;
			height: 35px;
			line-height: 35px;
			font-size: 12px;
			word-break: break-word;
			color: #999;
			&:nth-child(odd) {
				text-align: right;
				padding-right: 20px;
				width: 20px;
				white-space: nowrap;
				color: #20a0ff;
			}
			.remark {
				width: 300px;
				height: 70px;
				resize: none;
				margin-top: 10px;
			}
			.btn-box {
				margin-bottom: 10px;
			}
			.save-btn {
				border: 1px solid #20a0ff;
				background: #20a0ff;
				color: #fff;
				padding: 5px 10px;
				border-radius: 2px;
				cursor: pointer;
			}
			.cancel-btn {
				padding: 5px 10px;
				border-radius: 2px;
				border: 1px solid #ddd;
				&:hover {
					.save-btn
				}
			}
			.save-btn:hover {
				background: #4db3ff;
				border-color: #4db3ff;
				color: #fff;
			}
		}
		.info-title {
			font-size: 18px;
			font-weight: bold;
			color: #444;
		}
		.m_f {
			float: left;
			width: 16px;
			height: 16px;
			margin-top: 10px;
			display: block;
			background: url(../../../../static/img/common/fileico_v4.png) no-repeat;
		}
		.m_f1 {
			float: left;
			margin-right: 5px;
		}
		.m_fj .qita {
			background: url('../../../../static/img/folder.png') no-repeat;
			background-size: 100% 100%;
		}
		.m_fj .word {
			background-position: -64px 0;
		}
		.m_fj .ppt {
			background-position: -96px 0;
		}
		.m_fj .pdf {
			background-position: -512px 0;
		}
		.m_fj .psd {
			background-position: -96px 0;
		}
		.m_fj .xls {
			background-position: -32px 0;
		}
		.m_fj .zip {
			background-position: -128px 0;
		}
		.m_fj .rar {
			background-position: -128px 0;
		}
		.m_fj .txt {
			background-position: -672px 0;
		}
		.m_fj .jpg {
			background-position: 0 -32px;
		}
		.m_fj .gif {
			background-position: -32px -32px;
		}
		.m_fj .png {
			background-position: -64px -32px;
		}
		.m_fj .bmp {
			background-position: -96px -32px;
		}
		.m_fj .tif {
			background-position: -128px -32px;
		}
	}
</style>