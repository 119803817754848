<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：客户·客户详细信息·新建跟进弹窗
开始时间：2017-11-9
开发人员：陈立珍
最后修改：2018-7-23
备注说明：如需修改请联系开发人员
-->

<!--新建跟进-->
<template>
  <div id="newFollow-layer" >
    <div class="connectInfo">
      <el-dialog
        :title="cntTitle"
        class='yourName new-follow-layer dialogTwo'
        size="tiny"
        :visible.sync="test"
        :before-close="cntClose"
        :modal="false"
        :modal-append-to-body="true"
        :lock-scroll="true"
        :close-on-click-modal="false">
        <popupLoading></popupLoading>
        <div class="cnt-tab" style="height: 400px;overflow-y: auto;">
        	<!--新建跟进-->
          <newFollow ref="tab" @submitok="submitReturn" 
          	:followupType="followupType"
          	@recordok="submitReturn" @changeDisabled="changeDisabled"></newFollow>
        </div>
        <span slot="footer" class="dialog-footer" v-if="hasButton">
          <div class="btn-box">
            <el-button type="primary" @click="cntConfirm" :disabled="isDisabled">确 定</el-button>
            <el-button  @click="cntClose" :disabled="isDisabled">取 消</el-button>
          </div>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
	import popupLoading from '@/components/publicComponent/popupLoading'
import newFollow from "./newFollow.vue"
export default{
  name:"cm-more-info",
  components:{
    newFollow,
    popupLoading
  },
  data(){
	  return{
	    test:true,
	    isDisabled:false,
	  }
 	},
  props:[
    'cntTitle',
    'hasButton',
    'followupType',
    'customerId',
  ],
  methods:{
  	//关闭
    cntClose:function(){
      this.$emit('cntClose',false)
    },
    changeDisabled(){
    	this.isDisabled = true;
    },
    //确定
    cntConfirm:function(){
    	this.$refs.tab.subButton(this.customerId);
     },
    //提交
    submitReturn(){
    	if(this.followupType==2){//添加的是记录
    		this.$emit('cntConfirm',true);
    	}else{
    		this.$emit('cntConfirm',false);
    	}
    }
  },
  mounted(){
    function clearTransform(){
      $('.el-dialog').css({
        'transform': 'none',
      })
    };
    dialogDrag();
    clearTransform(dialogCenter());
  }
}
</script>
<style lang="less">
  #newFollow-layer{
    .connectInfo{
      .new-follow-layer{
        >.el-dialog{
          width:700px;
          height:auto;
          >.el-dialog__body{
            max-height:400px!important;
            overflow:auto;
          }
        }
      }
    }
  }
</style>
<style src="../../../css/crm/common_layer.css"></style>
