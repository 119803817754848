<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：客户·关联产品
开始时间：2018-10-08
开发人员：陈立珍
最后修改：2018-10-27
备注说明：如需修改请联系开发人员
-->

<template>
	<div class="reProSearch">
		<el-dialog title="选择关联产品" class='dialogThree' size="tiny" :visible.sync="proTest" :before-close="closePro" :modal="false" :modal-append-to-body="true" :lock-scroll="true" :close-on-click-modal="false">
			<popupLoading></popupLoading>
			<!-- 搜索 -->
			<div class="searchHead">
				<input v-model="proInput" class="search-input" type="text" placeholder="请输入产品名称..." />
				<span @click="searchProClick" class="search-btn">搜索</span>
				<span @click="reset_proInput" class="search-btn">重置</span>
			</div>
			<!-- 表格 -->
			<div class="search-result">
				<table width="100%" cellspacing="0">
					<thead>
						<tr>
							<th width="20%">序号</th>
							<th width="25%">产品名称</th>
							<th width="15%">选择</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item,index) in proInfos">
							<td>
								<span>{{index+1}}</span>
							</td>
							<td>
								<div class="show-font">
									<el-tooltip class="item" effect="dark" :open-delay="300" :content="item.name" placement="top">
										<span>{{item.name}}</span>
									</el-tooltip>
								</div>
								
							</td>
							<td>
								<el-checkbox-group v-model="choosePro">
								    <el-checkbox :label="item.id+'###'+item.name"></el-checkbox>
								</el-checkbox-group>
							</td>
						</tr>
						<tr v-if="proInfos.length == 0">
							<td colspan="100%" class="noData-box">
								<div class="noData-bg">
								</div>
								<span class="noData">暂无数据</span>
							</td>
						</tr>
					</tbody>
				</table>
				<!-- 分页 -->
				<div style="text-align: center;padding-top: 10px;">
					<el-pagination v-if="proInfos.length > 0" :current-page="p_currpage" :page-size="p_pagesize" 
						layout="total,sizes,prev, pager, next, jumper" :total="p_tolRecord" @current-change="p_changeCurrentPage" 
						@size-change="p_changeSizePage" :pager-count="pageCount"></el-pagination>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submitProInfo()">确 定</el-button>
				<el-button @click="closePro">取 消</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import popupLoading from '@/components/publicComponent/popupLoading'
	import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
	export default({
		props: ['productids2','productType'],
		data() {
			return {
				proTest: true, //弹窗
				p_currpage: 1, //当前页
				p_pagesize: 10, //一页显示的条数
				p_tolRecord: 0, //总条数
				choosePro: [], //选中的关联产品
				proInfos: [], //关联产品信息
				proInput: '', //关联产品搜索内容
				pageCount:5
			}
		},
		components: {
			popupLoading
		},
		computed: {
			...mapState({
				productlistall2: state => state.productlistall2,
			}),
		},
		methods: {
			...mapMutations({
				getProductlistAll2: 'getProductlistAll2',
			}),
			
			//触发父组件关闭弹窗
			closePro: function() {
				this.$emit('closePro', false);
			},
			//获取采购偏好数据集合
			getRelationProInfo(){
				this.proInfos = [];
				this.getProductlistAll2({
					currpage:this.p_currpage,
					pagesize:this.p_pagesize,
					conditionValue:this.proInput
				});
				
				if(this.productlistall2.productclassifylist != "" 
				&& this.productlistall2.productclassifylist != null && 
				this.productlistall2.productclassifylist != undefined){
					this.proInfos = this.productlistall2.productclassifylist;
				}
				this.p_tolRecord = this.productlistall2.pageBean.totalRecord;
			},
			//重置
			reset_proInput(){
				this.p_currpage = 1;
				this.p_pagesize = 10;
				this.proInput = "";
				this.getRelationProInfo();
			},
			//搜索采购偏好方法
			searchProClick() {
				this.p_currpage = 1;
				this.p_pagesize = 10;
				this.proInfos = [];
				this.p_tolRecord = 1;
				//获取采购偏好
				this.getRelationProInfo();
			},
			//分页查询
			p_changeCurrentPage(page) {
				this.p_currpage = page;
				//获取采购偏好
				this.getRelationProInfo();
			},
			//条数查询
			p_changeSizePage(size) {
				this.p_pagesize = size;
				//获取采购偏好
				this.getRelationProInfo();
			},
			//确认搜索采购偏好
			submitProInfo() {
				if(this.choosePro == ""){
					this.$message({
						showClose: true,
						message: '请选择关联产品!',
						type: 'warning',
						duration: 2000
					});
					return;
				}
				var str = [];
				$.each(this.choosePro, function(index, row) {
					var pid = row.split("###")[0];
					var pname = row.split("###")[1];
					str.push({
						id:pid,
						name:pname,
					})
				});
				this.$emit('cntConfirm', str);
				this.$store.state.popupLoading = false;
				this.$emit('closePro', false);
			},
		},
		mounted: function() {
			// this.productType 1：新建跟进   2：编辑跟进计划 未执行    3：编辑跟进计划 超时未处理计划    4：编辑跟进超记录   5：新建商机  5：新建订单
			this.getRelationProInfo();
			var lef = this;
			if(this.productids2 != '' && this.productids2 != null && this.productids2 != undefined) {
				$.each(lef.productids2.split(','), function(i, row) {
					lef.choosePro.push(row);
				});
			}
			function clearTransform() {
				$('.el-dialog').css({
					'transform': 'none',
				})
			};
			dialogDrag();
			clearTransform(dialogCenter());
		}
	})
</script>

<style>
	.reProSearch .el-dialog {
		width: 680px;
		height: auto;
	}
	
	.reProSearch .el-dialog__body {
		text-align: left;
		clear: both;
		color: #48576a;
		overflow: auto !important;
	}
	
	.reProSearch .searchHead .search-input {
		width: 250px;
		height: 30px;
		vertical-align: middle;
		border: 1px solid #ddd;
	}
	
	.reProSearch .searchHead .search-btn {
		cursor: pointer;
		display: inline-block;
		height: 30px;
		line-height: 30px;
		color: #fff;
		background-color: #20a0ff;
		vertical-align: middle;
		width: 50px;
		text-align: center;
	}
	
	.reProSearch .searchHead .search-btn:hover {
		background: #4db3ff;
		border-color: #4db3ff;
		color: #fff;
	}
	
	.reProSearch .search-result {
		margin-top: 10px;
		height: 360px;
		overflow: auto;
	}
	
	.reProSearch .search-result th,
	.reProSearch .search-result td {
		text-align: center;
		font-weight: normal;
	}
	
	.reProSearch .search-result th {
		background-color: #ededed;
		border-bottom: 1px solid #ededed !important;
		height: 30px;
	}
	
	.reProSearch .search-result td {
		height: 45px;
		border-bottom: 1px solid #ededed !important;
	}
	
	.reProSearch .show-font{
		max-width: 390px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	.reProSearch .search-result .el-checkbox__label{
		display: none;
	}
</style>