<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：客户·客户详细信息·编辑订单弹窗
开始时间：2017-11-9
开发人员：陈立珍
最后修改：2018-7-23
备注说明：如需修改请联系开发人员
-->

<!--编辑订单-->
<template>
  <div id="crm-connectInfo" >
    <div class="connectInfo">
      <el-dialog
        :title="cntTitle"
        class='yourName records-info dialogTwo'
        size="tiny"
        :visible.sync="test"
        :before-close="cntClose"
        :modal="false"
        :modal-append-to-body="true"
        :lock-scroll="true"
        :close-on-click-modal="false">
        <popupLoading></popupLoading>
        <div class="cnt-tab">
        	<!--引入编辑订单组件-->
          <newIndent
          	@submitok="submitReturn"
          	ref="tab"
          	:saleId="saleId"
            :is-record-layer="true"
            :is-records-info="true" :customerids='customerId'></newIndent>
        </div>
        <span slot="footer" class="dialog-footer">
          <div class="btn-box">
            <el-button type="primary"  @click="cntConfirm">确 定</el-button>
            <el-button  @click="cntClose">取 消</el-button>
          </div>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
	import popupLoading from '@/components/publicComponent/popupLoading'
  import newIndent from "./newOrders.vue"
  export default{
    name:"cm-more-info",
    components:{
      newIndent,popupLoading
    },
    data(){
      return{
        test:true,
      }
    },
    props:[
      'cntTitle',
      'saleId',
      'customerId',
    ],
    methods:{
    	//触发父组件关闭弹窗
      cntClose:function(){
        this.$emit('cntClose',false)
      },
      //提交
    	submitReturn(){
    		this.$emit('cntConfirm',false);
    	},
      cntConfirm:function(){
      	this.$refs.tab.subButton(this.customerId);
      }
    },
    mounted(){
      function clearTransform(){
        $('.el-dialog').css({
          'transform': 'none',
        })
      };
      dialogDrag();
      clearTransform(dialogCenter());
    }
  }
</script>
<style lang="less">
  #crm-connectInfo{
    .connectInfo{
      .records-info{
        >.el-dialog{
          width:700px;
          height:auto;
        }
        
        .el-dialog__body{
        	overflow: auto !important; 
        }
      }
    }
  }
</style>
<style src="../../../css/crm/common_layer.css"></style>

