<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：客户·客户详细信息·客户详细信息新建联系人弹窗
开始时间：2017-11-9
开发人员：陈立珍
最后修改：2018-7-23
备注说明：如需修改请联系开发人员
-->

<!--客户详细信息新建联系人-->
<template>
	<div id="crm-connectInfo">
		<div class="connectInfo">
			<el-dialog :title="cntTitle" class='yourName records-info dialogTwo' size="tiny" :visible.sync="test" :before-close="cntClose" :modal="false" :modal-append-to-body="true" :lock-scroll="true" :close-on-click-modal="false">
				<popupLoading></popupLoading>
				<div class="cnt-tab">
					<table width="100%" cellspacing="0" cellpadding="0" class="newTab1">
						<tr>
							<td class="newF-usz">跟进方式</td>
							<td colspan="3">
								<select v-model="wayid">
									<option value="0">请选择</option>
									<option v-for="way in followWayList" :value="way.id" :title="way.name">{{way.name1}}</option>
								</select>
							</td>
						</tr>
						<tr>
							<td class="cu_tbg">跟进内容</td>
							<td colspan="3">
								<div contenteditable="true" id='followContentId' class="f-content" v-html="followContent"></div>
							</td>
						</tr>
						<tr>
							<td class="newF-usz">关联产品</td>
							<td colspan="3">
								<!-- <span @click="selectProduct" class="pSelect" title="选择关联的产品">{{productNum==0?'请选择':'选择了'+productNum+'个产品'}}</span> -->
								 	 <com-product :choiceProductArr="productids" @cofirmProduct="cofirmProduct" v-if="loadingProduct"></com-product>
                <div v-if="!loadingProduct">
										<i class="el-icon-loading"></i>
									</div>
							</td>
						</tr>
					</table>
				</div>
				<span slot="footer" class="dialog-footer">
          <div class="btn-box">
            <el-button type="primary"  @click="submitUpdate">确 定</el-button>
            <el-button  @click="cntClose">取 消</el-button>
          </div>
        </span>
			</el-dialog>
			<!--引入选择关联产品组件-->
			<small-layer class="smallLayer" v-if="showC" @cntClose="showC = false" @cntConfirm="cntConfirm" :showType="4" :productIds="productids" :productids2="productids2" :connect-tab="false" cnt-title="选择关联产品" :has-button="true">
			</small-layer>

			<!-- 关联产品 -->
			<!-- <relatedProduct v-if="showC" :productids2="productids2" :productType="4" @closePro="showC = false" @cntConfirm="cntConfirm"></relatedProduct> -->
		</div>
	</div>
</template>

<script>
	import store from '../../../vuex/store.js';
	import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
	import '../../../vuex/mutations.js';
	import popupLoading from '@/components/publicComponent/popupLoading'
	import smallLayer from "./smallLayer.vue";
	import relatedProduct from './layer_related_product.vue'
    import comProduct from "@/components/common_file/com_product/comProduct";
	export default {
		name: "cm-more-info",
		components: {
			smallLayer,
			popupLoading,
			relatedProduct,
			comProduct
		},
		data() {
			return {
				productids: '', //选择的关联产品id,多个以逗号分割
				productids2: '', //拼接已选择的产品
				productNum: 0, //选择的关联产品数量
				wayid: 0, //跟进方式id
				processid: 0, //销售进程id
				customer_id: 0, //客户id
				create_user_id: 0, //用户id
				type: 1, //跟进计划
				showC: false,
				test: true,
				productNum: 0,
				cntTitle: '',
				followWayList: [], //跟进方式集合
				followContent: '', //跟进内容
				loadingProduct:false,//是否获取完产品id
			}
		},
		props: [
			'followupId', //跟进id
			'planOrRecord'
		],
		computed: {
			...mapState({
				userinfo: state => state.userinfo,
				followupwayall: state => state.followupwayall,
				relationProduct: state => state.relationProduct
			}),
		},
		methods: {
			...mapMutations({
				getFollowupWayAll: 'getFollowupWayAll',
				getRelationProduct: 'getRelationProduct',
			}),
			//触发父组件关闭弹窗
			cntClose: function() {
				this.loadingProduct=false
				this.$emit('cntClose', false)
			},
			//打开选择产品的弹窗
			selectProduct() {
				if(this.productNum != 0) {
					var param = [this.planOrRecord, this.followupId];
					this.getRelationProduct(param);

					if(this.relationProduct != '' && this.relationProduct.length > 0) {
						var production_value = "";
						$.each(this.relationProduct, function(i, row) {
							production_value += row.product_id + "###" + row.product_name + ",";
						})
						production_value = production_value.substring(0, production_value.length - 1);
						this.productids2 = production_value;
					}
				}
				this.showC = true;
			},
			//提交
			cntConfirm(proids) {
				var pro_ids = "";
				$.each(proids, function(index, row) {
					pro_ids += row.id + ",";
				})
				pro_ids = pro_ids.substring(0, pro_ids.length - 1);
				this.productids = pro_ids;
				if(proids != '') {
					this.productNum = this.productids.split(',').length;
				} else {
					this.productNum = 0;
				}
				//弹窗选中
				var production_value = "";
				$.each(proids, function(i, row) {
					var pid = row.id;
					var pname = row.name;
					production_value += pid + "###" + pname + ",";
				})
				production_value = production_value.substring(0, production_value.length - 1);
				this.productids2 = production_value;
			},
			//选中的产品id（产品组件）
			cofirmProduct(ids){
			    this.productids = ids
			},
			//加载跟进信息
			loadFollowupInfo() {
				var lef = this;
				$.ajax({
					type: "POST",
					url: backUrl + "cusfu3_getFollowupById.action",
					async: false,
					cache: false,
					dataType: "json",
					data: {
						token: token,
						followup_id: lef.followupId
					},
					success: function(msg) {
						if(msg.followup_classify == 1) {
							lef.type = 1;
							lef.cntTitle = '编辑已执行跟进计划';
						} else {
							lef.type = 2;
							lef.cntTitle = '编辑跟进记录';
						}
						// if(msg.focus_product != null && msg.focus_product != '') {
						// 	lef.productids = msg.focus_product;
						// 	lef.productNum = msg.focus_product.split(',').length;
						// } else {
						// 	lef.productids2 = ''
						// 	lef.productids = '';
						// 	lef.productNum = 0;
						// }
						 lef.ajaxProductId(lef.followupId,msg.followup_classify)
						lef.wayid = msg.followup_way;
						lef.followContent = msg.followup_content;
						lef.processid = msg.purpose_process;
						lef.customer_id = msg.customer_id;
						lef.create_user_id = msg.create_user_id;
					}
				});
			},
			 //异步获取跟进计划的产品id
		ajaxProductId(id,type){
      let self=this
      let num=1
					//判断当前是计划还是记录
			if(type!=0 && type!=-1 && type!=1){
					num=2
				}
			$.ajax({
				type: "post",
				url: backUrl + "cusXPCS_findProductRelationsByValue.action",
				dataType: "json",
				cache: false,
				data: {
					token: token,
					relation_type: num,
					record_id: id
				},
				success: function (msg) {
					if(msg !== null && msg !== undefined && msg != ''){
						$.each(msg,(i,elem)=>{
							self.productids+=elem.product_id+","
						})
						self.productids=self.productids.substring(0,self.productids.length-1)
					}else{
						self.productids = '';
          }
					self.loadingProduct=true
				}
			});
		},
			//提交编辑
			submitUpdate() {
				var content = $("#followContentId").html();
				if(content.length == '') {
					this.$message({
						showClose: true,
						message: '跟进内容不能为空！',
						type: 'warning',
						duration: 2000
					});
					return;
				}
				if(content.length > 2000) {
					this.$message({
						showClose: true,
						message: '跟进内容不能超过2000个字符！',
						type: 'warning',
						duration: 2000
					});
					return;
				}
				var lef = this;
				var postUrl = backUrl + "cusfu3_updateFollowup3.action";
				if(lef.type > 1) {
					postUrl = backUrl + "cusfu3_updateFollowup4.action";
				}
				$.ajax({
					type: "POST",
					url: postUrl,
					//				async : false,
					cache: false,
					dataType: "json",
					data: {
						token: token,
						followup_id: lef.followupId,
						followup_way: lef.wayid,
						purpose_process: lef.processid,
						focus_product_id: lef.commonJs.returnString(lef.productids),
						followup_content: content,
						customer_id: lef.customer_id
					},
					beforeSend: function() {
						lef.$store.state.popupLoading = true;
					},
					complete: function() {
						lef.$store.state.popupLoading = false;
					},
					success: function(msg) {
						if(msg == 1) {
							lef.$message({
								showClose: true,
								message: '编辑成功！',
								type: 'success',
								duration: 2000
							});
							if(lef.type == 1) {
								lef.$emit('cntConfirm', false);
							} else {
								lef.$emit('cntConfirm', true);
							}
						} else {
							lef.$message({
								showClose: true,
								message: '编辑失败！',
								type: 'warning',
								duration: 2000
							});
						}
					},
					error: function(e) {
						lef.$message({
							showClose: true,
							message: '编辑失败！',
							type: 'warning',
							duration: 2000
						});
					}
				});
			}
		},
		mounted() {
			this.loadFollowupInfo(); //加载跟进信息
			var self = this;
			$.each(this.followupwayall, function(i, row) {
				var name1 = row.name;
				if(name1.length > 9) {
					name1 = name1.substring(0, 9) + "...";
				}
				self.followWayList.push({
					name1: name1,
					name: row.name,
					id: row.id,
				})
			});

			function clearTransform() {
				$('.el-dialog').css({
					'transform': 'none',
				})
			};
			dialogDrag();
			clearTransform(dialogCenter());
		}
	}
</script>
<style lang="less">
	#crm-connectInfo {
		.connectInfo {
			.records-info {
				>.el-dialog {
					width: 600px;
					height: auto;
				}
			}
		}
	}
	
	.newTab1 {
		td {
			font-size: 12px;
			text-align: left;
			input {
				vertical-align: middle;
				margin: 0;
				width: 140px;
			}
			select {
				width: 140px;
				height: 30px;
			}
		}
		.newF-usz {
			text-align: right;
			width: 80px;
			height: 28px;
			padding-right: 10px;
		}
		.rsp {
			margin-left: 5px;
		}
		.remind {
			margin: 0 10px;
		}
		.cu_tbg {
			height: 30px;
			padding-right: 10px;
			text-align: right;
		}
		.pSelect {
			cursor: pointer;
			color: #20a0ff;
		}
		.el-date-editor.el-input {
			width: 140px;
		}
		.el-input__icon+.el-input__inner {
			height: 30px;
			line-height: 30px;
			border: 1px solid #bfcbd9;
			border-radius: 2px;
		}
		.editor-title-box {
			.editor-title {
				width: 350px;
				height: 30px;
			}
			.editor-title-option {
				height: 30px;
			}
		}
		.ue-editor {
			margin-top: 10px;
		}
	}
</style>
<style src="../../../css/crm/common_layer.css"></style>