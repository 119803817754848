<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：客户·客户详细信息·新建商机弹窗
开始时间：2017-11-9
开发人员：陈立珍
最后修改：2018-7-23
备注说明：如需修改请联系开发人员
-->

<!--新建商机-->
<template>
  <div id="crm-connectInfo" >
    <div class="connectInfo">
      <el-dialog
        :title="cntTitle"
        class='yourName new-bussiness dialogTwo'
        size="tiny"
        :visible.sync="test"
        :before-close="cntClose"
        :modal="false"
        :modal-append-to-body="true"
        :lock-scroll="true"
        :close-on-click-modal="false">
        <popupLoading></popupLoading>
        <div class="cnt-tab">
        	<!--引入新建商机组件-->
          <newBusiness ref="tab" :chanceId="0" @submitok="submitReturn" :is-layer="false"></newBusiness>
        </div>
        <span slot="footer" class="dialog-footer">
          <div class="btn-box">
            <el-button type="primary"  @click="cntConfirm">确 定</el-button>
            <el-button  @click="cntClose">取 消</el-button>
          </div>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
	import popupLoading from '@/components/publicComponent/popupLoading'
  import newBusiness from "./newBusiness.vue"
  export default{
    name:"cm-more-info",
    components:{
      newBusiness,popupLoading
    },
    data(){
      return{
        test:true
      }
    },
    props:[
      'cntTitle',
      'customerId',
    ],
    methods:{
    	 //触发父组件关闭弹窗
      cntClose:function(){
        this.$emit('cntClose',false)
      },
      //提交
      cntConfirm:function(){
        this.$refs.tab.subButton(this.customerId);
      },
      //提交
    	submitReturn(){
    		this.$emit('cntConfirm',false);
    	}
    },
    mounted(){
      function clearTransform(){
        $('.el-dialog').css({
          'transform': 'none',
        })
      };
      dialogDrag();
      clearTransform(dialogCenter());
    }
  }
</script>
<style lang="less">
  #crm-connectInfo{
    .connectInfo{
      .new-bussiness{
        >.el-dialog{
          width:750px;
          height:auto;
        }
      }
    }
  }
</style>
<style src="../../../css/crm/common_layer.css"></style>
