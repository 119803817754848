import { render, staticRenderFns } from "./crmMoreInfo_Editor.vue?vue&type=template&id=35336193&scoped=true&"
import script from "@/js/crm/cusDetailInfo.js?vue&type=script&lang=js&"
export * from "@/js/crm/cusDetailInfo.js?vue&type=script&lang=js&"
import style0 from "@/css/common.css?vue&type=style&index=0&lang=css&"
import style1 from "../../../css/crm/crm_moreInfo_editor.css?vue&type=style&index=1&lang=css&"
import style2 from "../../../css/crm/crmMoreInfo.css?vue&type=style&index=2&lang=css&"
import style3 from "@/css/disk/icon2.css?vue&type=style&index=3&lang=css&"
import style4 from "./crmMoreInfo_Editor.vue?vue&type=style&index=4&id=35336193&scoped=true&lang=css&"
import style5 from "./crmMoreInfo_Editor.vue?vue&type=style&index=5&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35336193",
  null
  
)

export default component.exports