/**
 * 程序版本：V2.0
 * 版权所有：深圳市科飞时速网络科技有限公司
 * 技术支持：Tech@21gmail.com
 * 单元名称：客户·客户详细信息弹窗js
 * 开始时间：2017-11-9
 * 开发人员：陈立珍
 * 最后修改：2019-01-11
 * 备注说明：如需修改请联系开发人员
 */

import newLinkman from "../../components/crm/CommonItem/layer_newLinkman.vue" //新建联系人
import connectInfo from "../../components/crm/CommonItem/smallLayer.vue" //公用小弹窗
import companyInfo from "../../components/crm/CommonItem/CompanyInfo_editor.vue" //企业信息可编辑弹窗
import newFollowLayer from "../../components/crm/CommonItem/layer_newFollow.vue" //新建跟进弹窗
import newFollowLayerRead from "../../components/crm/CommonItem/layer_newFollow_onlyRead.vue" //同事共享给我的新建跟进弹框
import newBusinessLayer from "../../components/crm/CommonItem/layer_newBusiness.vue" //新建商机弹窗
import businessInfo from '../../components/crm/CommonItem/layer_business_info.vue' //编辑商机弹窗
import newIndentLayer from "../../components/crm/CommonItem/layer_newIndent.vue" //新建订单弹窗
import writeMessage from "../../components/crm/CommonItem/writeMessage.vue" //编辑写信弹窗
import messageInfo from "../../components/crm/CommonItem/layer_message_info.vue" //信件信息弹窗
import picUpload from "../../components/crm/CommonItem/picUpload.vue" //头像上传弹窗
import followInfo from '../../components/crm/CommonItem/layer_content_info.vue' //查看跟进弹窗
import proInfo from '../../components/crm/CommonItem/layer_business_product.vue' //关联产品弹窗
import recordsInfo from '../../components/crm/CommonItem/layer_records_info.vue' //编辑订单弹框
import followEdit from '../../components/crm/CommonItem/layer_follow_info.vue' //编辑未执行跟进计划信息弹窗
import followEditTwo from '../../components/crm/CommonItem/layer_follow_info2.vue' //编辑超时未处理跟进计划信息弹窗
import followRecords from '../../components/crm/CommonItem/layer_follow_records.vue' //同事共享给我的 跟进模块 编辑跟进计划弹窗
import crmCompeleteRecordInfo from '../../components/crm/CommonItem/layer_business_details.vue' //查看商机弹框
import crmSaleRecordInfo from '../../components/crm/CommonItem/layer_compelete_record_info.vue' //查看订单弹框
import carryOutLayer from '../../components/crm/CommonItem/layer_follow_carryout.vue' //跟进详细信息立即执行
import fileInfo from '../../components/crm/CommonItem/layer_relative_file.vue' //关联文件弹窗
import { Message } from 'element-ui';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import lookProduct from "@/components/common_file/com_product/lookProduct";
export default {
	components: {
		carryOutLayer,
		followRecords,
		followEdit,
		followEditTwo,
		recordsInfo,
		proInfo,
		followInfo,
		picUpload,
		connectInfo,
		companyInfo,
		newLinkman,
		writeMessage,
		messageInfo,
		businessInfo,
		newFollowLayer,
		newFollowLayerRead,
		newBusinessLayer,
		newIndentLayer,
		crmCompeleteRecordInfo,
		crmSaleRecordInfo,
		fileInfo,
		lookProduct
	},
	props: [
		'isOnlyRead', //true别人共享给我,false我自己客户的
		'customer_id', //客户id
		'hasEditbtn',
		'userId' //用户id
	],
	data() {
		return {
			temp_api : 'http://localhost:8080/esmailweb2/',
			autographLoading: false,
			classify_id: 0, //客户分类id
			customer_img: backUrl + 'img/male_user.png', //企业头像
			email: '',
			cusorlink: 0,
			link_name: '', //企业联系人姓名
			ratingLevel: 0, //客户星级
			classify_name: '未知', //客户分类名称
			source_name: '未知', //客户来源名称
			relation_name: '未知', //客户关系名称
			status_name: '未知', //客户状态名称
			hangye_name: '未知', //所属行业名称
			product_name: '未知', //意向产品名称
			productIdLoading:false,//当前产品信息是否加载完成
			productId:'',//意向产品id
			dynamic: '', //输入的动态信息
			dynamicType: 0, //动态类型,0动态,1跟进记录,2生命线
			dynamicList: [], //动态集合
			recordList: [], //跟进记录集合
			lifeList: [], //生命线集合
			activeName: 'first', //顶部标签切换
			showM: false, //标签管理显示隐藏

			linkmanList: [], //联系人集合
			linkman_currpage: 1, //联系人当前页
			linkman_tolpage: 1, //联系人总页数
			linkman_tolRecord: 1, //总条数
			connectTab: [], //联系人信息实体
			phoneArr: [], //联系电话集合
			acus_phoneInfo: false, //多联系电话标识
			showL: false, //增加|编辑联系人弹出框标识
			linkman_id: 0, //当前编辑的联系人id

			//收件箱集合
			addressList: [], //邮件收|发件人信息集合
			acusR_moreaddress: false, //邮件收|发件人显示标识
			rmailList: [],
			rmail_currpage: 1, //收件箱当前页
			rmail_tolpage: 1, //收件箱总页数
			rmail_tolRecord: 1, //收件箱总条数
			//发件箱集合
			smailList: [],
			smail_currpage: 1, //发件箱当前页
			smail_tolpage: 1, //发件箱总页数
			smail_tolRecord: 1, //发件箱总条数
			mailId: 0, //邮件id
			mailType: 0, //0收件箱,1发件箱
			//收发件箱共用的联系人信息
			allLinkmanList: [],//所有联系人集合
			rCheckLinkmanId: 0,//收件箱选择的联系人id
			sCheckLinkmanId: 0,//发件箱选择的联系人id

			//跟进方式集合
			followupType: -1, //跟进类型
			followupWayList: [],
			wayId: 0, //选择的跟进方式
			fInfo: {}, //跟进信息实体
			//跟进计划集合
			planList: [],
			follow_plan_currpage: 1, //跟进计划当前页
			follow_plan_tolpage: 1, //跟进计划总页数
			follow_plan_tolRecord: 1, //跟进计划总条数
			planTypes: [], //计划类型集合
			//立即执行
			layer_Info: {
				follow_date: '',
				content: ''
			},
			planType: -2, //选择的计划类型
			//跟进记录集合
			recordList: [],
			follow_record_currpage: 1, //跟进记录当前页
			follow_record_tolpage: 1, //跟进记录总页数
			follow_record_tolRecord: 1, //跟进记录总条数
			recordTypes: [], //记录类型集合
			recordType: -2, //选择的记录类型 
			showFlInfo: false, //是否显示跟进弹出框
			fInfo: {}, //跟进信息实体
			followupId: 0, //跟进id
			planOrRecord: 1,//判断计划还是记录

			business_data: {}, //商机信息实体
			chanceList: [], //商机集合
			chance_currpage: 1, //商机当前页
			chance_tolpage: 1, //商机总页数
			chance_tolRecord: 1, //商机总条数
			showCRI: false, //是否显示商机弹出框
			chanceId: 0, //商机id

			records_data: {}, //订单信息实体
			saleorderList: [], //订单集合
			saleorder_currpage: 1, //订单当前页
			saleorder_tolpage: 1, //订单总页数
			saleorder_tolRecord: 1, //订单总条数
			saleId: 0, //订单id

			showProduct: false, //关联产品弹出框标识
			productList: [], //关联产品集合

			shareList: [], //共享集合
			share_currpage: 1, //共享当前页
			share_tolpage: 1, //共享总页数
			clientList: [], //共享的用户列表

			currentPage: 1,
			radio3: 1,
			radio4: 1,
			myEmail: '',
			showFollowInfo: false,
			showFollowRecords: false,
			followRecordsList: false,
			showRecordInfo: false,
			showProInfo: false,
			showUpload: false,
			showMessage: false,
			showMessageInfo: false,
			showMessageInfoPost: false,
			showCarryInfo: false, //跟进详细信息立即执行
			showP: false,
			myIndex: 0,
			myDate: Date.now(),
			myipt: true,
			test: true,
			showC: false,
			showNFL: false,
			showNFL_read: false,
			showNBL: false,
			showEBL: false,
			showNIL: false,
			showEIL: false,
			showFIL: false,
			showFIL2: false,
			showFRec: false,
			showSRI: false,
			emailShow: true,
			planShow: true,

			getEmailTab: [],
			followRecordsList: [],
			shareTab: [],
			emailInfo: {
				title: "测试邮件",
				addresser: "科飞时速客服 <400@esmail.com.cn>",
				replier: "400@esmail.com.cn",
				receiver: "<kfspeed@163.com>",
				date: "2017-05-12 14:58:29"
			},
			//文件管理
			fileuploadList: [], //文件管理数据集合
			file_currpage: 1, //订单当前页
			file_tolpage: 1, //订单总页数
			file_tolRecord: 1, //订单总条数
			file_pageSize: 1, //每页数量
			lookFj: false, //文件预览
			looFjName: '', //文件预览-文件名
			fileToken: '', //文件预览-token
			lookFjUrl: '', //文件预览-url
			fileType: '', //限制上传类型
			fileNameChanging: true, //编辑
			fileName: '', //修改文件名
			fileChangeType: '',
			clearInputFile: true,
			showFileInfo: false, //关联文件弹窗

			cusProShow: false, //订单关联产品
			orderInfo: [], //订单关联产品信息
			cusProDShow: false, //订单详情
			orderInfo1: [], //订单客户详细信息
			orderInfo2: [], //订单自定义字段
			chooseProid: "", //选中的意向产品id

			upfile_size: "", //上传文件大小
			upfolder_sizes: "", //上传文件夹大小
			upfolder_size_one: "", //上传文件夹中单个文件大小
			uploadNum: 0, //上传文件的个数
			orderFirstLists: [], //订单总计
			residueSpace: 0, //云盘剩余空间
			fileDirectory: "", //文件/文件夹名称
			fileid: 0, //文件id
			cusUploadData: [], //上传文件数据
			cusUploadRecord: [], //总条数

			//背景
			backgroundInfos: {}, //背景信息
			//采购数据
			joinAliTime: '', //加入阿里时间
			xunpanPro: '', //询盘产品
			purchaseInfos: {}, //采购数据信息集合
			imgList: [], //询盘产品集合
			purchaseNeedList: [], //采购需求集合

			//邮箱集合
			mailAccountList: [],
			cus_selectMailAccount: 0, //选中的邮箱账号id
			cus_mailAccount: "", //邮箱账号
			cus_mailAccountId: 0, //邮箱账号id
			cus_poporimap: 0, //判断邮箱属于pop还是imap
			followman: "",//跟进人
			isGiveMe: true, //判断是不是同事共享给我的
			country_flag : '', // 国旗
			
			dynamicSaleProcessId: 0,
			dynamicFollowWayId: 0,
			dynamicSaleProcess: [],//动态的销售进程
			dynamicFollowWay: [],//动态的跟进方式
			
			editCusType1: false,//编辑背景
			editCusType2: false,//编辑采购数据
			uploadCaiGouTag: false,//上传采购图片
			
			isAlibabaOpen: false,//判断阿里巴巴插件是否开通
		}
	},
	methods: {
		...mapMutations({
			getCustomerInformation: 'getCustomerInformation',
			getUserlistAll: 'getUserlistAll',
			getCustomerClassifyAll: 'getCustomerClassifyAll',
			getCustomerFromAll: 'getCustomerFromAll',
			getCustomerRelationAll: 'getCustomerRelationAll',
			getCustomerStatusAll: 'getCustomerStatusAll',
			getMajorProductAll: 'getMajorProductAll',
			getFollowupWayAll: 'getFollowupWayAll',
			getSaleProcessAll: 'getSaleProcessAll',
			getTradeCurrencyAll: 'getTradeCurrencyAll',
			getRelationProduct: 'getRelationProduct',
			downloadCommonMethod: 'downloadCommonMethod',
			find_limitUploadFormat: 'find_limitUploadFormat',
			getCalculateAllInfo: 'getCalculateAllInfo',
			getOrderStatusAllInfo: 'getOrderStatusAllInfo',
			findCusFolderid: 'findCusFolderid',
			load_memorySpace: 'load_memorySpace',
			getMailAccountById: 'getMailAccountById',
			fun_loadLicencefun: 'fun_loadLicencefun',
			updateFollowcount:'updateFollowcount',
		}),
		//删除文件
		deleteFile(id, index) {
			let self = this;
			$.ajax({
				type: "POST",
				url: diskUrl + "/file/change",
				async: false,
				cache: false,
				data: {
					LoginUserToken: token,
					companyId: self.userinfo.company_id,
					rootId: -1,
					type: 0,
					ids: id,
				},
				success: function (msg) {
					if (msg.code == 200) {
						self.getFileList(1)
						self.$message({
							showClose: true,
							message: '文件删除成功！',
							type: 'success'
						});
					}
				}
			});
		},
		//修改文件名
		fileNameChange(name, index) {
			let self = this;
			this.fileName = name.split('.')[0];
			this.fileChangeType = name.split('.')[1];
			self.fileuploadList[index].change = true
			self.fileNameChanging = false
		},
		//保存文件名
		fileNameInput(type, index, id, folderId, fileType) {
			let self = this;
			let name = "";
			if (type) {
				if (fileType == 0) { //文件夹
					name = this.fileName;
				} else { //文件
					name = this.fileName + '.' + this.fileChangeType
				}
				$.ajax({
					type: "POST",
					url: diskUrl + "/file/rename",
					async: false,
					cache: false,
					data: {
						LoginUserToken: token,
						rootId: -1,
						fileId: id,
						parentId: folderId,
						name: name
					},
					success: function (msg) {
						if (msg.code == 200) {
							self.$message({
								showClose: true,
								message: '文件名修改成功！',
								type: 'success'
							});
							self.fileuploadList[index].name = name;
						}
					}
				});
			}
			self.fileuploadList[index].change = false
			self.fileNameChanging = true
		},
		//获取文件列表
		getFileList(fileCurrentPage) {
			let self = this; //上传文件类型
			if (this.uploadtype == "") {
				this.find_limitUploadFormat()
			}
			var acceptType = this.uploadtype.docs + "," + this.uploadtype.images;
			var str1 = acceptType.split(',');
			for (var i in str1) {
				this.fileType += "." + str1[i] + ","
			}
			this.fileType = this.fileType.substring(0, this.fileType.length - 1);
			this.upfile_size = this.uploadtype.fileUploadMaxSize;
			this.upfolder_sizes = this.uploadtype.filesFolderUploadMaxSize;
			this.upfolder_size_one = this.uploadtype.fileFolderUploadMaxSize;
			$.ajax({
				type: "get",
				url: diskUrl + "/file/list",
				async: true,
				cache: false,
				data: {
					LoginUserToken: token,
					companyId: self.userinfo.company_id,
					folderId: self.fileid,
					currentPage: fileCurrentPage,
					pageSize: 5,
					customerId: self.customer_id,
				},
				beforeSend: function () {
					self.autographLoading = true;
				},
				complete: function () {
					self.autographLoading = false;
				},
				success: function (msg) {
					if (msg.code == 200) {
						self.fileuploadList = []
						self.file_currpage = msg.data.page.currentPage;
						self.file_tolpage = msg.data.page.totalPage;
						self.file_tolRecord = msg.data.page.totalRecord;
						self.file_pageSize = msg.data.page.pageSize;
						$.each(msg.data.list, (i, v) => {
							//文件大小
							let filesizeM = v.size / 1024 / 1024,
								failsize, filesizeK = v.size / 1024;
							if (filesizeM > 1) {
								failsize = filesizeM.toFixed(2) + 'M';
							} else {
								failsize = filesizeK.toFixed(2) + 'K';
							}
							var file_mate = "";
							if (v.type != 0) { //0:表示文件夹，1：表示文件
								var index1 = v.name.lastIndexOf(".");
								var index2 = v.name.length;
								var suffix = v.name.substring(index1 + 1, index2); //后缀名
								var fileType1 = suffix.toLowerCase();
								switch (fileType1) {
									case "txt":
										file_mate = "fl-txt";
										break;
									case "doc":
										file_mate = "fl-doc";
										break;
									case "docx":
										file_mate = "fl-doc";
										break;
									case "dotx":
										file_mate = "fl-doc";
										break;
									case "dotm":
										file_mate = "fl-doc";
										break;
									case "docm":
										file_mate = "fl-doc";
										break;
									case "dot":
										file_mate = "fl-doc";
										break;
									case "wps":
										file_mate = "fl-doc";
										break;
									case "wpt":
										file_mate = "fl-doc";
										break;
									case "vsd":
										file_mate = "fl-doc";
										break;
									case "rtf":
										file_mate = "fl-doc";
										break;
									case "jnt":
										file_mate = "fl-doc";
										break;
									case "pdf":
										file_mate = "fl-pdf";
										break;
									case "ppt":
										file_mate = "fl-ppt";
										break;
									case "pps":
										file_mate = "fl-ppt";
										break;
									case "pptx":
										file_mate = "fl-ppt";
										break;
									case "ppsx":
										file_mate = "fl-ppt";
										break;
									case "ppsm":
										file_mate = "fl-ppt";
										break;
									case "pptm":
										file_mate = "fl-ppt";
										break;
									case "potx":
										file_mate = "fl-ppt";
										break;
									case "potm":
										file_mate = "fl-ppt";
										break;
									case "ppam":
										file_mate = "fl-ppt";
										break;
									case "xls":
										file_mate = "fl-xls";
										break;
									case "xlt":
										file_mate = "fl-xls";
										break;
									case "xlsx":
										file_mate = "fl-xls";
										break;
									case "xlsm":
										file_mate = "fl-xls";
										break;
									case "xltx":
										file_mate = "fl-xls";
										break;
									case "xltm":
										file_mate = "fl-xls";
										break;
									case "xlsb":
										file_mate = "fl-xls";
										break;
									case "xlam":
										file_mate = "fl-xls";
										break;
									case "csv":
										file_mate = "fl-xls";
										break;
									case "jpg":
										file_mate = "fl-img";
										break;
									case "gif":
										file_mate = "fl-img";
										break;
									case "png":
										file_mate = "fl-img";
										break;
									case "jpeg":
										file_mate = "fl-img";
										break;
									case "bmp":
										file_mate = "fl-img";
										break;
									case "tif":
										file_mate = "fl-img";
										break;
									case "tiff":
										file_mate = "fl-img";
										break;
									case "ico":
										file_mate = "fl-img";
										break;
									case "fcx":
										file_mate = "fl-img";
										break;
									case "tga":
										file_mate = "fl-img";
										break;
									case "exif":
										file_mate = "fl-img";
										break;
									case "fpx":
										file_mate = "fl-img";
										break;
									case "svg":
										file_mate = "fl-img";
										break;
									case "eps":
										file_mate = "fl-img";
										break;
									case "psd":
										file_mate = "fl-ps";
										break;
									case "md":
										file_mate = "fl-md";
										break;
									case "json":
										file_mate = "fl-md";
										break;
									case "js":
										file_mate = "fl-md";
										break;
									case "less":
										file_mate = "fl-md";
										break;
									case "bat":
										file_mate = "fl-md";
										break;
									case "css":
										file_mate = "fl-md";
										break;
									case "java":
										file_mate = "fl-md";
										break;
									case "jsp":
										file_mate = "fl-md";
										break;
									case "class":
										file_mate = "fl-md";
										break;
									case "vue":
										file_mate = "fl-v";
										break;
									case "exe":
										file_mate = "fl-html";
										break;
									case "htm":
										file_mate = "fl-html";
										break;
									case "html":
										file_mate = "fl-html";
										break;
									case "mmap":
										file_mate = "fl-mmap";
										break;
									case "dmg":
										file_mate = "fl-dmg";
										break;
									case "ai":
										file_mate = "fl-ai";
										break;
									case "zip":
										file_mate = "fl-zip";
										break;
									case "rar":
										file_mate = "fl-zip";
										break;
									case "7z":
										file_mate = "fl-zip";
										break;
									case "mp4":
										file_mate = "fl-video";
										break;
									case "wmv":
										file_mate = "fl-video";
										break;
									case "avi":
										file_mate = "fl-video";
										break;
									case "flv":
										file_mate = "fl-video";
										break;
									case "rmvb":
										file_mate = "fl-video";
										break;
									case "rm":
										file_mate = "fl-video";
										break;
									case "flash":
										file_mate = "fl-video";
										break;
									case "3gp":
										file_mate = "fl-video";
										break;
									case "swf":
										file_mate = "fl-video";
										break;
									case "mp3":
										file_mate = "fl-audio";
										break;
									case "amr":
										file_mate = "fl-audio";
										break;
									case "wma":
										file_mate = "fl-audio";
										break;
									case "wav":
										file_mate = "fl-audio";
										break;
									case "aac":
										file_mate = "fl-audio";
										break;
									case "flac":
										file_mate = "fl-audio";
										break;
									case "ape":
										file_mate = "fl-audio";
										break;
									case "mid":
										file_mate = "fl-audio";
										break;
									case "ogg":
										file_mate = "fl-audio";
										break;
									case "torrent":
										file_mate = "fl-bt";
										break;
									default:
										file_mate = "fl-other";
								}
							} else {
								file_mate = "fl-folder";
							}
							let fromType = '';

							if (v.from == 2) {
								fromType = '订单'
							} else if (v.from == 3) {
								fromType = '邮件'
							} else if (v.from == 4) {
								fromType = '上传'
							} else if (v.from == 5) {
								fromType = '其他'
							}
							var creator = "未知";
							$.each(self.userlistall, function (i, row) {
								if (v.userId == row.user_id) {
									creator = row.user_name;
								}
							})
							self.fileuploadList.push({
								name: v.name,
								file_mate: file_mate,
								time: v.createTime.substring(0, 10),
								time1: v.createTime,
								size: failsize,
								creator: creator,
								from: fromType,
								token: v.token,
								id: v.id,
								folderId: v.folderId,
								change: false,
								type: v.type,
							});
						});
					}
				}
			});
		},
		//文件预览
		lookFile(fileToken, name) {
			this.looFjName = name
			this.fileToken = fileToken
			let self = this;
			$.ajax({
				type: "POST",
				url: diskUrl + "/file/previewUrl/get",
				async: false,
				cache: false,
				data: {
					type: 0,
					token: fileToken,
				},
				success: function (msg) {
					self.lookFjUrl = msg.data
				}
			});
			this.lookFj = true
		},
		//查看下一级目录
		lookNextFile(id, name) {
			this.fileid = id;
			this.cusUploadRecord.push(this.file_tolRecord);
			this.cusUploadData.push(this.fileuploadList)
			this.file_currpage = 1;
			this.file_pageSize = 5;
			this.fileDirectory = name; //获取文件目录名称
			$(".all_file").hide();
			$(".ul-bar").show();
			$("#titleTip").attr("title", "全部文件/" + this.fileDirectory);
			this.getFileList(1);
		},
		//返回上一级
		returnToUpperLevel() {
			this.file_tolRecord = this.cusUploadRecord.pop();
			this.fileuploadList = this.cusUploadData.pop();
			if (this.cusUploadData.length == 0) {
				this.cusUploadData = [];
				$(".all_file").show();
				$(".ul-bar").hide();
				this.fileid = this.folderid.data;
				this.getFileList(1);
			}
		},
		//查看全部
		loadAllFile() {
			$(".all_file").show();
			$(".ul-bar").hide();
			this.fileid = this.folderid.data;
			this.getFileList(1);
		},
		//初始化客户信息
		initCustomerInfo() {
			this.classify_id = this.customerInformation.classify_id;
			// 标识国旗
			if(this.customerInformation.country != ''){
				let temp_flag      = this.countryArr.find(item => item.name == this.customerInformation.country);
				this.country_flag  = temp_flag != undefined ?  temp_flag.clazz : '';
			}
			this.email = this.customerInformation.email;
			//有主联系人
			if (this.customerInformation.linkman != null && this.customerInformation.linkman != '') {
				this.link_name = this.customerInformation.linkman.full_name;
			} else { //无主联系人
				this.link_name = this.customerInformation.customer_name;
			}
			//企业头像
			if (this.customerInformation.img_url !== undefined && this.customerInformation.img_url !== null && this.customerInformation.img_url != 'null' && this.customerInformation.img_url !== '') {

				if (this.customerInformation.img_url.indexOf("http://") != -1 || this.customerInformation.img_url.indexOf("https://") != -1) {
					this.customer_img = this.customerInformation.img_url
				} else {
					this.customer_img = backUrl + this.customerInformation.img_url.replace('..', '').replace('//', '/');
				}
			}
			this.ratingLevel = this.customerInformation.important_star;
			var lef = this;
			$.each(lef.customerclassifyall, function (i, row) {
				if (lef.customerInformation.classify_id == row.id) {
					lef.classify_name = row.name;
					return false;
				}
			});
			$.each(lef.customerfromall, function (i, row) {
				if (lef.customerInformation.source_from_id == row.id) {
					lef.source_name = row.name;
					return false;
				}
			});
			$.each(lef.customerrelationall, function (i, row) {
				if (lef.customerInformation.partnership == row.id) {
					lef.relation_name = row.name;
					return false;
				}
			});
			$.each(lef.customerstatusall, function (i, row) {
				if (lef.customerInformation.customer_status == row.id) {
					lef.status_name = row.name;
					return false;
				}
			});
			$.each(lef.industryType, function (i, row) {
				if (lef.customerInformation.calling == row.cid) {
					lef.hangye_name = row.cname;
					return false;
				}
			});
			if (lef.customerInformation.prductid != null && lef.customerInformation.prductid != '') {
				var product_arr = lef.customerInformation.prductid.split(',');
				var pname = '未知';
				for (var i = 0; i < product_arr.length; i++) {
					$.each(this.majorproductall, function (s, row) {
						if (row.id == parseInt(product_arr[i])) {
							pname += row.name + " | ";
							return false;
						}
					});
				}
				if (pname != '未知') {
					pname = pname.replace('未知', '');
					pname = pname.substring(0, pname.length - 2);
				}
				lef.product_name = pname;
				lef.productId=lef.customerInformation.prductid
			} else {
				lef.product_name = '未知';
				lef.productId=''
			}
			lef.productIdLoading=true
		},
		//修改客户星级
		updateStar() {
			var lef = this;
			if (lef.isOnlyRead) {
				return;
			}
			$.ajax({
				"type": "POST",
				"async": false,
				"cache": false,
				"url": backUrl + "cus_updateCusStarById.action",
				data: {
					token: token,
					customer_id: lef.customer_id,
					stars: 0 + "," + lef.ratingLevel
				},
				success: function (msg) {
					if (msg == 1) {
						lef.$emit('refreshCusList');
						lef.$message({
							showClose: true,
							message: '修改成功！',
							type: 'success',
							duration: 2000
						});
					} else {
						lef.$message({
							showClose: true,
							message: '修改失败！',
							type: 'warning',
							duration: 2000
						});
						lef.ratingLevel = oldv;
					}
				}
			});
		},
		//修改标签
		submitTipManager(parvalue) {
			var yewu_arr = parvalue.split("###");
			var lef = this;
			$.ajax({
	          type: "POST",
	          url: backUrl + "cus_checkCanUpdateClassify.action",
	          data: {
	            token: token,
	            customerid: lef.customer_id,
	            classify_id: yewu_arr[5]
	          },
	          success: function(msg) {
	            if(msg == 'ok'){
	            	$.ajax({
						"type": "POST",
						"cache": false,
						"url": backUrl + "cus_updateCustpmerYeWu.action",
						"data": {
							token: token,
							customer_id: lef.customer_id,
							fenlei: yewu_arr[5], //客户分类
							chanpin: yewu_arr[3], //主营产品
							zhuantai: yewu_arr[1], //客户类型
							guanxi: yewu_arr[2], //客户关系
							hangye: yewu_arr[4], //所属行业
							laiyuan: yewu_arr[0] //客户来源
						},
						beforeSend: function () {
							lef.$store.state.popupLoading = true;
						},
						complete: function () {
							lef.$store.state.popupLoading = false;
						},
						success: function (msg) {
							if (msg == 1) {
								lef.$message({
									showClose: true,
									message: '编辑成功！',
									type: 'success',
									duration: 2000
								});
								lef.getCustomerInformation(lef.customer_id);
								if (parvalue.split("###")[7] == 1) {
									lef.$confirm('需要同步到ERP系统?', '提示', {
										confirmButtonText: '确定',
										cancelButtonText: '取消',
										cancelButtonClass: 'cancelButtonClass',
									}).then(() => {
										lef.modifySelfERP(lef.customer_id)
									}).catch(() => {
		
									})
								}
								lef.chooseProid = lef.customerInformation.prductid;
								lef.initCustomerInfo();
								//重新加载已入库客户数量
								lef.$store.state.loadCusNumTag = 7;
							} else {
								lef.$message({
									showClose: true,
									message: '编辑失败！',
									type: 'warning',
									duration: 2000
								});
							}
						},
						error: function () {
							lef.$message({
								showClose: true,
								message: '编辑失败！',
								type: 'warning',
								duration: 2000
							});
						}
					});
	            }else if(msg == 'error'){
	            	lef.$message({
		              	showClose: true,
		             	message: '编辑失败',
		              	type: "warning",
		              	duration: 2000
	            	});
	            }else{
	              	lef.$message({
	              		showClose: true,
	             		message: ''+msg,
	              		type: "warning",
	              		duration: 5000
	            	});
	            }
	          }
	      });
		},
		//修改erp
		modifySelfERP(id) {
			let self = this
			if (this.customerInformation.organization_code === undefined || this.customerInformation.organization_code === null) {
				this.$message({
					message: '该客户没有填写客户编号！',
					type: 'warning'
				})
				return
			}
			$.ajax({
				type: 'POST',
				url: backUrl + 'erp_addMyCustomerOneToErp.action',
				cache: false,
				async: false,
				data: {
					token: token,
					customer_id: id
				},
				success: function (res) {
					if (res == 1) {
						self.$message({
							message: '操作成功！',
							type: 'success',
						})
					} else if (res == 0) {
						self.$message({
							message: '操作失败！',
							type: 'warning',
						})
					}
					self.selectArr = []
				}
			})
		},
		//提交动态
		submitDynamic() {
			if (this.dynamic.trim() == '') {
				this.$message({
					showClose: true,
					message: '请输入动态内容！',
					type: 'warning',
					duration: 2000
				});
				return;
			}
			if ($.trim(this.dynamic).lenght > 200) {
				this.$message({
					showClose: true,
					message: '动态内容不能超过200个字符！',
					type: 'warning',
					duration: 2000
				});
				return;
			}
			var lef = this;
			if (!lef.isOnlyRead) //我自己的客户
			{
				$.ajax({
					"type": "POST",
					"async": false,
					"cache": false,
					"url": backUrl + "dynamic_getDynamicNumsByCusid.action",
					data: {
						token: token,
						customer_id: lef.customer_id
					},
					success: function (msg) {
						if (msg != -1) {
							if (msg < 3) {
								lef.$confirm('是否对该动态置顶?', '提示', {
									confirmButtonText: '是',
									cancelButtonText: '否',
									type: 'warning',
									closeOnClickModal: false,
									cancelButtonClass: 'MBcancelBtn'
								}).then(() => {
									$.ajax({
										"type": "POST",
										"async": false,
										"cache": false,
										"url": backUrl + "dynamic_addCustomerDynamic.action",
										data: {
											token: token,
											customer_id: lef.customer_id,
											value: lef.dynamic,
											is_top: 1,
											id1: lef.dynamicFollowWayId,
											id2: lef.dynamicSaleProcessId
										},
										success: function (msg) {
											if (msg == 1) {
												lef.$message({
													showClose: true,
													message: '添加成功！',
													type: 'success',
													duration: 2000
												});
												lef.dynamic = '';
												if (lef.dynamicType != 0) {
													lef.dynamicType = 0;
												} else {
													lef.showDynamic();
												}
											} else {
												lef.$message({
													showClose: true,
													message: '添加失败！',
													type: 'warning',
													duration: 2000
												});
											}
										},
										error: function (e) {
											lef.$message({
												showClose: true,
												message: '添加失败！',
												type: 'warning',
												duration: 2000
											});
										}
									});
								}).catch(() => {
									$.ajax({
										"type": "POST",
										"async": false,
										"cache": false,
										"url": backUrl + "dynamic_addCustomerDynamic.action",
										data: {
											token: token,
											customer_id: lef.customer_id,
											value: lef.dynamic,
											is_top: 0,
											id1: lef.dynamicFollowWayId,
											id2: lef.dynamicSaleProcessId
										},
										success: function (msg) {
											if (msg == 1) {
												lef.$message({
													showClose: true,
													message: '添加成功！',
													type: 'success',
													duration: 2000
												});
												lef.dynamic = '';
												if (lef.dynamicType != 0) {
													lef.dynamicType = 0;
												} else {
													lef.showDynamic();
												}
											} else {
												lef.$message({
													showClose: true,
													message: '添加失败！',
													type: 'warning',
													duration: 2000
												});
											}
										},
										error: function (e) {
											lef.$message({
												showClose: true,
												message: '添加失败！',
												type: 'warning',
												duration: 2000
											});
										}
									});
								});
							} else {
								$.ajax({
									"type": "POST",
									"async": false,
									"cache": false,
									"url": backUrl + "dynamic_addCustomerDynamic.action",
									data: {
										token: token,
										customer_id: lef.customer_id,
										value: lef.dynamic,
										is_top: 0,
										id1: lef.dynamicFollowWayId,
										id2: lef.dynamicSaleProcessId
									},
									success: function (msg) {
										if (msg == 1) {
											lef.$message({
												showClose: true,
												message: '添加成功！',
												type: 'success',
												duration: 2000
											});
											lef.dynamic = '';
											if (lef.dynamicType != 0) {
												lef.dynamicType = 0;
											} else {
												lef.showDynamic();
											}
										} else {
											lef.$message({
												showClose: true,
												message: '添加失败！',
												type: 'warning',
												duration: 2000
											});
										}
									},
									error: function (e) {
										lef.$message({
											showClose: true,
											message: '添加失败！',
											type: 'warning',
											duration: 2000
										});
									}
								});
							}

							// 统计跟进记录
							lef.updateFollowcount(lef.customer_id);
						}
					}
				});
			} else { //别人共享给我的
				$.ajax({
					"type": "POST",
					"async": false,
					"cache": false,
					"url": backUrl + "dynamic_addCustomerDynamic.action",
					data: {
						token: token,
						customer_id: lef.customer_id,
						value: lef.dynamic,
						is_top: 0,
						id1: lef.dynamicFollowWayId,
						id2: lef.dynamicSaleProcessId
					},
					success: function (msg) {
						if (msg == 1) {
							lef.$message({
								showClose: true,
								message: '添加成功！',
								type: 'success',
								duration: 2000
							});
							lef.dynamic = '';
							if (lef.dynamicType != 0) {
								lef.dynamicType = 0;
							} else {
								lef.showDynamic();
							}
							// 统计跟进记录
							lef.updateFollowcount(lef.customer_id);
						} else {
							lef.$message({
								showClose: true,
								message: '添加失败！',
								type: 'warning',
								duration: 2000
							});
						}
					},
					error: function (e) {
						lef.$message({
							showClose: true,
							message: '添加失败！',
							type: 'warning',
							duration: 2000
						});
					}
				});
			}
		},
		//动态置顶或取消置顶
		updateTop(customer_id,id, is_top) {
			if (is_top == 0) {
				this.$confirm("是否取消该动态的置顶?", '提示', {
					confirmButtonText: '是',
					cancelButtonText: '否',
					type: 'warning',
					closeOnClickModal: false,
					cancelButtonClass: 'MBcancelBtn'
				}).then(() => {
					var lef = this;
					$.ajax({
						"type": "POST",
						"async": false,
						"cache": false,
						"url": backUrl + "dynamic_updateDynamicTopById.action",
						data: {
							token: token,
							dynamic_id: id,
							is_top: is_top
						},
						success: function (msg) {
							if (msg != 0) {
								lef.$message({
									showClose: true,
									message: '取消置顶成功',
									type: 'success',
									duration: 2000
								});
								lef.showDynamic();
							} else {
								lef.$message({
									showClose: true,
									message: "取消置顶失败！",
									type: 'warning',
									duration: 2000
								});
							}
						},
						error: function (e) {
							lef.$message({
								showClose: true,
								message: "取消置顶失败！",
								type: 'warning',
								duration: 2000
							});
						}
					});
				}).catch(() => { });
			} else {
				var lef = this;
				$.ajax({
					"type": "POST",
					"async": false,
					"cache": false,
					"url": backUrl + "dynamic_getDynamicNumsByCusid.action",
					data: {
						token: token,
						customer_id: customer_id
					},
					success: function (msg) {
						if(msg >= 3){
							lef.$message({
								showClose: true,
								message: "只能置顶3条动态",
								type: 'warning',
								duration: 2000
							});
							return;
						}
						lef.$confirm("是否置顶该动态?", '提示', {
							confirmButtonText: '是',
							cancelButtonText: '否',
							type: 'warning',
							closeOnClickModal: false,
							cancelButtonClass: 'MBcancelBtn'
						}).then(() => {
							$.ajax({
								"type": "POST",
								"async": false,
								"cache": false,
								"url": backUrl + "dynamic_updateDynamicTopById.action",
								data: {
									token: token,
									dynamic_id: id,
									is_top: is_top
								},
								success: function (msg) {
									if (msg != 0) {
										lef.$message({
											showClose: true,
											message: '置顶成功',
											type: 'success',
											duration: 2000
										});
										lef.showDynamic();
									} else {
										lef.$message({
											showClose: true,
											message: "置顶失败！",
											type: 'warning',
											duration: 2000
										});
									}
								},
								error: function (e) {
									lef.$message({
										showClose: true,
										message: "置顶失败！",
										type: 'warning',
										duration: 2000
									});
								}
						});
				}).catch(() => { });
					},
					error: function (e) {
							lef.$message({
								showClose: true,
								message: "置顶失败！",
								type: 'warning',
								duration: 2000
							});
						}
					});	
			}
		},
		//加载动态
		showDynamic() {
			var lef = this;
			lef.dynamicList = [];
			$.ajax({
				"type": "POST",
				"async": false,
				"cache": false,
				"url": backUrl + "dynamic_getDynamicByCusid.action",
				data: {
					token: token,
					customer_id: lef.customer_id
				},
				success: function (msg) {
					$.each(msg, function (i, row) {
						var username = "未知用户";
						if (row.submit_user_id != lef.userinfo.user_id) {
							$.each(lef.userlistall, function (i, user) {
								if (row.submit_user_id == user.user_id) {
									username = user.user_name;
									return false;
								}
							});
						} else {
							username = "我";
						}
						lef.dynamicList.push({
							"id": row.id,
							"username": username,
							"create_time": row.create_time,
							"dynamic_info": row.dynamic_info,
							"is_top": row.is_top,
							"customer_id": row.customer_id
						});
					});
				}
			});
		},
		//加载跟进记录
		showFollowupRecord() {
			var lef = this;
			lef.recordList = [];
			$.ajax({
				"type": "POST",
				"async": false,
				"cache": false,
				"url": backUrl + "cusfu3_findAllFollowupRecord.action",
				data: {
					token: token,
					customer_id: lef.customer_id
				},
				success: function (msg) {
					$.each(msg.followuplist, function (i, row) {
						var username = "我";
						if (row.create_user_id != lef.userinfo.user_id) {
							$.each(lef.userlistall, function (i, user) {
								if (row.create_user_id == user.user_id) {
									username = user.user_name;
									return false;
								}
							});
						}
						lef.recordList.push({
							"username": username,
							"create_date": row.create_date,
							"followup_content": row.followup_content
						});
					});
				}
			});
		},
		//加载生命线
		showLifeRecycle() {
			var lef = this;
			lef.lifeList = [];
			$.ajax({
				"type": "POST",
				"async": false,
				"cache": false,
				"url": backUrl + "xcus_findLifeCycleById.action",
				data: {
					token: token,
					customer_id: lef.customer_id
				},
				success: function (msg) {
					$.each(msg.cycles, function (i, row) {
						var username = "未知用户";
						var owner_id = row.owner_id; // 用户id
						if (owner_id == lef.userinfo.user_id) {
							username = "我";
						} else {
							$.each(lef.userlistall, function (index, user) {
								if (owner_id == user.user_id) {
									username = user.user_name;
									return false;
								}
							});
						}
						var update_time = row.update_time.replace(".0", "");
						var operator_name = '';
						if (row.owner_type == 1) // 新建客户
						{
							operator_name = '新建了该客户'
						} else if (row.owner_type == 2) // 转移客户
						{
							operator_name = '转移该客户给我'
						} else if (row.owner_type == 3) // 领取客户
						{
							operator_name = '从公海库领取了该客户'
						}
						lef.lifeList.push({
							"username": username,
							"update_time": update_time,
							"operator_name": operator_name
						});
					});
				}
			});
		},
		//编辑成功返回
		updateSuccess() {
			this.$emit('refreshCusList');
		},
		//编辑改变状态
		changeState() {
			this.$emit('all_changeState'); //触发父组件的方法
		},
		//加载联系人列表
		getLinkManList() {
			var lef = this;
			lef.linkmanList = [];
			$.ajax({
				type: "POST",
				url: backUrl + "link_findLinkManByCustomerid.action",
				async: false,
				cache: false,
				dataType: "json",
				data: {
					token: token,
					customerid: lef.customer_id,
					currpage: lef.linkman_currpage,
					pagesize: 5
				},
				success: function (msg) {
					lef.linkman_tolpage = msg.pageBean.totalPage; //总页数
					lef.linkman_tolRecord = msg.pageBean.totalRecord; //总条数
					$.each(msg.crmlinkmanlist, function (index, row) {
						var tel_num = 0;
						if (row.phone != null && row.phone.trim() != '') {
							tel_num = row.phone.split("@G@").length;
						}
						var enrol_date = '';
						if (row.enrol_time != null && row.enrol_time != '') {
							enrol_date = row.enrol_time.split(" ")[0]
						}
						lef.linkmanList.push({
							"customer_id": row.customer_id,
							"linkman_id": row.linkman_id,
							"is_main": row.is_main_linkman,
							"name": row.full_name,
							"email": row.email,
							"date": enrol_date,
							"tel": row.phone,
							"tel_num": tel_num,
							"phone": row.mobile,

						})
					});
				}
			});
		},
		//查看联系人信息
		lookLinkMan(link_id) {
			var lef = this;
			$.ajax({
				type: "POST",
				url: backUrl + "link_findByLinkid.action",
				async: false,
				cache: false,
				dataType: "json",
				data: {
					token: token,
					linkmanid: link_id
				},
				success: function (msg) {
					lef.connectTab = [];
					lef.connectTab = [{
						"title": "姓名",
						"info": msg.full_name
					},
					{
						"title": "职位",
						"info": msg.post
					},
					{
						"title": "邮箱",
						"info": msg.email
					},
					{
						"title": "手机",
						"info": msg.mobile
					},
					{
						"title": "性别",
						"info": msg.sex
					},
					{
						"title": "地址",
						"info": msg.address
					},
					{
						"title": "网址",
						"info": msg.website
					}
					];
				}
			});
			this.showC = true;
		},
		//查看多电话
		lookManyPhone(phone) {
			var lef = this;
			lef.phoneArr = [];
			$.each(phone.split('@G@'), function (index, row) {
				lef.phoneArr.push(row);
			});
			lef.acus_phoneInfo = true;
		},
		//新增|编辑联系人
		addoreditLinkman(link_id) {
			this.linkman_id = link_id;
			this.showL = true;
		},
		//新增|编辑联系人成功
		linkmanReturn(isMain) {
			if (isMain == 1) { //更改了主次联系人则执行以下方法
				this.$emit('refreshCusList');
				this.getCustomerInformation(this.customer_id);
			}
			this.getLinkManList();
			this.showL = false;
			//重新加载已入库客户数量
			this.$store.state.loadCusNumTag = 8;
		},
		//删除联系人
		deleteLinkman(link_id) {
			this.$confirm('确定删除?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				closeOnClickModal: false,
				cancelButtonClass: 'MBcancelBtn'
			}).then(() => {
				var lef = this;
				$.ajax({
					type: "POST",
					url: backUrl + "link_delLinkMan.action",
					async: false,
					cache: false,
					dataType: "json",
					data: {
						token: token,
						linkmanid: link_id
					},
					success: function (msg) {
						if (msg == 1) {
							lef.$message({
								showClose: true,
								message: '删除成功！',
								type: 'success',
								duration: 2000
							});
							lef.getLinkManList();
							//重新加载已入库客户数量
							lef.$store.state.loadCusNumTag = 8;
						} else {
							lef.$message({
								showClose: true,
								message: '删除失败！',
								type: 'warning',
								duration: 2000
							});
						}
					}
				});
			}).catch(() => { });
		},
		//加载所有联系人
		getAllLinkMan() {
			var lef = this;
			lef.allLinkmanList = [];
			$.ajax({
				type: "POST",
				url: backUrl + "link_findAllLinkManByCustomerid.action",
				async: false,
				cache: false,
				dataType: "json",
				data: {
					token: token,
					customerid: lef.customer_id,
				},
				success: function (msg) {
					if (msg.length > 1) {
						lef.allLinkmanList.push({ "id": 0, "name": "所有人", "email": "所有人" });
					}
					for (var i = 0; i < msg.length; i++) {
						lef.allLinkmanList.push(
							{
								"id": msg[i].linkman_id,
								"name": msg[i].full_name,
								"email": msg[i].email
							}
						);
					}
				}
			});
		},
		//收件箱联系人改变事件
		rLinkmanChange() {
			this.rmail_currpage = 1;
			this.getRMailList();
		},
		//发件箱联系人改变事件
		sLinkmanChange() {
			this.smail_currpage = 1;
			this.getSMailList();
		},
		//加载收件箱列表
		getRMailList() {
			var lef = this;
			lef.rmailList = [];
			var action = 'contacts_findCusRMail.action';
			if (lef.cus_poporimap == 1) {
				action = 'contactsimap_findCusRMail.action';
			}
			$.ajax({
				type: "POST",
				url: backUrl + action,
				async: true,
				cache: false,
				dataType: "json",
				beforeSend: function () {
					lef.autographLoading = true;
				},
				complete: function () {
					lef.autographLoading = false;
				},
				data: {
					token: token,
					currentpage: lef.rmail_currpage,
					cusid: lef.customer_id,
					accountid: lef.cus_mailAccountId,
					mailaccount: lef.cus_mailAccount,
					linkid: lef.rCheckLinkmanId
				},
				success: function (msg) {
					lef.rmail_tolpage = msg.pageBean.totalPage; //总页数
					lef.rmail_tolRecord = msg.pageBean.totalRecord; //总条数
					var lists = "";
					if (lef.cus_poporimap == 0) {
						lists = msg.rmaillist;
					} else {
						lists = msg.datalist;
					}
					if (lists != "" && lists != null && lists != undefined) {
						$.each(lists, function (index, row) {
							var from_name = "",
								from_mail = "";
							var date_str = "";
							var id = 0;
							var folder = "";
							if (lef.cus_poporimap == 0) {
								id = row.receive_mail_id;
								if (row.from_name != null && row.from_name != "") {
									from_name = row.from_name;
								} else {
									from_name = row.from_email.split('@')[0];
								}
								from_mail = row.from_email;
								//时间
								date_str = row.receibe_time.replace('.0', '');
								if (row.delete_flag == 1) {//已删除(可见)
									folder = "已删除";
								} else if (row.delete_flag == 2) {//已删除(不可见)
									folder = "已删除";
								} else {//收件箱
									if (row.status == 1) {
										folder = "垃圾箱";
									} else {//收件箱
										folder = "收件箱";
									}
								}
							} else {
								id = row.id;
								var fromer = row.fromer;
								if (fromer != null && fromer != "") {
									if (fromer.indexOf('<') != -1) {
										from_name = fromer.split('<')[0];
										from_mail = fromer.split('<')[1].split('>')[0];
									} else {
										from_name = fromer.split('@')[0];
										from_mail = fromer;
									}
								}
								date_str = row.send_time.replace('.0', '');
								if (row.delete_flag == 1) {//已删除(可见)
									folder = "已删除";
								} else if (row.delete_flag == 2) {//已删除(不可见)
									folder = "已删除";
								} else {//收件箱
									if (row.imap_folder_id == -4) {
										folder = "垃圾箱";
									} else {//收件箱
										folder = "收件箱";
									}
								}
							}
							if (from_name.length > 12) {
								from_name = from_name.substring(0, 12) + "...";
							}
							var linkTitle = row.subject;
							linkTitle = $.trim(linkTitle);
							linkTitle = linkTitle.replace(/\n/g, "");
							linkTitle = linkTitle.replace(/\r/g, "");
							linkTitle = linkTitle.replace(/\"/g, "&quot;");
							linkTitle = linkTitle.replace(/\'/g, "&acute;");
							linkTitle = linkTitle.replace(/\：/g, ":");
							linkTitle = linkTitle.replace(/</g, "&lt;");
							linkTitle = linkTitle.replace(/>/g, "&gt;");
							linkTitle = linkTitle.replace(/ /g, "");
							linkTitle = linkTitle.replace(/ /g, "");
							lef.rmailList.push({
								"id": id,
								"name": from_name,
								"name_email": from_name + "###" + from_mail,
								"title": linkTitle,
								"size": lef.handlerEmailSize(row.mail_size),
								"date_str0": date_str.split(' ')[0],
								"date_str1": date_str.split(' ')[1],
								"folder": folder
							})
						});
					}
				}
			});
		},
		//加载发件箱列表
		getSMailList() {
			var lef = this;
			lef.smailList = [];
			var action = 'contacts_findCusSMail.action';
			if (this.cus_poporimap == 1) {
				action = 'contactsimap_findCusSMail.action';
			}
			$.ajax({
				type: "POST",
				url: backUrl + action,
				async: true,
				cache: false,
				dataType: "json",
				beforeSend: function () {
					lef.autographLoading = true;
				},
				complete: function () {
					lef.autographLoading = false;
				},
				data: {
					token: token,
					currentpage: lef.smail_currpage,
					cusid: lef.customer_id,
					accountid: lef.cus_mailAccountId,
					mailaccount: lef.cus_mailAccount,
					linkid: lef.sCheckLinkmanId
				},
				success: function (msg) {
					lef.smail_tolpage = msg.pageBean.totalPage; //总页数   
					lef.smail_tolRecord = msg.pageBean.totalRecord; //总条数
					var lists = "";
					if (lef.cus_poporimap == 0) {
						lists = msg.smaillist;
					} else {
						lists = msg.datalist;
					}
					if (lists != "" && lists != null && lists != undefined) {
						$.each(lists, function (index, row) {
							var name = '多个';
							var name_email = '';
							var data_str = "";
							var id = 0;
							var folder = "";
							if (lef.cus_poporimap == 0) {
								id = row.send_mail_id;
								if (row.detaillist != "" && row.detaillist != null && row.detaillist.length != 0) {
									if (row.detaillist.length == 1) {
										name = row.detaillist[0].email.split('@')[0];
										if (row.detaillist[0].receiver != null && row.detaillist[0].receiver != '') {
											name = row.detaillist[0].receiver;
											if (name.length > 12) {
												name = name.substring(0, 12) + "...";
											}
										}
										name_email = name + '###' + row.detaillist[0].email;
									} else {
										for (var i = 0; i < row.detaillist.length; i++) {
											var sname = row.detaillist[i].email.split('@')[0];
											if (row.detaillist[i].receiver != null && row.detaillist[i].receiver != '') {
												sname = row.detaillist[i].receiver;
											}
											name_email += sname + '###' + row.detaillist[i].email + '@G@';
										}
										name_email = name_email.substring(0, name_email.length - 3);
									}
								}
								data_str = row.create_time.replace('.0', '');
							} else {
								id = row.id;
								var receivers = row.receiver;
								var mail = "";
								if (receivers != "" && receivers != null) {
									if (receivers.indexOf(',') != -1) {
										var str = receivers.split(',');
										for (var i = 0; i < str.length; i++) {
											name_email += str[i].split('<')[0] + '###' + str[i].split('<')[1].split('>')[0] + '@G@';
										}
										name_email = name_email.substring(0, name_email.length - 3);
									} else {
										if (receivers.indexOf('<') != -1) {
											name = receivers.split('<')[0];
											mail = receivers.split('<')[1].split('>')[0];
										} else {
											name = receivers.split('@')[0];
											mail = receivers;
										}
										if (name.length > 12) {
											name = name.substring(0, 12) + "...";
										}
										name_email = name + '###' + mail;
									}
								}
								data_str = row.send_time.replace('.0', '');
							}
							if (row.delete_flag == 1) {//已删除(可见)
								folder = "已删除";
							} else if (row.delete_flag == 2) {//已删除(不可见)
								folder = "已删除";
							} else {//收件箱
								folder = "已发邮件";
							}
							var linkTitle = row.subject;
							linkTitle = $.trim(linkTitle);
							linkTitle = linkTitle.replace(/\n/g, "");
							linkTitle = linkTitle.replace(/\r/g, "");
							linkTitle = linkTitle.replace(/\"/g, "&quot;");
							linkTitle = linkTitle.replace(/\'/g, "&acute;");
							linkTitle = linkTitle.replace(/\：/g, ":");
							linkTitle = linkTitle.replace(/</g, "&lt;");
							linkTitle = linkTitle.replace(/>/g, "&gt;");
							linkTitle = linkTitle.replace(/ /g, "");
							linkTitle = linkTitle.replace(/ /g, "");
							lef.smailList.push({
								"id": id,
								"name": name,
								"name_email": name_email,
								"title": linkTitle,
								"size": lef.handlerEmailSize(row.mail_size),
								"data_str0": data_str.split(' ')[0],
								"data_str1": data_str.split(' ')[1],
								"folder": folder
							})
						});

					}
				}
			});
		},
		//查看邮件收|发件人
		lookMailName(names) {
			this.addressList = [];
			var name_arr = names.split("@G@");
			for (var i = 0; i < name_arr.length; i++) {
				this.addressList.push({
					"receivername": name_arr[i].split('###')[0],
					"email": name_arr[i].split('###')[1]
				});
			}
			this.acusR_moreaddress = true;
		},
		//处理邮件大小
		handlerEmailSize(emailSize) {
			var finalSize = "";
			var mailsizeM = emailSize / 1024 / 1024;
			var mailsiezeK = emailSize / 1024;
			if (mailsizeM > 1) {
				finalSize = mailsizeM.toFixed(2) + "M";
			} else {
				finalSize = mailsiezeK.toFixed(2) + "K";
			}
			return finalSize;
		},
		//查看邮件内容
		lookMailInfo(mailid, type) {
			this.mailId = mailid;
			this.mailType = type;
			this.showMessageInfo = true;
		},
		//加载跟进计划列表
		getFollowPlanList() {
			var lef = this;
			var followup_value = "plan###" + lef.wayId + "###" + lef.planType;
			lef.planList = [];
			$.ajax({
				type: "POST",
				url: backUrl + "cusfu3_findFollowupByCRM.action",
				async: false,
				cache: false,
				dataType: "json",
				data: {
					token: token,
					value: followup_value,
					pagesize: 5,
					currpage: lef.follow_plan_currpage,
					customer_id: lef.customer_id
				},
				success: function (msg) {
					lef.follow_plan_tolpage = msg.pageBean.totalPage; //总页数    
					lef.follow_plan_tolRecord = msg.pageBean.totalRecord; //总条数
					$.each(msg.followuplist, function (index, row) {
						var tool = '未知';
						$.each(lef.followupwayall, function (i, way) {
							if (way.id == row.followup_way) {
								tool = way.name;
								return false;
							}
						});
						//跟进状态
						var plan = '';
						$.each(lef.planOrRecordStatus, function (m, n) {
							if (row.followup_classify == n.typeid) {
								plan = n.name;
								return false;
							}
						});
						var date_str = row.followup_time.split(' ')[0];
						var time_str = row.followup_time.split(' ')[1];
						//获取跟进内容的纯文本
						var fc = row.followup_content;
						fc = fc.replace(/<[^>]*>|/g, "");

						var user_name = "未知用户";
						$.each(lef.userlistall, function (i, user) {
							if (row.create_user_id == user.user_id) {
								user_name = user.user_name;
								return false;
							}
						});

						lef.planList.push({
							"id": row.id,
							"tool": tool,
							"content": fc,
							"date_str": date_str,
							"time_str": time_str,
							"plan": plan,
							"type": row.followup_classify,
							"mailid": row.mail_id,
							"user_name": user_name,
						})
					});
				}
			});
		},
		//加载跟进记录列表
		getFollowRecordList() {
			var lef = this;
			var followup_value = "record###" + lef.wayId + "###" + lef.recordType;
			lef.recordList = [];
			$.ajax({
				type: "POST",
				url: backUrl + "cusfu3_findFollowupByCRM.action",
				async: false,
				cache: false,
				dataType: "json",
				data: {
					token: token,
					value: followup_value,
					pagesize: 5,
					currpage: lef.follow_record_currpage,
					customer_id: lef.customer_id
				},
				success: function (msg) {
					lef.follow_record_tolpage = msg.pageBean.totalPage; //总页数   
					lef.follow_record_tolRecord = msg.pageBean.totalRecord; //总条数
					$.each(msg.followuplist, function (index, row) {
						var tool = '未知';
						if (row.followup_way == 5) {
							tool = '营销';
						}
						$.each(lef.followupwayall, function (i, way) {
							if (way.id == row.followup_way) {
								tool = way.name;
								return false;
							}
						});
						//跟进状态
						var record = '';
						$.each(lef.planOrRecordStatus, function (m, n) {
							if (row.followup_classify == n.typeid) {
								record = n.name;
								return false;
							}
						});
						var date_str = row.followup_time.split(' ')[0];
						var time_str = row.followup_time.split(' ')[1];
						//获取跟进内容的纯文本
						var fc = row.followup_content;
						fc = fc.replace(/<[^>]*>|/g, "");

						var user_name = "未知用户";
						$.each(lef.userlistall, function (i, user) {
							if (row.create_user_id == user.user_id) {
								user_name = user.user_name;
								return false;
							}
						});

						lef.recordList.push({
							"id": row.id,
							"tool": tool,
							"content": fc,
							"date_str": date_str,
							"time_str": time_str,
							"record": record,
							"mailid": row.mail_id,
							"type": row.followup_classify,
							"user_name": user_name,
						})
					});
				}
			});
		},
		//未执行->立即执行
		planMouseEnter(v, id) {
			if (this.planList[v].plan == '未执行') {
				this.planList[v].plan = '立即执行'
			}
		},
		//立即执行->未执行
		planMouseLeave(v) {
			if (this.planList[v].plan == '立即执行') {
				this.planList[v].plan = '未执行'
			}
		},
		//立即执行跟进计划
		carryOut(v) {
			this.layer_Info = this.planList[v];
			var follow_data = this.planList[v].date_str + " " + this.planList[v].time_str;
			this.layer_Info.id = this.planList[v].id;
			this.layer_Info.follow_date = follow_data;
			this.layer_Info.content = this.planList[v].content;
			this.showCarryInfo = true;
		},
		carryClose() {
			this.showCarryInfo = false;
		},
		//提交立即执行跟进计划
		followupNow(id) {
			var lef = this;
			$.ajax({
				type: "POST",
				url: backUrl + "cusfu3_updateFollowupPlanStatus.action",
				//					async: false,
				cache: false,
				dataType: "json",
				data: {
					token: token,
					followup_id: id,
				},
				beforeSend: function () {
					lef.$store.state.popupLoading = true;
				},
				complete: function () {
					lef.$store.state.popupLoading = false;
				},
				success: function (msg) {
					if (msg == 1) {
						lef.$message({
							showClose: true,
							message: '执行成功！',
							type: 'success',
							duration: 2000
						});
						lef.showCarryInfo = false;
						lef.getFollowPlanList();
					} else {
						lef.$message({
							showClose: true,
							message: '执行失败！',
							type: 'warning',
							duration: 2000
						});
					}
				}
			});
		},
		//查看跟进信息
		lookFollowup(id) {
			var lef = this;
			$.ajax({
				type: "POST",
				url: backUrl + "cusfu3_getFollowupById.action",
				async: false,
				cache: false,
				dataType: "json",
				data: {
					token: token,
					followup_id: id
				},
				success: function (msg) {
					var mode = "未知";
					$.each(lef.followupwayall, function (index, row) {
						if (row.id == msg.followup_way) {
							mode = row.name;
							return false;
						}
					});
					var course = "未知";
					$.each(lef.saleprocessall, function (index, row) {
						if (row.id == msg.purpose_process) {
							course = row.name;
							return false;
						}
					});
					lef.fInfo = {
						"mode": mode,
						"Date": msg.followup_time,
						"remindDate": msg.remind_time,
						"course": course,
						"content": msg.followup_content
					},
						lef.showFlInfo = true;
				}
			});
		},
		//删除跟进
		deleteFollow(id, mailid) {
			if (mailid == 0) { //不是邮件跟进
				this.$confirm('确定删除?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					closeOnClickModal: false,
					cancelButtonClass: 'MBcancelBtn'
				}).then(() => {
					var lef = this;
					$.ajax({
						type: "POST",
						url: backUrl + "cusfu3_deleteFollowup.action",
						async: false,
						cache: false,
						dataType: "json",
						data: {
							token: token,
							followup_id: id,
						},
						success: function (msg) {
							if (msg == 1) {
								lef.$message({
									showClose: true,
									message: '删除成功！',
									type: 'success',
									duration: 2000
								});
								if (lef.planShow) { //删除计划
									lef.getFollowPlanList();
								} else { //删除记录
									lef.getFollowRecordList();
								}
							} else {
								lef.$message({
									showClose: true,
									message: '删除失败！',
									type: 'warning',
									duration: 2000
								});
							}
						}
					});
				}).catch(() => { })
			} else { //是邮件跟进
				this.$confirm('删除该跟进信息的同时也会删除该跟进的邮件?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					closeOnClickModal: false,
					cancelButtonClass: 'MBcancelBtn'
				}).then(() => {
					var lef = this;
					$.ajax({
						type: "POST",
						url: backUrl + "cusfu3_deleteFollowup.action",
						async: false,
						cache: false,
						dataType: "json",
						data: {
							token: token,
							followup_id: id,
						},
						success: function (msg) {
							if (msg == 1) {
								lef.$message({
									showClose: true,
									message: '删除成功！',
									type: 'success',
									duration: 2000
								});
								if (lef.planShow) { //删除计划
									lef.getFollowPlanList();
								} else { //删除记录
									lef.getFollowRecordList();
								}
								var savepath = lef.userparamlist.param111;
								if (savepath == "" || savepath == null) {
									savepath = lef.companyparamlist.param113;
								}
								$.ajax({
									type: "post",
									url: backUrl + "xmailbase_delmail.action",
									dataType: "json",
									cache: false,
									async: true,
									data: {
										token: token,
										idstr: mailid,
										savepath: savepath
									}
								});
							} else {
								lef.$message({
									showClose: true,
									message: '删除失败！',
									type: 'warning',
									duration: 2000
								});
							}
						}
					});
				}).catch(() => { })
			}
		},
		//添加|编辑跟进后返回
		followReturn(addRecord) {
			if (addRecord) { //加载记录列表且刷新客户列表
				this.getFollowRecordList();
				this.$emit('refreshCusList');
			} else { //加载计划列表
				this.getFollowPlanList();
			}
			this.showNFL = false;
			this.showFIL = false;
			this.showFIL2 = false;
			this.showFRec = false;
		},
		//编辑跟进
		updateFollowup(type, id, value) {
			this.followupId = id;
			this.planOrRecord = value;
			if (type == -1) { //未执行
				this.showFIL = true;
			} else if (type == 0) { //超时未处理
				this.showFIL2 = true;
			} else { //已执行或记录
				this.showFRec = true;
			}
		},
		//加载商机列表
		getChanceList(close) {
			var lef = this;
			lef.chanceList = [];
			$.ajax({
				type: "POST",
				url: backUrl + "cusSC_findSaleschanceByCusid.action",
				//					async: false,
				cache: false,
				dataType: "json",
				data: {
					token: token,
					pagesize: 5,
					currpage: lef.chance_currpage,
					customerid: lef.customer_id
				},
				beforeSend: function () {
					lef.$store.state.popupLoading = true;
				},
				complete: function () {
					lef.$store.state.popupLoading = false;
					if (close == 'close') {
						lef.showNBL = false;
						lef.showEBL = false;
					}
				},
				success: function (msg) {
					lef.chance_tolpage = msg.pageBean.totalPage; //总页数   
					lef.chance_tolRecord = msg.pageBean.totalRecord; //总条数
					$.each(msg.saleschancelist, function (index, row) {
						var source = '未知';
						$.each(lef.customerfromall, function (i, sfrom) {
							if (sfrom.id == row.source_from_id) {
								source = sfrom.name;
								return false;
							}
						});
						lef.chanceList.push({
							"id": row.id,
							"source": source,
							"date": row.pre_execute_time,
							"note": row.note_pad,
							"status": row.chance_status
						})
					});
				}
			});
		},
		//查看商机
		lookChane(id) {
			var lef = this;
			$.ajax({
				type: "POST",
				url: backUrl + "cusSC_findSalesChanceByid.action",
				async: false,
				cache: false,
				dataType: "json",
				data: {
					token: token,
					salechanceid: id
				},
				success: function (msg) {
					var chance_origin = "未知";
					$.each(lef.customerfromall, function (index, row) {
						if (row.id == msg.source_from_id) {
							chance_origin = row.name;
							return false;
						}
					});
					var r_currency = "未知";
					if (lef.tradecurrencyall == '') {
						lef.getTradeCurrencyAll();
					}
					$.each(lef.tradecurrencyall.crmcurrencylist, function (index, row) {
						if (row.id == msg.money_type) {
							r_currency = row.name;
							return false;
						}
					});
					lef.business_data = {
						"chance_origin": chance_origin,
						"success_odds": msg.chance_level,
						"r_currency": r_currency,
						"compelete_sum": msg.pre_execute_amount,
						"compelete_time": msg.pre_execute_time,
						"now_status": msg.chance_status,
						"remark": msg.note_pad
					};
					lef.showCRI = true;
				}
			});
		},
		//删除商机
		deleteChance(id) {
			this.$confirm('确定删除?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				closeOnClickModal: false,
				cancelButtonClass: 'MBcancelBtn'
			}).then(() => {
				var lef = this;
				$.ajax({
					type: "POST",
					url: backUrl + 'cusSC_deleteSaleschanceByid.action',
					async: false,
					cache: false,
					dataType: "json",
					data: {
						token: token,
						salechanceid: id
					},
					success: function (msg) {
						if (msg == 1) {
							lef.$message({
								showClose: true,
								message: '删除成功！',
								type: 'success',
								duration: 2000
							});
							lef.getChanceList();
						} else {
							lef.$message({
								showClose: true,
								message: '删除失败！',
								type: 'warning',
								duration: 2000
							});
						}
					}
				});
			}).catch(() => { });
		},
		//添加|编辑商机后返回
		chanceReturn() {
			this.getChanceList('close');
		},
		//编辑商机
		updateChance(id) {
			this.chanceId = id;
			this.showEBL = true;
		},
		//加载订单列表
		getSaleRecordList() {
			var lef = this;
			lef.saleorderList = [];
			lef.orderFirstLists = [];
			$.ajax({
				type: "POST",
				url: backUrl + "cusPR_findProductSalesByCusID.action",
				async: false,
				cache: false,
				dataType: "json",
				data: {
					token: token,
					pagesize: 5,
					currpage: lef.saleorder_currpage,
					customerid: lef.customer_id
				},
				success: function (msg) {
					lef.saleorder_tolpage = msg.pageBean.totalPage; //总页数  
					lef.saleorder_tolRecord = msg.pageBean.totalRecord; //总条数
					$.each(msg.crmproductsaleslist, function (index, row) {

						var user_name = "未知用户";
						$.each(lef.userlistall, function (i, user) {
							if (row.create_user_id == user.user_id) {
								user_name = user.user_name;
								return false;
							}
						});

						lef.saleorderList.push({
							"id": row.id,
							"type": row.record_type,
							"date": row.contract_time,
							"money": row.transcation_ammount,
							"note": row.sales_desc,
							"user_name": user_name,
						})
					});
					var first = lef.saleorderList.splice(0, 1);
					$.each(first, function (index, row) {
						var money = Math.round(row.money * 100) / 100;
						lef.orderFirstLists.push({
							money: money,
							type: row.type,
						})
					});
				}
			});
		},
		//删除订单
		deleteSale(id) {
			this.$confirm('确定删除?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				closeOnClickModal: false,
				cancelButtonClass: 'MBcancelBtn'
			}).then(() => {
				var lef = this;
				$.ajax({
					type: "POST",
					url: backUrl + 'cusPR_delSalesByid.action',
					async: false,
					cache: false,
					dataType: "json",
					data: {
						token: token,
						saleid: id
					},
					success: function (msg) {
						if (msg == 1) {
							lef.$message({
								showClose: true,
								message: '删除成功！',
								type: 'success',
								duration: 2000
							});
							lef.getSaleRecordList();
						} else {
							lef.$message({
								showClose: true,
								message: '删除失败！',
								type: 'warning',
								duration: 2000
							});
						}
					}
				});
			}).catch(() => { });
		},
		//添加|编辑订单后返回
		saleReturn() {
			this.getSaleRecordList();
			this.showNIL = false;
			this.showEIL = false;
		},
		//查看订单信息
		lookSale(id) {
			this.orderInfo1 = [];
			this.orderInfo2 = [];
			var str = {};
			var lef = this;
			if (this.orderStatusInfo == "") {
				this.getOrderStatusAllInfo();
			}
			if (lef.tradecurrencyall == '') {
				lef.getTradeCurrencyAll();
			}
			$.ajax({
				type: "POST",
				url: backUrl + "cusPR_findSalesByid.action",
				async: false,
				cache: false,
				dataType: "json",
				data: {
					token: token,
					saleid: id
				},
				success: function (msg) {
					var order_status_name = "";
					var sumMoney_unit = "";
					var loMoney_unit = "";
					var r_currency = "未知";
					str['customer_name'] = msg.customer_name;
					str['transcation_ammount'] = msg.transcation_ammount;
					str['logistics_company_name'] = msg.logistics_company_name;
					str['logistics_money'] = msg.logistics_money;
					str['create_date'] = msg.create_date;
					str['contract_time'] = msg.contract_time;
					str['logistics_money'] = msg.logistics_money;
					str['logistics_way_name'] = msg.logistics_way_name;
					str['record_type'] = msg.record_type;
					if (msg.order_status != "" && msg.order_status != null) {
						var order_str = msg.order_status.split(","); //订单状态
						$.each(lef.orderStatusInfo, function (index, row1) {
							$.each(order_str, function (index2, row2) {
								if (row2 == row1.id) {
									order_status_name += row1.name + " , ";
									return;
								}
							})
						})
					}
					order_status_name = order_status_name.substring(0, order_status_name.length - 2);
					str['order_status'] = order_status_name;
					str['sales_desc'] = msg.sales_desc;
					$.each(lef.tradecurrencyall.crmcurrencylist, function (index3, row3) {
						if (msg.money_type == row3.id) { //总金额单位
							sumMoney_unit = row3.name;
							return;
						}
						if (msg.logistics_money_type == row3.id) { //物流费用单位
							loMoney_unit = row3.name;
							return;
						}
					})
					str['sumMoney_unit'] = sumMoney_unit;
					str['loMoney_unit'] = loMoney_unit;

					//遍历相关产品
					if (msg.customer_field != null && msg.customer_field != "" && msg.customer_field != undefined) {
						var field_str = msg.customer_field.split("@@G@@"); //自定义字段
						$.each(field_str, function (index, row) {
							lef.orderInfo2.push({
								field_name: row.split("###")[0],
								field_value: row.split("###")[1],
							})
						})
					}
					lef.orderInfo1 = str;
					$.each(lef.tradecurrencyall.crmcurrencylist, function (index, row) {
						if (row.id == msg.money_type) {
							r_currency = row.name;
							return false;
						}
					});
					lef.records_data = {
						"indent_type": msg.record_type,
						"compelete_date": msg.contract_time,
						"r_currency": r_currency,
						"compelete_sum": msg.transcation_ammount,
						"buy_num": msg.buy_number,
						"remark": msg.sales_desc
					};
					//lef.showSRI = true;
					lef.cusProDShow = true;
				}
			});
			this.showProductList(4, id);
		},
		//编辑订单
		updateSale(id) {
			this.saleId = id;
			this.showEIL = true;
		},
		//显示关联产品
		showProductList(type, id) {
			var lef = this;
			lef.productList = [];
			lef.orderInfo = [];
			var param = [type, id];
			this.getRelationProduct(param);
			if (this.relationProduct != '' && this.relationProduct.length != 0) {
				//加载货币信息
				if (lef.tradecurrencyall == '') {
					lef.getTradeCurrencyAll();
				}
				//获取所有计量信息
				if (lef.calculateInfo == '') {
					lef.getCalculateAllInfo();
				}
				$.each(lef.relationProduct, function (i, row) {
					var proM_unit = "";
					var proNum_unit = "";

					$.each(lef.tradecurrencyall.crmcurrencylist, function (index, d1) {
						if (row.product_money_type == d1.id) {
							proM_unit = d1.name;
							return;
						}
					})
					$.each(lef.calculateInfo, function (index2, d2) {
						if (row.product_num_type == d2.id) {
							proNum_unit = d2.name;
							return;
						}
					})
					lef.orderInfo.push({
						pro_name: row.product_name,
						product_money: row.product_money,
						proM_unit: proM_unit,
						product_num: row.product_num,
						proNum_unit: proNum_unit,
					})
					lef.productList.push({
						"name": row.product_name,
						"date": row.create_time
					});
				});
				//lef.cusProShow = true;
				if (type != 4) {
					lef.showProduct = true;
				}
			} else {
				lef.$message({
					showClose: true,
					message: '无关联产品信息！',
					type: 'info',
					duration: 2000
				});
			}
		},
		//查看关联文件
		showFileList(id) {
			this.saleId = id;
			this.showFileInfo = true;
		},
		//加载共享列表
		getShareList() {
			var lef = this;
			lef.shareList = [];
			$.ajax({
				type: "POST",
				url: backUrl + "cusCC_findShareInfoByCusid.action",
				async: false,
				cache: false,
				dataType: "json",
				data: {
					token: token,
					pagesize: 10000000,
					currpage: lef.share_currpage,
					customerid: lef.customer_id
				},
				success: function (msg) {
					lef.share_tolpage = msg.pageBean.totalPage; //总页数
					$.each(msg.users, function (index, row) {
						lef.shareList.push({
							"user_id": row.user_id,
							"user_name": row.user_name
						});
					});
				}
			});
			lef.clientList = [];
			var shareArr = [];
			$.each(lef.shareList, function (index, row) {
				shareArr.push(row.user_id);
			});
			//排除已经共享的用户
			$.each(lef.userlistall, function (i, user) {
				if (user.user_id != lef.userinfo.user_id && $.inArray(user.user_id, shareArr) == -1) { //非当前用户
					lef.clientList.push({
						'user_id': user.user_id,
						'name': user.user_name
					});
				}
			});
		},
		//提交共享
		cntConfirmShare(userids) {
			var lef = this;
			$.ajax({
				type: "POST",
				url: backUrl + "cusCC_addorupdateShare.action",
				async: false,
				cache: false,
				dataType: "json",
				data: {
					token: token,
					customerid: lef.customer_id, //客户id
					shareuserid: userids + "," //用户id
				},
				success: function (msg) {
					if (msg == 1) {
						lef.$message({
							showClose: true,
							message: '共享成功！',
							type: 'success',
							duration: 2000
						});
						//之前是非共享则刷新共享数量
						if (lef.shareList.length == 0) {
							//刷新客户列表
							lef.$emit('refreshCusList');
							//重新加载我的共享客户数量
							lef.$store.state.loadCusNumTag = 4;
						}
						lef.getShareList();
					} else {
						lef.$message({
							showClose: true,
							message: '共享失败！',
							type: 'warning',
							duration: 2000
						});
					}
				}
			});
		},
		//取消全部共享
		cancelAll() {
			this.$confirm('确定要取消全部共享吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				closeOnClickModal: false,
				cancelButtonClass: 'MBcancelBtn'
			}).then(() => {
				var lef = this;
				$.ajax({
					type: "POST",
					url: backUrl + "cusCC_updateShareCanel.action",
					async: false,
					cache: false,
					dataType: "json",
					data: {
						token: token,
						customerid: lef.customer_id //客户id
					},
					success: function (msg) {
						if (msg == 1) {
							lef.$message({
								showClose: true,
								message: '取消成功！',
								type: 'success',
								duration: 2000
							});
							lef.getShareList();
							//刷新客户列表
							lef.$emit('refreshCusList');
							//重新加载我的共享客户数量
							lef.$store.state.loadCusNumTag = 4;
						} else {
							lef.$message({
								showClose: true,
								message: '取消失败！',
								type: 'warning',
								duration: 2000
							});
						}
					}
				});
			}).catch(() => { })
		},
		//取消单个共享
		cancelOne(userid, username) {
			this.$confirm('确定要取消对 [' + username + '] 的共享吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				closeOnClickModal: false,
				cancelButtonClass: 'MBcancelBtn'
			}).then(() => {
				var lef = this;
				$.ajax({
					type: "POST",
					url: backUrl + "cusCC_cancelShareInfoByUserid.action",
					async: false,
					cache: false,
					dataType: "json",
					data: {
						token: token,
						upid: userid,
						customerid: lef.customer_id //客户id
					},
					success: function (msg) {
						if (msg == 1) {
							lef.$message({
								showClose: true,
								message: '取消成功！',
								type: 'success',
								duration: 2000
							});
							lef.getShareList();
							//已经取消完了
							if (lef.shareList.length == 0) {
								//刷新客户列表
								lef.$emit('refreshCusList');
								//重新加载我的共享客户数量
								lef.$store.state.loadCusNumTag = 4;
							}
						} else {
							lef.$message({
								showClose: true,
								message: '取消失败！',
								type: 'warning',
								duration: 2000
							});
						}
					}
				});
			}).catch(() => { })
		},
		//上传头像成功
		imageSuccess(imgurl) {
			this.$message({
				showClose: true,
				message: '上传头像成功！',
				type: 'success',
				duration: 2000
			});
			this.customer_img = backUrl + imgurl;
			this.showUpload = false;
			this.$emit('refreshCusList');
		},
		//触发父组件关闭弹窗
		close() {
			this.$emit('closeInfo', false)
		},
		//保存标签管理
		cntConfirm() {
			this.showN = false;
			this.showNFL = false;
			this.showNBL = false;
			this.showNIL = false;
			this.showEIL = false;
			this.showEBL = false;
			this.showFIL = false;
			this.showFRec = false;
			this.showCarryInfo = false;
			this.getChanceList();
		},
		cntConfirmNew() {
			console.log(this.newConnectionTab[0])
		},
		//关闭写信弹窗
		closeMessage() {
			this.showMessage = false;
		},
		//跳转到完整写信
		writeMessages(emails) {
			this.$router.push({
				path: '/mail'
			});
			this.$store.state.mail.wlPagetype = "";
			this.$store.state.mail.wlMailid = 0;
			this.writeLetter({
				name: '写信',
				addressee: emails
			});
			this.showMessage = false; //关闭快捷写信的弹窗
			this.test = false; //关闭客户详细信息弹窗
		},
		...mapActions([
			'writeLetter'
		]),
		//关闭邮件内容弹窗
		closeMessageInfo() {
			this.showMessageInfo = false;
		},
		//跳转到完整写信
		replyMessageInfo(emails, mailid) {
			if (this.userparamlist.param39 == undefined || this.userparamlist.param39 == 0) {
				this.$message({
					showClose: true,
					message: '您还未开通邮件模块！',
					type: 'warning',
					duration: 2000
				});
				return;
			}
			this.$router.push({
				path: '/mail'
			});
			this.$store.state.mail.wlPagetype = "Reply";
			this.$store.state.mail.wlMailid = mailid;
			this.$store.state.is_look = 0;
			this.writeLetter({
				name: '回复',
				addressee: emails
			});
			this.showMessageInfo = false; //关闭邮件信息弹窗
			this.test = false; //关闭客户详细信息弹窗
		},
		closeMessageInfoPost() {
			this.showMessageInfoPost = false;
		},
		//点击打开写信弹窗
		clickMessage(email, linkid, type) {
			if (this.userparamlist.param39 == undefined || this.userparamlist.param39 == 0) {
				this.$message({
					showClose: true,
					message: '您还未开通邮件模块！',
					type: 'warning',
					duration: 2000
				});
				return;
			}
			this.myEmail = email;
			this.linkman_id = linkid;
			this.cusorlink = type;
			this.showMessage = true;
		},
		//图像上传
		uploadClose() {
			this.showUpload = false;
		},
		//点击关闭联系人跟进详细内容
		flInfoClose() {
			this.showFlInfo = false;
		},
		//关闭关联产品弹窗
		proInfoClose() {
			this.showProInfo = false;
		},
		recordInfoClose() {
			this.showRecordInfo = false;
		},
		followInfoClose() {
			this.showFollowInfo = false;
		},
		followRecordsClose() {
			this.showFollowRecords = false;
		},
		showCrm_record() {
			this.showCRI = true;
		},
		//关闭查看商机弹窗
		cntClose() {
			this.showCRI = false;
		},
		//加载云盘剩余空间
		load_residueSpace() {
			//总内存(用户总内存)
			var totalMain = [1, this.userinfo.company_id, this.userinfo.domain];
			this.load_memorySpace(totalMain);
			if (this.result != null) {
				var total = this.result;
				//已用内存(用户已用内存)
				var usedmain = [3, this.userinfo.company_id, this.userinfo.domain];
				this.load_memorySpace(usedmain);
				var used = this.result;
				if (total == -1) {
					this.residueSpace = -1;
				} else {
					this.residueSpace = total - used;
				}

			}
		},
		//判断云盘空间大小
		isSpaceSizeMethod(fileList) {
			var diskSpace = this.residueSpace / 1024 / 1024;
			var self = this;
			var tag = true;
			var file_size2 = 0;
			$.each(fileList, function (index, item) {
				//上传文件大小判断
				file_size2 += item.size;
				if (file_size2 > self.residueSpace) { //判断云盘空间
					self.getFileList(1);
					$("#upload_folder").val('');
					$("#updateFile").val('');
					self.$message({
						showClose: true,
						message: "云盘空间大小已超出，当前所剩云盘空间大小为" + diskSpace.toFixed(2) + "M内",
						type: 'warning',
						duration: 2000
					});
					tag = false;
					return false;
				}
			});
			return tag;
		},
		//判断文件大小
		cus_addFileList(value, fileList) {
			var allFile = [];
			var self = this;
			var byte_size = 0; //字节大小
			var option_file_size = 0; //获取文件的大小
			if (value == 1) { //文件上传
				byte_size = self.upfile_size * 1024 * 1024;
				option_file_size = self.upfile_size;
			} else { //文件夹中的文件
				byte_size = self.upfolder_size_one * 1024 * 1024;
				option_file_size = self.upfolder_size_one;
			}
			$.each(fileList, function (index, item) {
				//上传文件格式判断
				var file_size = item.size;
				if (file_size > byte_size) { //文件大小限制
					self.getFileList(1)
					self.$message({
						showClose: true,
						message: "文件（" + item.name + "）超出了大小限制！请控制在" + option_file_size.toFixed(2) + "M内",
						type: 'warning',
						duration: 2000
					});
					return;
				}
				allFile.push(item);
			});
			return allFile;
		},
		//上传文件
		cus_uploadFile() {
			this.uploadNum = 0;
			this.fileuploadList = [];
			var fileList = [];
			var self = this;
			var files = document.getElementById("updateFile").files;
			if (this.residueSpace == -1) { //判断云盘总空间是否是无限制 -1：无限制
				fileList = this.cus_addFileList(1, files); //判断上传的文件大小
			} else {
				var tag = this.isSpaceSizeMethod(files); //判断云盘剩余空间的大小
				if (tag) {
					fileList = this.cus_addFileList(1, files); //判断上传的文件大小
				}
			}
			$('.cus-it-select1').hide(400);
			if (fileList != '' && fileList != undefined && fileList != null) {
				for (var i = 0, length = fileList.length; i < length; i++) {
					//文件大小
					var fileSize = fileList[i].size / 1024;
					if (fileSize > 1024) { //转为M
						fileSize = (fileSize / 1024).toFixed(2) + "M";
					} else if (fileSize > 1024) {
						fileSize = (fileSize / 1024 / 1024).toFixed(2) + "G";
					} else {
						fileSize = fileSize.toFixed(2) + "KB";
					}
					//截取
					var index1 = fileList[i].name.lastIndexOf(".");
					var index2 = fileList[i].name.length;
					var suffix = fileList[i].name.substring(index1 + 1, index2); //后缀名
					var file_mate, fileType1 = suffix.toLowerCase();
					switch (fileType1) {
						case "txt":
							file_mate = "fl-txt";
							break;
						case "doc":
							file_mate = "fl-doc";
							break;
						case "docx":
							file_mate = "fl-doc";
							break;
						case "dotx":
							file_mate = "fl-doc";
							break;
						case "dotm":
							file_mate = "fl-doc";
							break;
						case "docm":
							file_mate = "fl-doc";
							break;
						case "dot":
							file_mate = "fl-doc";
							break;
						case "wps":
							file_mate = "fl-doc";
							break;
						case "wpt":
							file_mate = "fl-doc";
							break;
						case "vsd":
							file_mate = "fl-doc";
							break;
						case "rtf":
							file_mate = "fl-doc";
							break;
						case "pdf":
							file_mate = "fl-pdf";
							break;
						case "ppt":
							file_mate = "fl-ppt";
							break;
						case "pptx":
							file_mate = "fl-ppt";
							break;
						case "ppsx":
							file_mate = "fl-ppt";
							break;
						case "ppsm":
							file_mate = "fl-ppt";
							break;
						case "pptm":
							file_mate = "fl-ppt";
							break;
						case "potx":
							file_mate = "fl-ppt";
							break;
						case "potm":
							file_mate = "fl-ppt";
							break;
						case "ppam":
							file_mate = "fl-ppt";
							break;
						case "xls":
							file_mate = "fl-xls";
							break;
						case "xlt":
							file_mate = "fl-xls";
							break;
						case "xlsx":
							file_mate = "fl-xls";
							break;
						case "xlsm":
							file_mate = "fl-xls";
							break;
						case "xltx":
							file_mate = "fl-xls";
							break;
						case "xltm":
							file_mate = "fl-xls";
							break;
						case "xlsb":
							file_mate = "fl-xls";
							break;
						case "xlam":
							file_mate = "fl-xls";
							break;
						case "csv":
							file_mate = "fl-xls";
							break;
						case "jpg":
							file_mate = "fl-img";
							break;
						case "gif":
							file_mate = "fl-img";
							break;
						case "png":
							file_mate = "fl-img";
							break;
						case "jpeg":
							file_mate = "fl-img";
							break;
						case "bmp":
							file_mate = "fl-img";
							break;
						case "tif":
							file_mate = "fl-img";
							break;
						case "tiff":
							file_mate = "fl-img";
							break;
						case "ico":
							file_mate = "fl-img";
							break;
						case "webp":
							file_mate = "fl-img";
							break;
						case "fcx":
							file_mate = "fl-img";
							break;
						case "tga":
							file_mate = "fl-img";
							break;
						case "exif":
							file_mate = "fl-img";
							break;
						case "fpx":
							file_mate = "fl-img";
							break;
						case "svg":
							file_mate = "fl-img";
							break;
						case "eps":
							file_mate = "fl-img";
							break;
						case "psd":
							file_mate = "fl-ps";
							break;
						case "md":
							file_mate = "fl-md";
							break;
						case "json":
							file_mate = "fl-md";
							break;
						case "js":
							file_mate = "fl-md";
							break;
						case "less":
							file_mate = "fl-md";
							break;
						case "bat":
							file_mate = "fl-md";
							break;
						case "css":
							file_mate = "fl-md";
							break;
						case "java":
							file_mate = "fl-md";
							break;
						case "jsp":
							file_mate = "fl-md";
							break;
						case "class":
							file_mate = "fl-md";
							break;
						case "vue":
							file_mate = "fl-v";
							break;
						case "exe":
							file_mate = "fl-html";
							break;
						case "htm":
							file_mate = "fl-html";
							break;
						case "html":
							file_mate = "fl-html";
							break;
						case "mmap":
							file_mate = "fl-mmap";
							break;
						case "dmg":
							file_mate = "fl-dmg";
							break;
						case "ai":
							file_mate = "fl-ai";
							break;
						case "zip":
							file_mate = "fl-zip";
							break;
						case "rar":
							file_mate = "fl-zip";
							break;
						case "7z":
							file_mate = "fl-zip";
							break;
						case "mp4":
							file_mate = "fl-video";
							break;
						case "wmv":
							file_mate = "fl-video";
							break;
						case "avi":
							file_mate = "fl-video";
							break;
						case "flv":
							file_mate = "fl-video";
							break;
						case "rmvb":
							file_mate = "fl-video";
							break;
						case "rm":
							file_mate = "fl-video";
							break;
						case "flash":
							file_mate = "fl-video";
							break;
						case "3gp":
							file_mate = "fl-video";
							break;
						case "swf":
							file_mate = "fl-video";
							break;
						case "mp3":
							file_mate = "fl-audio";
							break;
						case "amr":
							file_mate = "fl-audio";
							break;
						case "wma":
							file_mate = "fl-audio";
							break;
						case "wav":
							file_mate = "fl-audio";
							break;
						case "aac":
							file_mate = "fl-audio";
							break;
						case "flac":
							file_mate = "fl-audio";
							break;
						case "ape":
							file_mate = "fl-audio";
							break;
						case "mid":
							file_mate = "fl-audio";
							break;
						case "ogg":
							file_mate = "fl-audio";
							break;
						case "torrent":
							file_mate = "fl-bt";
							break;
						default:
							file_mate = "fl-other";
					}
					this.fileuploadList.unshift({
						file_mate: file_mate,
						name: fileList[i].name,
						size: fileSize,
						type: "--",
						date: "--",
						isShowOperate: true,
						type: 1,
					})
				}
				cusUpload_request();
			}
			$('.cus-it-select1').hide(400); //关闭下拉框
			//上传文件请求后台
			function cusUpload_request() {
				if (self.uploadNum >= fileList.length) { //采用递归的方式循环发送ajax请求
					$("#selectFileBtn").attr("disabled", false).html("选择文件");
					self.getFileList(1)
					self.uploadNum = 0;
					return;
				} else {
					$("#selectFileBtn").attr("disabled", true).html("<i class='el-icon-loading'></i> 正在上传中，请勿刷新……");
				}
				var formData = new FormData();
				formData.append("companyId", self.userinfo.company_id);
				formData.append("domain", self.userinfo.domain);
				formData.append("rootId", -1);
				formData.append("parentId", -1);
				formData.append("customerId", self.customer_id);
				formData.append("customerUserId", self.userId);
				formData.append("from", 4);
				formData.append("file", fileList[self.uploadNum]);
				$.ajax({
					url: diskUrl + 'file/uploadFile',
					type: 'POST',
					crossDomain: true,
					xhrFields: {
						withCredentials: true
					},
					headers: {
						"LoginUserToken": token
					},
					data: formData,
					async: true,
					cache: false,
					contentType: false, //不设置内容类型
					processData: false, //不处理数据
					xhr: function () {
						var xhr = $.ajaxSettings.xhr();
						if (xhr.upload) {
							xhr.upload.onprogress = function (progress) {
								if (progress.lengthComputable) {
									var loaded = progress.loaded / progress.total * 100;
									$(".upload_File .cus-tr[fileCodeId='" + self.uploadNum + "'] .cus_progress>div").css("width", loaded + "%");
								}
							};
						}
						return xhr;
					},
				}).done(function (resp) {
					if (resp.code == 200) {
						self.$message({
							showClose: true,
							message: "上传成功!",
							type: 'success',
							duration: 2000
						});
						$(".upload_File .cus-tr[fileCodeId='" + self.uploadNum + "'] .cus_progress>div").css("width", "100%");
					} else if (resp.code == 300 || resp.code == 100) {
						self.$message({
							showClose: true,
							type: 'warning',
							message: resp.msg,
							duration: 2000
						});
						$(".upload_File .cus-tr[fileCodeId='" + self.uploadNum + "'] .isError").hide();
						$(".upload_File .cus-tr[fileCodeId='" + self.uploadNum + "'] .error_class").html(resp.msg);
					} else {
						$(".upload_File .cus-tr[fileCodeId='" + self.uploadNum + "'] .isError").hide();
						$(".upload_File .cus-tr[fileCodeId='" + self.uploadNum + "'] .error_class").html("上传失败");
					}
					self.uploadNum++; //递归条件
					cusUpload_request();
					$('.cus-it-select1').hide(400);
					$("#updateFile").val("");
				}).fail(function (err) {
					$(".upload_File .cus-tr[fileCodeId='" + self.uploadNum + "'] .isError").hide();
					$(".upload_File .cus-tr[fileCodeId='" + self.uploadNum + "'] .error_class").html("上传失败");
					self.uploadNum++; //递归条件
					cusUpload_request();
					$("#updateFile").val("");
					$('.cus-it-select1').hide(400);
				});
			}
		},
		//判断总的文件大小
		isTotalfileSize(fileList) {
			var self = this;
			var foldersize = 0; //文件夹大小
			var result = true;
			$.each(fileList, function (index, item) {
				foldersize += item.size;
				var byte_size1 = self.folderSize * 1024 * 1024; //总的文件夹
				if (foldersize > byte_size1) {
					$("#upload_folder").val('');
					$("#updateFile").val('');
					self.$message({
						showClose: true,
						message: "文件夹超出了大小限制！请控制在" + self.folderSize.toFixed(2) + "M内",
						type: 'warning',
						duration: 2000
					});
					result = false;
				}
			});
			return result;
		},
		//验证文件夹是否存在
		validate_folderisExist(value) {
			var self = this;
			var result = "";
			var formData = new FormData();
			formData.append("companyId", self.userinfo.company_id);
			formData.append("rootId", -1);
			formData.append("parentId", self.fileid);
			formData.append("customerId", self.customer_id);
			formData.append("fileProps", JSON.stringify(value));
			formData.append("from", 4);
			$.ajax({
				url: diskUrl + 'file/validFolder',
				type: 'POST',
				crossDomain: true,
				xhrFields: {
					withCredentials: true
				},
				headers: {
					"LoginUserToken": token
				},
				data: formData,
				async: false,
				cache: false,
				contentType: false, //不设置内容类型
				processData: false, //不处理数据
				success: function (data) {
					if (data.code == 200) {
						result = data.data;
					} else if (data.code == 100) {
						self.$message({
							showClose: true,
							message: "您未开通网盘,请开通网盘后再继续操作!",
							type: 'warning',
							duration: 2000
						});
					} else if (data.code == 300) {
						self.$message({
							showClose: true,
							message: data.msg,
							type: 'warning',
							duration: 2000
						});
					} else {
						self.$message({
							showClose: true,
							message: "验证失败!",
							type: 'warning',
							duration: 2000
						});
					}
					$("#upload_folder").val('');
					$("#updateFile").val('');
				},
				error: function () {
					self.$message({
						showClose: true,
						message: '上传文件异常，请稍后重试！',
						type: 'warning',
						duration: 2000
					});
				}
			})
			return result;
		},
		//上传文件夹
		cus_uploadFolder() {
			this.uploadNum = 0;
			var fileList = [];
			let lef = this,
				arr = [],
				arr2 = [],
				files = document.getElementById("upload_folder").files;
			if (this.residueSpace == -1) {
				var tag2 = this.isTotalfileSize(files);
				if (tag2) {
					fileList = this.cus_addFileList(2, files);
				}
			} else {
				var tag = this.isSpaceSizeMethod(files); //判断云盘大小
				if (tag) {
					var tag2 = this.isTotalfileSize(files);
					if (tag2) {
						fileList = this.cus_addFileList(2, files);
					}
				}
			}
			$('.cus-it-select1').hide(400);
			var validate_result = "";
			if (fileList != '' && fileList != undefined && fileList != null) {
				for (var i = 0, length = fileList.length; i < length; i++) {
					//文件大小
					var fileSize = fileList[i].size / 1024;
					if (fileSize > 1024) { //转为M
						fileSize = (fileSize / 1024).toFixed(2) + "M";
					} else if (fileSize > 1024) {
						fileSize = (fileSize / 1024 / 1024).toFixed(2) + "G";
					} else {
						fileSize = fileSize.toFixed(2) + "KB";
					}
					//截取
					var index1 = fileList[i].name.lastIndexOf(".");
					var index2 = fileList[i].name.length;
					var suffix = fileList[i].name.substring(index1 + 1, index2); //后缀名
					var file_mate, fileType1 = suffix.toLowerCase();
					switch (fileType1) {
						case "txt":
							file_mate = "fl-txt";
							break;
						case "doc":
							file_mate = "fl-doc";
							break;
						case "docx":
							file_mate = "fl-doc";
							break;
						case "dotx":
							file_mate = "fl-doc";
							break;
						case "dotm":
							file_mate = "fl-doc";
							break;
						case "docm":
							file_mate = "fl-doc";
							break;
						case "dot":
							file_mate = "fl-doc";
							break;
						case "wps":
							file_mate = "fl-doc";
							break;
						case "wpt":
							file_mate = "fl-doc";
							break;
						case "vsd":
							file_mate = "fl-doc";
							break;
						case "rtf":
							file_mate = "fl-doc";
							break;
						case "pdf":
							file_mate = "fl-pdf";
							break;
						case "ppt":
							file_mate = "fl-ppt";
							break;
						case "pptx":
							file_mate = "fl-ppt";
							break;
						case "ppsx":
							file_mate = "fl-ppt";
							break;
						case "ppsm":
							file_mate = "fl-ppt";
							break;
						case "pptm":
							file_mate = "fl-ppt";
							break;
						case "potx":
							file_mate = "fl-ppt";
							break;
						case "potm":
							file_mate = "fl-ppt";
							break;
						case "ppam":
							file_mate = "fl-ppt";
							break;
						case "xls":
							file_mate = "fl-xls";
							break;
						case "xlt":
							file_mate = "fl-xls";
							break;
						case "xlsx":
							file_mate = "fl-xls";
							break;
						case "xlsm":
							file_mate = "fl-xls";
							break;
						case "xltx":
							file_mate = "fl-xls";
							break;
						case "xltm":
							file_mate = "fl-xls";
							break;
						case "xlsb":
							file_mate = "fl-xls";
							break;
						case "xlam":
							file_mate = "fl-xls";
							break;
						case "csv":
							file_mate = "fl-xls";
							break;
						case "jpg":
							file_mate = "fl-img";
							break;
						case "gif":
							file_mate = "fl-img";
							break;
						case "png":
							file_mate = "fl-img";
							break;
						case "jpeg":
							file_mate = "fl-img";
							break;
						case "bmp":
							file_mate = "fl-img";
							break;
						case "tif":
							file_mate = "fl-img";
							break;
						case "tiff":
							file_mate = "fl-img";
							break;
						case "ico":
							file_mate = "fl-img";
							break;
						case "webp":
							file_mate = "fl-img";
							break;
						case "fcx":
							file_mate = "fl-img";
							break;
						case "tga":
							file_mate = "fl-img";
							break;
						case "exif":
							file_mate = "fl-img";
							break;
						case "fpx":
							file_mate = "fl-img";
							break;
						case "svg":
							file_mate = "fl-img";
							break;
						case "eps":
							file_mate = "fl-img";
							break;
						case "psd":
							file_mate = "fl-ps";
							break;
						case "md":
							file_mate = "fl-md";
							break;
						case "json":
							file_mate = "fl-md";
							break;
						case "js":
							file_mate = "fl-md";
							break;
						case "less":
							file_mate = "fl-md";
							break;
						case "bat":
							file_mate = "fl-md";
							break;
						case "css":
							file_mate = "fl-md";
							break;
						case "java":
							file_mate = "fl-md";
							break;
						case "jsp":
							file_mate = "fl-md";
							break;
						case "class":
							file_mate = "fl-md";
							break;
						case "vue":
							file_mate = "fl-v";
							break;
						case "exe":
							file_mate = "fl-html";
							break;
						case "htm":
							file_mate = "fl-html";
							break;
						case "html":
							file_mate = "fl-html";
							break;
						case "mmap":
							file_mate = "fl-mmap";
							break;
						case "dmg":
							file_mate = "fl-dmg";
							break;
						case "ai":
							file_mate = "fl-ai";
							break;
						case "zip":
							file_mate = "fl-zip";
							break;
						case "rar":
							file_mate = "fl-zip";
							break;
						case "7z":
							file_mate = "fl-zip";
							break;
						case "mp4":
							file_mate = "fl-video";
							break;
						case "wmv":
							file_mate = "fl-video";
							break;
						case "avi":
							file_mate = "fl-video";
							break;
						case "flv":
							file_mate = "fl-video";
							break;
						case "rmvb":
							file_mate = "fl-video";
							break;
						case "rm":
							file_mate = "fl-video";
							break;
						case "flash":
							file_mate = "fl-video";
							break;
						case "3gp":
							file_mate = "fl-video";
							break;
						case "swf":
							file_mate = "fl-video";
							break;
						case "mp3":
							file_mate = "fl-audio";
							break;
						case "amr":
							file_mate = "fl-audio";
							break;
						case "wma":
							file_mate = "fl-audio";
							break;
						case "wav":
							file_mate = "fl-audio";
							break;
						case "aac":
							file_mate = "fl-audio";
							break;
						case "flac":
							file_mate = "fl-audio";
							break;
						case "ape":
							file_mate = "fl-audio";
							break;
						case "mid":
							file_mate = "fl-audio";
							break;
						case "ogg":
							file_mate = "fl-audio";
							break;
						case "torrent":
							file_mate = "fl-bt";
							break;
						default:
							file_mate = "fl-other";
					}
					arr.push({
						size: fileList[i].size,
						webkitRelativePath: fileList[i].webkitRelativePath
					})
					arr2.push({
						file_mate: file_mate,
						name: fileList[i].name,
						size: fileSize,
						type: "--",
						date: "--",
						isShowOperate: true,
						type: 1,
					})

				}
				validate_result = this.validate_folderisExist(arr);
				if (validate_result != "" && validate_result != null) {
					this.fileuploadList = arr2
					cus_uploadFolder();
				}
			}
			$('.cus-it-select1').hide(400);
			//上传文件夹提交
			function cus_uploadFolder() {
				if (lef.uploadNum >= fileList.length) { //采用递归的方式循环发送ajax请求
					lef.getFileList(1)
					$("#selectFileBtn").attr("disabled", false).html("选择文件");
					lef.uploadNum = 0;
					return;
				} else {
					$("#selectFileBtn").attr("disabled", true).html("<i class='el-icon-loading'></i> 正在上传中，请勿刷新……");
				}
				//上传文件
				let formData = new FormData();
				formData.append("companyId", lef.userinfo.company_id);
				formData.append("domain", lef.userinfo.domain);
				formData.append("customerId", lef.customer_id);
				formData.append("folderProps", JSON.stringify(validate_result));
				formData.append("file", fileList[lef.uploadNum]);
				formData.append("webkitPath", fileList[lef.uploadNum].webkitRelativePath);
				formData.append("from", 4);

				$.ajax({
					url: diskUrl + 'file/uploadFolder',
					type: 'POST',
					crossDomain: true,
					xhrFields: {
						withCredentials: true
					},
					headers: {
						"LoginUserToken": token
					},
					data: formData,
					async: true,
					cache: false,
					contentType: false, //不设置内容类型
					processData: false, //不处理数据
					xhr: function () {
						var xhr = $.ajaxSettings.xhr();
						if (xhr.upload) {
							xhr.upload.onprogress = function (progress) {
								if (progress.lengthComputable) {
									var loaded = progress.loaded / progress.total * 100;
									$(".upload_File .cus-tr[fileCodeId='" + lef.uploadNum + "'] .cus_progress>div").css("width", loaded + "%");
								}
							};
						}
						return xhr;
					},
				}).done(function (resp) {
					if (resp.code == 200) {
						lef.$message({
							showClose: true,
							message: "上传成功!",
							type: 'success',
							duration: 2000
						});
						$(".upload_File .cus-tr[fileCodeId='" + lef.uploadNum + "'] .cus_progress>div").css("width", "100%");
					} else if (resp.code == 300 || resp.code == 100) {
						lef.$message({
							showClose: true,
							type: 'warning',
							message: resp.msg,
							duration: 2000
						});
						$(".upload_File .cus-tr[fileCodeId='" + lef.uploadNum + "'] .error_class").html(resp.msg);
					} else {
						$(".upload_File .cus-tr[fileCodeId='" + lef.uploadNum + "'] .error_class").html("上传失败");
					}
					lef.uploadNum++; //递归条件
					cus_uploadFolder();
					$('.cus-it-select1').hide(400);
					$("#upload_folder").val('');
					$("#updateFile").val('');
					lef.clearInputFile = true;
				}).fail(function (err) {
					lef.$message({
						showClose: true,
						message: '上传文件异常！',
						type: 'warning'
					});
					$(".upload_File .cus-tr[fileCodeId='" + lef.uploadNum + "'] .error_class").html("上传失败");
					$("#upload_folder").val('');
					$("#updateFile").val('');
					lef.clearInputFile = true;
				});
			}

		},
		//上传下拉
		selectShow(event) {
			//判断是否上传中
			this.indexNum = 0;
			var select = $(event.currentTarget).siblings('.cus-it-select1');
			var selects = $(event.currentTarget).parent().siblings().children('.cus-it-select1');
			//清空
			$(".acus-operate input").val('');
			selects.hide(400);
			select.show(400);
		},

		//获取客户背景和采购数据信息
		getBgAndPurchaseInfo() {
			var self = this;
			$.ajax({
				type: "POST",
				url: backUrl + "cus_findCustomerInfo.action",
				dataType: "json",
				async: true,
				cache: false,
				beforeSend: function () {
					self.autographLoading = true;
				},
				complete: function () {
					self.autographLoading = false;
				},
				data: {
					token: token,
					customer_id: self.customer_id
				},
				success: function (msg) {
					//成立时间
					var build_time = "";
					if (msg.year_established != "" && msg.year_established != null && msg.year_established != undefined) {
						build_time = msg.year_established;
					}
					//商业类型
					var business_type = "";
					if (msg.business_type != "" && msg.business_type != null && msg.business_type != undefined) {
						business_type = msg.business_type;
					}
					//销售平台
					var sales_platform = "";
					if (msg.platforms_selling != "" && msg.platforms_selling != null && msg.platforms_selling != undefined) {
						sales_platform = msg.platforms_selling;
					}
					//主营产品
					var main_pro = "";
					if (msg.main_products != "" && msg.main_products != null && msg.main_products != undefined) {
						main_pro = msg.main_products;
					}
					//经营地址
					var business_addr = "";
					if (msg.operational_address != "" && msg.operational_address != null && msg.operational_address != undefined) {
						business_addr = msg.operational_address;
					}
					//公司简介(关于我们)
					var about_us = "";
					if (msg.about_us != "" && msg.about_us != null && msg.about_us != undefined) {
						about_us = msg.about_us;
					}
					//年采购额
					var purchase_count = "";
					if (msg.annual_purchasing_volume != "" && msg.annual_purchasing_volume != null && msg.annual_purchasing_volume != undefined) {
						purchase_count = msg.annual_purchasing_volume;
					}
					//采购目的
					var purchase_purpose = "";
					if (msg.primary_sourcing_purpose != "" && msg.primary_sourcing_purpose != null && msg.primary_sourcing_purpose != undefined) {
						purchase_purpose = msg.primary_sourcing_purpose;
					}
					//平均采购频率
					var purchase_frequency = "";
					if (msg.average_sourcing_frequency != "" && msg.average_sourcing_frequency != null && msg.average_sourcing_frequency != undefined) {
						purchase_frequency = msg.average_sourcing_frequency;
					}
					//偏好供应商类型
					var supplier_type = "";
					if (msg.preferred_supplier_qualifications != "" && msg.preferred_supplier_qualifications != null && msg.preferred_supplier_qualifications != undefined) {
						supplier_type = msg.preferred_supplier_qualifications;
					}
					//偏好行业
					var preferences_industry = "";
					if (msg.preferred_industries != "" && msg.preferred_industries != null && msg.preferred_industries != undefined) {
						preferences_industry = msg.preferred_industries;
					}
					self.backgroundInfos['purchase_count'] = purchase_count; //年采购额
					self.backgroundInfos['purchase_purpose'] = purchase_purpose; //采购目的
					self.backgroundInfos['purchase_frequency'] = purchase_frequency; //平均采购频率
					self.backgroundInfos['supplier_type'] = supplier_type; //偏好供应商类型
					self.backgroundInfos['preferences_industry'] = preferences_industry; //偏好行业
					self.backgroundInfos['build_time'] = build_time; //成立时间
					self.backgroundInfos['business_type'] = business_type; //商业类型
					self.backgroundInfos['sales_platform'] = sales_platform; //销售平台
					self.backgroundInfos['main_pro'] = main_pro; //主营产品
					self.backgroundInfos['business_addr'] = business_addr; //经营地址
					self.backgroundInfos['about_us'] = about_us; //公司简介(关于我们)

					//获取客户的采购数据信息
					//加入阿里时间
					self.joinAliTime = "";
					if (msg.joined_alibaba_time != "" && msg.joined_alibaba_time != null && msg.joined_alibaba_time != undefined) {
						self.joinAliTime = msg.joined_alibaba_time;
					}
					//询盘产品
					self.xunpanPro = "";
					if (msg.inquiries_product != "" && msg.inquiries_product != null && msg.inquiries_product != undefined) {
						self.xunpanPro = msg.inquiries_product;
					}
					//注册年限
					var register_year = "";
					if (msg.registered_years != "" && msg.registered_years != null && msg.registered_years != undefined) {
						register_year = msg.registered_years;
					}
					//登录天数
					var login_day = "";
					if (msg.days_signed_in != "" && msg.days_signed_in != null && msg.days_signed_in != undefined) {
						login_day = msg.days_signed_in;
					}
					//产品浏览数
					var pro_view = "";
					if (msg.product_views != "" && msg.product_views != null && msg.product_views != undefined) {
						pro_view = msg.product_views;
					}
					//搜索次数
					var search_count = "";
					if (msg.searches != "" && msg.searches != null && msg.searches != undefined) {
						search_count = msg.searches;
					}
					//有效询盘数/回复询盘数
					var valid_xunpan_count = "";
					if (msg.valid_inquiries_sentTo != "" && msg.valid_inquiries_sentTo != null && msg.valid_inquiries_sentTo != undefined) {
						valid_xunpan_count = msg.valid_inquiries_sentTo;
					}
					//被标为垃圾询盘数
					var rubbish_xunpan_count = "";
					if (msg.spam_inquiries != "" && msg.spam_inquiries != null && msg.spam_inquiries != undefined) {
						rubbish_xunpan_count = msg.spam_inquiries;
					}
					//有效RFQ数
					var RFQ_count = "";
					if (msg.valid_rfqs_submitted != "" && msg.valid_rfqs_submitted != null && msg.valid_rfqs_submitted != undefined) {
						RFQ_count = msg.valid_rfqs_submitted;
					}
					//收到报价数/查看报价数
					var offer_count = "";
					if (msg.quotations_receivedRead != "" && msg.quotations_receivedRead != null && msg.quotations_receivedRead != undefined) {
						offer_count = msg.quotations_receivedRead;
					}
					//被添加联系人数
					var add_concact_count = "";
					if (msg.listed_contact != "" && msg.listed_contact != null && msg.listed_contact != undefined) {
						add_concact_count = msg.listed_contact;
					}
					//被添加黑名单数
					var add_blacklist = "";
					if (msg.add_blacklist != "" && msg.add_blacklist != null && msg.add_blacklist != undefined) {
						add_blacklist = msg.add_blacklist;
					}
					//最近搜索词
					var recent_searches = "";
					if (msg.recent_searches != "" && msg.recent_searches != null && msg.recent_searches != undefined) {
						recent_searches = msg.recent_searches;
					}
					//最常采购行业
					var re_purchase_industry = "";
					if (msg.most_sourcing_industries != "" && msg.most_sourcing_industries != null && msg.most_sourcing_industries != undefined) {
						re_purchase_industry = msg.most_sourcing_industries;
					}
					var latest_buying_requests = "";
					//最新采购需求
					if (msg.latest_buying_requests != "" && msg.latest_buying_requests != null && msg.latest_buying_requests != undefined) {
						latest_buying_requests = msg.latest_buying_requests;
					}
					//询盘产品图片
					if (msg.latest_inquiries != "" && msg.latest_inquiries != null && msg.latest_inquiries != undefined) {
						self.imgList = msg.latest_inquiries.split("###");
					}
					self.purchaseInfos['register_year'] = register_year; //注册年限
					self.purchaseInfos['login_day'] = login_day; //登录天数
					self.purchaseInfos['pro_view'] = pro_view; //产品浏览数
					self.purchaseInfos['search_count'] = search_count; //搜索次数
					self.purchaseInfos['valid_xunpan_count'] = valid_xunpan_count; //有效询盘数/回复询盘数
					self.purchaseInfos['rubbish_xunpan_count'] = rubbish_xunpan_count; //被标为垃圾询盘数
					self.purchaseInfos['RFQ_count'] = RFQ_count; //有效RFQ数
					self.purchaseInfos['offer_count'] = offer_count; //收到报价数/查看报价数
					self.purchaseInfos['add_concact_count'] = add_concact_count; //被添加联系人数
					self.purchaseInfos['add_blacklist'] = add_blacklist; //被添加黑名单数
					self.purchaseInfos['recent_searches'] = recent_searches; //最近搜索词
					self.purchaseInfos['re_purchase_industry'] = re_purchase_industry; //最常采购行业
					self.purchaseInfos['latest_buying_requests'] = latest_buying_requests;//最新采购需求

				}
			});
		},
		//根据用户id获取邮箱
		loadMailAccountByID() {
			var self = this;
			self.getAllLinkMan();
			self.getMailAccountById({
				userId: self.userId
			})
			self.mailAccountList = self.team_mailAccount.resultlist;
			var blog = false;
			if (self.mailAccountList.length != 0 && self.mailAccountList != "" && self.mailAccountList != null) {
				$.each(self.mailAccountList, function (i, row) {
					if (row.defalut_show == 1) {   //设为默认
						blog = true;
						self.cus_selectMailAccount = row.account_id;
						self.cus_mailAccount = row.mail_account;
						self.cus_mailAccountId = row.account_id;
						self.cus_poporimap = row.poporimap;
					} else if (row.is_default == 0) {
						blog = true;
						self.cus_selectMailAccount = row.account_id;
						self.cus_mailAccount = row.mail_account;
						self.cus_mailAccountId = row.account_id;
						self.cus_poporimap = row.poporimap;
					}
				})
				if (blog) {
					if (self.emailShow) { //加载收件箱
						self.getRMailList();
					} else { //加载发件箱
						self.getSMailList();
					}
				} else {
					self.cus_selectMailAccount = self.mailAccountList[0].account_id;
					self.$message({
						showClose: true,
						message: '此用户没有主邮箱账号！',
						type: 'warning'
					});
					self.rmailList = [];
					self.smailList = [];
				}
			} else {
				self.rmailList = [];
				self.smailList = [];
			}
		},

		//根据邮箱来查询
		cusMailAccountChange() {
			var self = this;
			$.each(this.mailAccountList, function (i, row) {
				if (self.cus_selectMailAccount == row.account_id) { //默认选中主邮箱账号
					self.cus_mailAccount = row.mail_account;
					self.cus_mailAccountId = row.account_id;
					self.cus_poporimap = row.poporimap;
				}
			})
			if (self.emailShow) { //加载收件箱
				self.getRMailList();
			} else { //加载发件箱
				self.getSMailList();
			}
		},
		//编辑背景
		editCusType1Open(){
			this.editCusType1 = true;
		},
		//编辑背景提交
		editCusType1Submit(){
			let self = this;
			$.ajax({
				type: "POST",
				url: backUrl + "cus_updateCustomerInfo1.action",
				async: false,
				cache: false,
				data: {
					token: token,
					customer_id: self.customer_id,
					"info.year_established": self.backgroundInfos.build_time,
					"info.business_type": self.backgroundInfos.business_type,
					"info.platforms_selling": self.backgroundInfos.sales_platform,
					"info.main_products": self.backgroundInfos.main_pro,
					"info.operational_address": self.backgroundInfos.business_addr,
					"info.about_us": self.backgroundInfos.about_us,
					"info.annual_purchasing_volume": self.backgroundInfos.purchase_count,
					"info.primary_sourcing_purpose": self.backgroundInfos.purchase_purpose,
					"info.average_sourcing_frequency": self.backgroundInfos.purchase_frequency,
					"info.preferred_supplier_qualifications": self.backgroundInfos.supplier_type,
					"info.preferred_industries": self.backgroundInfos.preferences_industry,
				},
				success: function (msg) {
					if (msg == 1) {
						self.$message({
							showClose: true,
							message: '提交成功！',
							type: 'success'
						});
						self.getBgAndPurchaseInfo();
						self.editCusType1 = false;
					}else{
						self.$message({
							showClose: true,
							message: '提交失败！',
							type: 'warning'
						});
					}
				}
			});
		},
		//编辑采购数据
		editCusType2Open(){
			this.editCusType2 = true;
		},
		//编辑采购数据提交
		editCusType2Submit(){
			let self = this;
			var latest_inquiries = "";
			if(self.imgList.length > 0){
				for(var i = 0;i < self.imgList.length;i++){
					latest_inquiries += self.imgList[i] + "###";
				}
				latest_inquiries = latest_inquiries.substring(0,latest_inquiries.length-3);
			}
			$.ajax({
				type: "POST",
				url: backUrl + "cus_updateCustomerInfo2.action",
				async: false,
				cache: false,
				data: {
					token: token,
					customer_id: self.customer_id,
					"info.joined_alibaba_time": self.joinAliTime,
					"info.Inquiries_product": self.xunpanPro,
					"info.registered_years": self.purchaseInfos.register_year,
					"info.days_signed_in": self.purchaseInfos.login_day,
					"info.product_views": self.purchaseInfos.pro_view,
					"info.searches": self.purchaseInfos.search_count,
					"info.valid_inquiries_sentTo": self.purchaseInfos.valid_xunpan_count,
					"info.spam_inquiries": self.purchaseInfos.rubbish_xunpan_count,
					"info.valid_rfqs_submitted": self.purchaseInfos.RFQ_count,
					"info.quotations_receivedRead": self.purchaseInfos.offer_count,
					"info.listed_contact": self.purchaseInfos.add_concact_count,
					"info.add_blacklist": self.purchaseInfos.add_blacklist,
					"info.recent_searches": self.purchaseInfos.recent_searches,
					"info.most_sourcing_industries": self.purchaseInfos.re_purchase_industry,
					"info.latest_buying_requests": self.purchaseInfos.latest_buying_requests,
					"info.latest_inquiries": latest_inquiries,
					
				},
				success: function (msg) {
					if (msg == 1) {
						self.$message({
							showClose: true,
							message: '提交成功！',
							type: 'success'
						});
						self.getBgAndPurchaseInfo();
						self.editCusType2 = false;
					}else{
						self.$message({
							showClose: true,
							message: '提交失败！',
							type: 'warning'
						});
					}
				}
			});
		},
		//点击上传采购图片
		uploadCaiGouPic(){
			this.uploadCaiGouTag = true;
		},
		//提交上传采购图片
		uploadCaiGouPicSubmit(){
    		//判断上传文件类型
			var filename=$("#cus_imgid").val();
			if(filename==null||filename==""){
				this.$message({
				   showClose: true,
				   message: '请选择图片！',
				   type: 'warning',
				   duration:2000
				});
				return false;
			}
			var type=filename.substring(filename.lastIndexOf("\.")+1,filename.length);
			if (type.toUpperCase()!="GIF"&&type.toUpperCase()!="JPG"&&type.toUpperCase()!="PNG"){
				this.$message({
				   showClose: true,
				   message: '图片格式不支持,只支持GIF、JPG、PNG！',
				   type: 'warning',
				   duration:2000
				});
				return false;
			}
			var lef = this;
			var option = {
				url: backUrl + 'cus_uploadImg.action?token='+token,
				dataType: 'json',
				type:'POST',
				async: false,
				data: {
					customerFileName:$("#cus_imgid").val(),
					customerid:"0"
				},
				success: function (msg) {
					if(msg!=null&&msg!=""){
						lef.uploadCaiGouTag = false;
						lef.imgList.push(backUrl+msg);
					}else{
						lef.$message({
							showClose: true,
						  	message: '上传头像失败！',
						    type: 'warning',
						    duration:2000
						});
					}
				}
			};
			myAjaxSubmit('cus_imgForm',option);
		},
		//删除采购图片
		deleteCaiGouPic(index){
			this.imgList.splice(index,1);
		},
		//判断阿里巴巴插件是否开通
		checkAlibabaOpen(upId){
			let self = this;
			$.ajax({
				type: "POST",
				url: backUrl + "cus_checkAlibabaOpen.action",
				async: true,
				cache: false,
				data: {
					token: token,
					upid: upId,
				},
				success: function (msg) {
					if(msg == 1){
						self.isAlibabaOpen = true;
					}else{
						self.isAlibabaOpen = false;
					}
				}
			});
		},
	},
	filters: {
		time(input) {
			var d = new Date(input);
			var year = d.getFullYear();
			var month = d.getMonth() + 1;
			var day = d.getDate() < 10 ? '0' + d.getDate() : '' + d.getDate();
			var hour = d.getHours();
			var minutes = d.getMinutes();
			var seconds = d.getSeconds();
			return year + '-' + month + '-' + day + ' ' + hour + ':' + minutes + ':' + seconds;
		}
	},
	computed: {
		...mapState({
			userinfo: state => state.userinfo,
			customerInformation: state => state.customerInformation,
			userlistall: state => state.userlistall,
			customerclassifyall: state => state.customerclassifyall,
			customerfromall: state => state.customerfromall,
			customerrelationall: state => state.customerrelationall,
			customerstatusall: state => state.customerstatusall,
			industryType: state => state.hangyeArr,
			majorproductall: state => state.majorproductall,
			followupwayall: state => state.followupwayall,
			saleprocessall: state => state.saleprocessall,
			tradecurrencyall: state => state.tradecurrencyall,
			loadCusNumTag: state => state.loadCusNumTag,
			userparamlist: state => state.userparamlist,
			companyparamlist: state => state.companyparamlist,
			relationProduct: state => state.relationProduct,
			planOrRecordStatus: state => state.planOrRecordStatus,
			uploadtype: state => state.fileUpdate.uploadtype,
			calculateInfo: state => state.calculateInfo,
			orderStatusInfo: state => state.orderStatusInfo,
			folderid: state => state.fileUpdate.folderid,
			result: state => state.fileUpdate.result,
			team_mailAccount: state => state.team_mailAccount,
			plicencefun: state => state.plicencefun,
			countryArr: state => state.countryArr,
		})
	},
	mounted() {
		var self = this;
		//加载所有用户
		if (this.userlistall == '') {
			this.getUserlistAll();
		}
		$.each(this.userlistall, function (i, row) {
			if (self.userId == row.user_id) {
				self.followman = row.user_name;
			}
		})
		//加载客户分类信息
		if (this.customerclassifyall == '') {
			this.getCustomerClassifyAll();
		}
		//加载客户来源信息
		if (this.customerfromall == '') {
			this.getCustomerFromAll();
		}
		//加载客户关系信息
		if (this.customerrelationall == '') {
			this.getCustomerRelationAll();
		}
		//加载客户状态
		if (this.customerstatusall == '') {
			this.getCustomerStatusAll();
		}
		//加载意向产品信息
		if (this.majorproductall == '') {
			this.getMajorProductAll();
		}
		var self = this;
		//加载销售进程
		if (self.saleprocessall == '') {
			self.getSaleProcessAll();
		}
		self.dynamicSaleProcess.push({
			"id": 0,
			"name": "销售进程",
			"name1": "销售进程"
		});
		$.each(self.saleprocessall, function (index, row) {
			var name1 = row.name;
			if (name1.length > 6) {
				name1 = name1.substring(0, 6) + "...";
			}
			self.dynamicSaleProcess.push({
				"id": row.id,
				"name": row.name,
				"name1": name1
			});
		});
		//加载跟进方式
		if (self.followupwayall == '') {
			self.getFollowupWayAll();
		}
		self.dynamicFollowWay.push({
			"id": 0,
			"name": "跟进方式",
			"name1": "跟进方式"
		});
		$.each(self.followupwayall, function (index, row) {
			var name1 = row.name;
			if (name1.length > 6) {
				name1 = name1.substring(0, 6) + "...";
			}
			self.dynamicFollowWay.push({
				"id": row.id,
				"name": row.name,
				"name1": name1
			});
		});
		this.getCustomerInformation(this.customer_id); //加载客户信息
		this.chooseProid = this.customerInformation.prductid;
		this.initCustomerInfo(); //初始化客户信息
		this.showDynamic(); //加载动态数据
		this.checkAlibabaOpen(this.customerInformation.user_id);
		//获取客户附件的上传文件夹所属文件夹
		this.findCusFolderid({
			companyId: this.userinfo.company_id,
			customerId: this.customer_id,
			userId: this.userId
		})
		this.fileid = this.folderid.data;
		//判断当前路径
		this.isGiveMe = true;
		if (this.$route.path == '/crm/allClientOrigin_colleagueShare' || this.$route.path == '/team/customerShare') {
			this.isGiveMe = false;
		}
		function clearTransform() {
			$('.el-dialog').css({
				'transform': 'none',
			})
		};
		dialogDrag();
		clearTransform(dialogCenter());

		$(document).click(function (e) {
			var target = $(e.target);
			if (!target.is('.cus-it-drop1 *')) {
				$('.cus-it-select1').hide(400)
			}
		});
	},
	watch: {
		dynamicType() {
			if (this.dynamicType == 0) { //动态
				this.showDynamic();
			} else if (this.dynamicType == 1) { //跟进记录
				this.showFollowupRecord();
			} else if (this.dynamicType == 2) { //生命线
				this.showLifeRecycle();
			}
		},
		activeName(newv, oldv) {
			var lef = this;
			if (newv == 'third') { //加载联系人列表
				this.getLinkManList()
			} else if (newv == 'fiveth') { //加载跟进列表
				if (this.followupwayall == '') {
					this.getFollowupWayAll();
				}
				lef.followupWayList = [];
				lef.followupWayList.push({
					"id": 0,
					"name": "联系工具",
					"name1": "联系工具"
				});
				$.each(lef.followupwayall, function (index, row) {
					var name1 = row.name;
					if (name1.length > 6) {
						name1 = name1.substring(0, 6) + "...";
					}
					lef.followupWayList.push({
						"id": row.id,
						"name": row.name,
						"name1": name1
					});
				});
				if (this.planShow) { //显示计划
					this.getFollowPlanList();
					$.each(lef.planOrRecordStatus, function (i, row) {
						if (row.typeid < 2) {
							lef.planTypes.push({
								typeid: row.typeid,
								name: row.name,
							})
						}
					});
				} else { //显示记录
					this.getFollowRecordList();
				}
			} else if (newv == 'seventh') { //加载商机列表
				this.getChanceList();
			} else if (newv == 'eighth') { //加载订单列表
				this.getSaleRecordList();
			} else if (newv == 'sixth') { //加载邮件列表
				this.loadMailAccountByID();
				//if(this.emailShow) { //显示收件箱列表
				//	this.getRMailList();
				//} else { //显示发件箱列表
				//	this.getSMailList();
				//}
			} else if (newv == 'nineth') { //加载共享列表
				this.getShareList();
			} else if (newv == "first") {
				this.dynamicType = 0;
				this.showDynamic();
			} else if (newv == 'tenth') {
				this.getFileList(1);
				this.load_residueSpace();
			} else if (newv == 'eleven' || newv == 'twelve') { //背景和采购数据
				this.getBgAndPurchaseInfo();
			}
		},
		planShow() {
			this.recordTypes = [];
			if (this.planShow) { //加载跟进计划列表
				this.getFollowPlanList();
				this.followupType = -1;
			} else { //加载跟进记录列表
				this.getFollowRecordList();
				this.followupType = 2;
				var lef = this;
				$.each(lef.planOrRecordStatus, function (i, row) {
					if (row.typeid >= 2) {
						lef.recordTypes.push({
							typeid: row.typeid,
							name: row.name,
						})
					}
				});
			}
		},
		wayId(newv, oldv) {
			if (this.planShow) { //加载跟进计划列表
				this.getFollowPlanList();
			} else { //加载跟进记录列表
				this.getFollowRecordList();
			}
		},
		planType() {
			this.getFollowPlanList();
		},
		recordType() {
			this.getFollowRecordList();
		},
		emailShow(newVal) {
			if (newVal) { //加载收件箱
				this.getRMailList();
			} else { //加载发件箱
				this.getSMailList();
			}
		}
	}
}