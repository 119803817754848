<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：客户·客户详细信息·新建商机弹窗
开始时间：2017-11-9
开发人员：陈立珍
最后修改：2018-11-29
备注说明：如需修改请联系开发人员
-->

<template>
  <div>
    <table class="crm-tab2" width="100%" cellpadding="6" cellspacing="8">
      <tr>
        <td class="cu_tbg">
          <span>机会来源</span>
        </td>
        <td>
          <select v-model="fromid">
            <option value="0">请选择</option>
            <option
              v-for="from in chanceSourceList"
              :value="from.id"
              :title="from.name"
            >{{from.name1}}</option>
          </select>
        </td>
        <td class="cu_tbg">
          <span>机会可能性</span>
        </td>
        <td>
          <select v-model="star">
            <option value="1">一星</option>
            <option value="2">二星</option>
            <option value="3">三星</option>
            <option value="4">四星</option>
            <option value="5">五星</option>
          </select>
        </td>
      </tr>
      <tr>
        <td class="cu_tbg">
          <span>预成交时间</span>
        </td>
        <td>
          <el-date-picker v-model="dateValue" type="date" :editable="false"></el-date-picker>
        </td>
        <td class="cu_tbg">
          <span>成交币种</span>
        </td>
        <td>
          <select v-model="currencyid">
            <option value="0">请选择</option>
            <option
              v-for="currency in currencyList"
              :value="currency.id"
              :title="currency.name"
            >{{currency.name1}}</option>
          </select>
        </td>
      </tr>
      <tr>
        <td class="cu_tbg">
          <span>预成交金额</span>
        </td>
        <td>
          <input type="number" v-model="value1" class="money-input" />
        </td>
        <td class="cu_tbg">
          <span>当前状态</span>
        </td>
        <td>
          <select v-model="status">
            <option value="进行中">进行中</option>
            <option value="完成">完成</option>
            <option value="挂起">挂起</option>
            <option value="放弃">放弃</option>
          </select>
        </td>
      </tr>
      <tr>
        <td class="cu_tbg">
          <span>关联产品</span>
        </td>
        <!-- <td><span @click="selectProduct" class="pSelect" title="选择关联产品">{{productNum==0?'请选择':'已选择'+productNum+'个产品'}}</span></td> -->
        <!--<td v-if="isBusinessInfo"><span @click="selectProduct" class="pSelect" title="查看意向产品">查看</span></td>-->
        <td>
          <com-product :choiceProductArr="productids3" @cofirmProduct="cofirmProduct"></com-product>
        </td>
      </tr>
      <tr>
        <td class="cu_tbg">
          <span>备注信息</span>
        </td>
        <td colspan="3">
          <textarea v-model="remark" cols="30" rows="10" style="width: 500px;" class="f-content"></textarea>
        </td>
      </tr>
    </table>
    <!-- 选择关联产品 -->
    <!--<small-layer class="smallLayer" v-if="showC" 
			@cntClose="showC = false" @cntConfirm="cntConfirm" 
			:showType="4" :productIds="productids" 
			:productids2="productids2" :connect-tab="false"
    cnt-title="选择关联产品" :has-button="true"></small-layer>-->

    <!-- <relatedProduct v-if="showC" :productids2="productids3" :productType="5" @closePro="showC = false" @cntConfirm="cntConfirm"></relatedProduct> -->
  </div>
</template>
<script>
import store from "../../../vuex/store.js";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import "../../../vuex/mutations.js";
import smallLayer from "./smallLayer.vue";
import relatedProduct from "./layer_related_product.vue";
import comProduct from "@/components/common_file/com_product/comProduct";

export default {
  name: "newFollow",
  props: ["isBusinessInfo", "chanceId"],
  data() {
    return {
      fromid: 0, //客户来源id
      star: 1, //机会可能性
      dateValue: "", //预成交时间
      currencyid: 0, //币种id
      status: "进行中", //当前状态
      value1: "", //金额
      remark: "", //备注信息
      productids: "", //选择的关联产品id,多个以逗号分割
	  productids2: "",
	  productids3:'',//选择的关联产品id,多个以逗号分割（使用组件的方式获取）
      productNum: 0, //选择的关联产品数量
      createDate: "",

      showC: false,
      pickerOptions1: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            }
          }
        ]
      },
      chanceSourceList: [], //机会来源
      currencyList: [] //成交币种
    };
  },
  components: {
    "small-layer": smallLayer,
    relatedProduct: relatedProduct,
    comProduct
  },
  computed: {
    ...mapState({
      customerfromall: state => state.customerfromall,
      tradecurrencyall: state => state.tradecurrencyall,
      relationProduct: state => state.relationProduct,
    })
  },
  methods: {
    ...mapMutations({
      getCustomerFromAll: "getCustomerFromAll",
      getTradeCurrencyAll: "getTradeCurrencyAll",
      getRelationProduct: "getRelationProduct",
      updateFollowcount : "updateFollowcount",
    }),
    // //确认关联产品
    cntConfirm(proids) {
      var pro_ids = "";
      $.each(proids, function(index, row) {
        pro_ids += row.id + ",";
      });
      pro_ids = pro_ids.substring(0, pro_ids.length - 1);
      this.productids = pro_ids;
      if (proids != "") {
        this.productNum = this.productids.split(",").length;
      } else {
        this.productNum = 0;
      }
      //弹窗选中
      var production_value = "";
      $.each(proids, function(i, row) {
        var pid = row.id;
        var pname = row.name;
        production_value += pid + "###" + pname + ",";
      });
      production_value = production_value.substring(
        0,
        production_value.length - 1
      );
      this.productids2 = production_value;
	},
	//使用组件的方式获取返回来的产品id
	cofirmProduct(proids){
		this.productids3 =proids
	},
    //添加商机提交
    subButton(customerids) {
      if (this.value1 == "" || parseFloat(this.value1) < 0) {
        this.$message({
          showClose: true,
          message: "预成交金额只能填写大于0！",
          type: "warning",
          duration: 2000
        });
        return;
      }
      if (this.value1.length > 9) {
        this.$message({
          showClose: true,
          message: "预成交金额不能超过9个字符！",
          type: "warning",
          duration: 2000
        });
        return;
      }
      if (this.dateValue == "") {
        this.$message({
          showClose: true,
          message: "请选择预成交时间！",
          type: "warning",
          duration: 2000
        });
        return;
      }
      //转化预成交时间
      var myDate = new Date(this.dateValue);
      var year = myDate.getFullYear().toString();
      var month = (myDate.getMonth() + 1).toString();
      var day = myDate.getDate().toString();
      if (month.length == 1) {
        month = "0" + month;
      }
      if (day.length == 1) {
        day = "0" + day;
      }
      this.dateValue = year + "-" + month + "-" + day;
	  var lef = this;
      if (lef.chanceId > 0) {
        //编辑
        $.ajax({
          type: "POST",
          async: false,
          cache: false,
          url: backUrl + "cusSC_editSaleschance.action",
          data: {
            token: token,
            productids: this.commonJs.returnString(this.productids3),
            customer_ids: customerids,
            "saleschance.create_date": lef.createDate,
            "saleschance.id": lef.chanceId,
            "saleschance.source_from_id": lef.fromid,
            "saleschance.chance_level": lef.star,
            "saleschance.pre_execute_time": lef.dateValue,
            "saleschance.money_type": lef.currencyid,
            "saleschance.pre_execute_amount": parseFloat(lef.value1),
            "saleschance.chance_status": lef.status,
            "saleschance.note_pad": lef.remark
          },
          success: function(msg) {
            if (msg == 1) {
              lef.$message({
                showClose: true,
                message: "编辑成功！",
                type: "success",
                duration: 2000
              });
              lef.$emit("submitok");
            } else {
              lef.$message({
                showClose: true,
                message: "编辑失败！",
                type: "warning",
                duration: 2000
              });
            }
          },
          error: function(e) {
            lef.$message({
              showClose: true,
              message: "编辑失败！",
              type: "warning",
              duration: 2000
            });
          }
        });
      } else {
        //添加
        $.ajax({
          type: "POST",
          async: false,
          cache: false,
          url: backUrl + "cusSC_addSaleschance.action",
          data: {
            token: token,
            productids: this.commonJs.returnString(this.productids3),
            customer_ids: customerids,
            "saleschance.id": 0,
            "saleschance.source_from_id": lef.fromid,
            "saleschance.chance_level": lef.star,
            "saleschance.pre_execute_time": lef.dateValue,
            "saleschance.money_type": lef.currencyid,
            "saleschance.pre_execute_amount": parseFloat(lef.value1),
            "saleschance.chance_status": lef.status,
            "saleschance.note_pad": lef.remark
          },
          success: function(msg) {
            if (msg == 1) {
              lef.$message({
                showClose: true,
                message: "添加成功！",
                type: "success",
                duration: 2000
              });
              lef.$emit("submitok");
              // 统计跟进记录
              lef.updateFollowcount(customerids);
            } else {
              lef.$message({
                showClose: true,
                message: "添加失败！",
                type: "warning",
                duration: 2000
              });
            }
          },
          error: function(e) {
            lef.$message({
              showClose: true,
              message: "添加失败！",
              type: "warning",
              duration: 2000
            });
          }
        });
      }
    },
    //关联产品弹窗
    selectProduct() {
      this.showC = true;
    },
    //获取关联产品信息
    getProductList(type, id) {
      var param = [type, id];
	  this.getRelationProduct(param);
      if (this.relationProduct != "" && this.relationProduct.length > 0) {
        var lef = this;
        var production_value = "";
        $.each(lef.relationProduct, function(i, row) {
          lef.productids += row.product_id + ",";
          production_value += row.product_id + "###" + row.product_name + ",";
        });
        lef.productids = lef.productids.substring(0, lef.productids.length - 1);
        lef.productNum = lef.productids.split(",").length;
        production_value = production_value.substring(
          0,
          production_value.length - 1
        );
		lef.productids2 = production_value;
		lef.productids3=lef.productids
      }
    },
    //获取商机信息
    getChaneInfo() {
      var lef = this;
      $.ajax({
        type: "POST",
        url: backUrl + "cusSC_findSalesChanceByid.action",
        async: false,
        cache: false,
        dataType: "json",
        data: {
          token: token,
          salechanceid: lef.chanceId
        },
        success: function(msg) {
          lef.fromid = msg.source_from_id;
          lef.star = msg.chance_level; //机会可能性
          lef.dateValue = msg.pre_execute_time; //预成交时间
          lef.currencyid = msg.money_type; //币种id
          lef.status = msg.chance_status; //当前状态
          lef.value1 = msg.pre_execute_amount; //金额
          lef.remark = msg.note_pad; //备注信息
          lef.createDate = msg.create_date;
          lef.getProductList(3, lef.chanceId);
        }
      });
    }
  },
  mounted() {
    //加载客户来源信息
    if (this.customerfromall == "") {
      this.getCustomerFromAll();
    }
    var self = this;
    $.each(this.customerfromall, function(i, row) {
      var name1 = row.name;
      if (name1.length > 12) {
        name1 = name1.substring(0, 12) + "...";
      }
      self.chanceSourceList.push({
        id: row.id,
        name: row.name,
        name1: name1
      });
    });
    //加载货币信息
    if (this.tradecurrencyall == "") {
      this.getTradeCurrencyAll();
    }
    $.each(this.tradecurrencyall.crmcurrencylist, function(i, row) {
      var name1 = row.name;
      if (name1.length > 12) {
        name1 = name1.substring(0, 12) + "...";
      }
      self.currencyList.push({
        id: row.id,
        name: row.name,
        name1: name1
      });
    });
    //编辑
    if (this.chanceId > 0) {
      this.getChaneInfo();
    }
  }
};
</script>
<style lang="less">
.crm-tab2 {
  td {
    font-size: 12px;
    text-align: left;
    input {
      vertical-align: middle;
      margin: 0;
    }
    select {
      width: 180px;
      height: 30px;
    }
    input {
      height: 30px;
      font-size: 12px;
      margin: 0;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      border: 1px solid #ddd;
    }
  }
  .cu-t {
    border-bottom: 1px solid #ddd;
  }
  .usz {
    text-align: right;
    width: 60px;
    height: 28px;
    padding-right: 10px;
  }
  .rsp {
    margin-left: 5px;
  }
  .remind {
    margin: 0 10px;
  }
  .cu_tbg {
    height: 30px;
  }
  .pSelect {
    cursor: pointer;
    color: #20a0ff;
  }
  .money-input {
    width: 180px;
    height: 30px;
  }
  .el-date-editor.el-input {
    width: 180px;
    height: 30px;
  }
}
</style>