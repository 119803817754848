<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：客户·客户详细信息·新建跟进弹窗
开始时间：2017-11-9
开发人员：陈立珍
最后修改：2018-7-23
备注说明：如需修改请联系开发人员
-->

<template>
  <div id="newFollowRead">
    <table width="100%" cellspacing="0" cellpadding="0" class="newTab1">
      <tr>
        <td class="newF-usz">跟进类型</td>
        <td colspan="3">
          <el-radio-group v-model="type">
          	<el-radio :label="-1">计划</el-radio>
            <el-radio :label="2">记录</el-radio>
          </el-radio-group>
        </td>
      </tr>
      <tr>
        <td class="newF-usz">跟进方式</td>
        <td colspan="3">
          <select v-model="wayid" id="wayname">
            <option value="0">请选择</option>
          	<option v-for="way in followWayList" :value="way.id" :title="way.name">{{way.name1}}</option>
          </select>
        </td>
      </tr>
      <tr>
        <td class="newF-usz">跟进时间</td>
        <td colspan="3">
        	<span v-show="type==-1">
        		 <el-date-picker
          	style="width: 140px;"
            v-model="dateValue"
            placeholder="选择日期"
            type="date"
            :clearable="false" :editable="false"
            :picker-options="pickerOptions0">
          </el-date-picker>
        	</span>
         <span v-show="type!=-1">
         	 <el-date-picker
          	style="width: 140px;"
            v-model="dateValue"
            placeholder="选择日期"
            type="date"
            :clearable="false" :editable="false"
            :picker-options="pickerOptions2">
          </el-date-picker>
         </span>
          <el-time-picker
          	style="width: 140px;"
            v-model="timeValue"
            :picker-options="{selectableRange: '00:00:00 - 23:59:59'}"
            placeholder="选择时间"
            :editable="false"
            :clearable="false">
          </el-time-picker>
          <span class="rsp" v-show="type==-1">
            <span class="remind">提醒</span>
            <select v-model="remindid">
            	<option value="0">请选择</option>
              <option value="1">10分钟前</option>
              <option value="2">30分钟前</option>
              <option value="3">1小时前</option>
              <option value="4">2小时前</option>
              <option value="5">6小时前</option>
              <option value="6">1天前</option>
            </select>
          </span>
        </td>
      </tr>
      <tr>
        <td class="newF-usz">销售进程</td>
        <td colspan="3">
          <select v-model="processid">
            <option value="0">请选择</option>
          	<option v-for="process in salesProcessList" :value="process.id" :title="process.name">{{process.name1}}</option>
          </select>
        </td>
      </tr>
      <tr>
        <td class="cu_tbg">跟进内容</td>
        <td colspan="3">
          <textarea  v-model="followContent" cols="30" rows="10" class="f-content"></textarea>
        </td>
      </tr>
      <tr>
        <td class="newF-usz">关联产品</td>
        <td colspan="3">
          <span @click="selectProduct" class="pSelect">{{productNum==0?'请选择':'选择了'+productNum+'个产品'}}</span>
        </td>
      </tr>
    </table>
     <!--引入选择关联产品组件-->
   <!-- <small-layer
      class="smallLayer"
      v-if="showC"
      @cntClose="showC = false"
      @cntConfirm="cntConfirm"
      :showType="4"
     	:productIds="productids"
     	:productids2="productids2"
      :connect-tab="false"
      cnt-title="选择关联产品"
      :has-button="true"></small-layer>-->
      
      <relatedProduct v-if="showC" :productids2="productids2" :productType="7" @closePro="showC = false" @cntConfirm="cntConfirm"></relatedProduct>
      
  </div>
</template>
<script>
  import store from '../../../vuex/store.js'
	import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
	import '../../../vuex/mutations.js';
	import smallLayer from "./smallLayer.vue"
	import relatedProduct from './layer_related_product.vue'
  export default({
    name:"newFollow",
    data(){
    return{
    	type:-1,
      showC:false,
      productNum:0,
      productids: '',//选择的关联产品id,多个以逗号分割
      productids2:'',
      pickerOptions1: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
          	picker.$emit('pick', new Date());
  				}
  		}]
  		},
  		pickerOptions0: {
				disabledDate(time) {
					return time.getTime() < Date.now() - 8.64e7;
				}
	  	},
	  	pickerOptions2: {
				disabledDate(time) {
					return time.getTime() > Date.now();
				}
	  	},
  		dateValue: '',
    	timeValue: '',
    	remindid:0,//提醒类型
    	wayid:0,//跟进方式id
      processid:0,//销售进程id
      followContent:'',//跟进内容
      followWayList:[],//跟进方式集合
      salesProcessList:[],//销售进程集合
  	}
  },
  computed:{
		...mapState({
	    userinfo: state => state.userinfo,
	    followupwayall: state => state.followupwayall,
	    saleprocessall: state => state.saleprocessall,
	  }),
  },
  components:{
    smallLayer,relatedProduct
  },
  methods:{
  	...mapMutations({
      getFollowupWayAll: 'getFollowupWayAll',
      getSaleProcessAll: 'getSaleProcessAll',
    }),
    //确认关联产品
    cntConfirm(proids){
			var pro_ids = "";
				$.each(proids, function(index, row) {
					pro_ids += row.id + ",";
				})
				pro_ids = pro_ids.substring(0, pro_ids.length - 1);
				this.productids = pro_ids;
				if(proids != '') {
					this.productNum = this.productids.split(',').length;
				} else {
					this.productNum = 0;
				}
				//弹窗选中
				var production_value = "";
				$.each(proids, function(i, row) {
					var pid = row.id;
					var pname = row.name;
					production_value += pid + "###" + pname + ",";
				})
				production_value = production_value.substring(0, production_value.length - 1);
				this.productids2 = production_value;
    },
    //选择关联产品
    selectProduct(){
      this.showC = true;
    },
    //添加跟进提交
    subButton(customerids){
    	//跟进记录
    	if(this.dateValue==''){
		    this.$message({
					showClose: true,
					message: '请选择跟进日期！',
					type: 'warning',
					duration:2000
				});
				return;
		   }
    	if(this.timeValue==''){
		    this.$message({
					showClose: true,
					message: '请选择跟进时间！',
					type: 'warning',
					duration:2000
				});
				return;
		  }
    	var followDate = new Date(this.dateValue);
    	var followTime = new Date(this.timeValue);
    	var year = followDate.getFullYear();
    	var month = followDate.getMonth();
    	var day = followDate.getDate();
    	var hour = followTime.getHours();
    	var minu = followTime.getMinutes();
    	var second = followTime.getSeconds();
    	var selectDate = new Date(year,month,day,hour,minu,second);
    	var nowDate = new Date();
    	if(selectDate.getTime() > nowDate.getTime()){
    		this.$message({
					showClose: true,
					message: '跟进时间不能大于当前时间！',
					type: 'warning',
					duration:2000
				});
				return;
    	}
    	if(this.processid == 0){
    		this.$message({
					showClose: true,
					message: '请选择销售进程！',
					type: 'warning',
					duration:2000
				});
				return;
    	}
    	if(this.followContent == ''){
    		this.$message({
					showClose: true,
					message: '跟进内容不能为空！',
					type: 'warning',
					duration:2000
				});
				return;
    	}
    	var yearStr = year.toString();
		  var monthStr = (month + 1).toString();
		  if(month+1<10){
		    monthStr = "0"+(month + 1).toString();
		  }
		  var dayStr = day.toString();
		  if (day < 10) {
		    dayStr = "0" + day;
		  }
		  var dateStr = yearStr + "-" + monthStr + "-" + dayStr;
    	var hourStr = hour.toString();
    	if (hour < 10) {
		    hourStr = "0" + hour;
		  }
		  var minuStr = minu.toString();
		  if (minu < 10) {
		    minuStr = "0" + minu;
		  }
		  var secondStr = second.toString();
		  if (second < 10) {
		    secondStr = "0" + second;
		  }
		  var timeStr = hourStr + ":" + minuStr + ":" + secondStr;
    	var lef = this;
    	$.ajax({
				type : "POST",
				url : backUrl + "cusfu3_addFollowups.action",
				async : false,
				cache : false,
				dataType : "json",
				data : {
					token:token,
					customer_ids:customerids,
					"customerfollowup.create_user_id":lef.userinfo.user_id,
					"customerfollowup.followup_content":lef.followContent,
					"customerfollowup.followup_time":dateStr+" "+timeStr,
					"customerfollowup.followup_classify":2,
					"customerfollowup.followup_way":lef.wayid,
					"customerfollowup.remind_time_classify":0,
					"customerfollowup.remind_time":"",
					"customerfollowup.purpose_process":lef.processid,
					"customerfollowup.focus_product":lef.productids,
					"customerfollowup.is_msg_remind":0,
					"customerfollowup.is_email_remind":0,
					"customerfollowup.mail_id":0,
					"upuserid":lef.userinfo.user_id
				},
				success : function(msg) {
					if(msg==1){
						lef.$message({
							showClose: true,
							message: '添加成功！',
							type: 'success',
							duration:2000
						});
						lef.$emit('submitok');
					}else{
						lef.$message({
							showClose: true,
							message: '添加失败！',
							type: 'warning',
							duration:2000
						});
					}
				},
				error : function(e) {
					lef.$message({
						showClose: true,
						message: '添加失败！',
						type: 'warning',
						duration:2000
					});
				}
			});
    }
  },
  mounted(){
  	var self = this;
  	$.each(this.followupwayall,function(i,row){
  		var name1 = row.name;
  		if(name1.length > 9){
  			name1 = name1.substring(0,9)+"...";
  		}
  		self.followWayList.push({
  			name1:name1,
  			name:row.name,
  			id:row.id,
  		})
  	});
  	$.each(this.saleprocessall,function(i,row){
  		var name1 = row.name;
  		if(name1.length > 9){
  			name1 = name1.substring(0,9)+"...";
  		}
  		self.salesProcessList.push({
  			name1:name1,
  			name:row.name,
  			id:row.id,
  		})
  	});
  }
  
})
</script>
<style lang="less">
	.newTab1 .pSelect{
    color:#20a0ff;
    cursor: pointer;
  }
  .el-time-spinner__item{
  	line-height: 30px !important;
  }
  #newFollowRead .newTab1 select{
  	width: 140px;
  	height: 30px;
  }
</style>