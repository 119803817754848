<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：客户·客户详细信息·跟进详细信息弹窗
开始时间：2017-11-9
开发人员：陈立珍
最后修改：2018-7-23
备注说明：如需修改请联系开发人员
-->

<template>
	<div id="morefollowInfo">
		<el-dialog title="跟进详细信息" class='dialogTwo' size="tiny" :visible.sync="test" :before-close="carryClose" :modal="false" :modal-append-to-body="true" :lock-scroll="true" :close-on-click-modal="false">
			<popupLoading></popupLoading>
			<div class="tb-box">
				<table cellspacing="0" cellpadding="0" width="100%">
					<tr>
						<td>跟进时间</td>
						<td>{{layer_Info.follow_date}}</td>
					</tr>
					<tr>
						<td>跟进内容</td>
						<td>
							<div class="maxF-width hide-name">
								<el-tooltip class="item" effect="dark" :open-delay="300" :content="layer_Info.content" placement="top">
									<span>{{layer_Info.content}}</span>
								</el-tooltip>
							</div>
						</td>
					</tr>
				</table>
			</div>
			<span slot="footer" class="dialog-footer">
          <div class="btn-box">
            <el-button type="primary"  @click="cntConfirm">立即执行</el-button>
            <el-button  @click="carryClose">暂不执行</el-button>
          </div>
        </span>
		</el-dialog>
	</div>
</template>
<script>
	import popupLoading from '@/components/publicComponent/popupLoading'
	export default({
		data() {
			return {
				test: true,
			}
		},
		props: [
			'layer_Info'
		],
		components: {
		popupLoading
	},
		methods: {
			//提交
			cntConfirm() {
				this.$emit('cntConfirm', this.layer_Info.id)
			},
			//触发父组件关闭弹窗
			carryClose() {
				this.$emit('carryClose')
			}
		},
		mounted() {
			function clearTransform() {
				$('.el-dialog').css({
					'transform': 'none',
				})
			};
			dialogDrag();
			clearTransform(dialogCenter());
		}
	})
</script>
<style lang="less">
	#morefollowInfo {
		.el-dialog__wrapper>.el-dialog {
			width: 400px;
		}
		.tb-box {
			height: 120px;
			table {
				border: 1px solid #ddd;
				td {
					height: 50px;
					line-height: 50px;
					border-bottom: 1px solid #ddd;
					border-right: 1px solid #ddd;
					text-indent: 20px;
					&:nth-child(even) {
						border-right: 0;
					}
					&:nth-child(odd) {
						background-color: #f2f2f2;
						white-space: nowrap;
						width: 85px;
					}
				}
			}
		}
	}
	
	.maxF-width {
		max-width: 250px;
	}
	
	.hide-name {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
</style>