<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：客户·客户详细信息·关联文件弹窗
开始时间：2017-11-9
开发人员：陈立珍
最后修改：2018-7-23
备注说明：如需修改请联系开发人员
-->

<template>
	<div>
		<el-dialog title="关联文件" class='relative_file dialogTwo' size="tiny" :visible.sync="test" :before-close="cntClose" :modal="false" :modal-append-to-body="true" :lock-scroll="true" :close-on-click-modal="false">
			<div class="cnt-tab">
				<!--表格-->
				<div class="fileTable" v-if='userparamlist.param213 != undefined && userparamlist.param213 != 0'>
					<table class="crm-connectionTab" cellspacing="0" cellpadding="0" width="100%" style="margin-bottom: 20px;" v-loading="fileLoading" element-loading-text="拼命加载中">
						<tbody>
							<tr>
								<th class="th" width="5%"></th>
								<th class="th" width="20%" style="min-width: 220px;">文件名</th>
								<th class="th" width="18%">上传时间</th>
								<th class="th" width="15%">大小</th>
								<th class="th" width="25%" style="border-right: 1px solid #d3d3d3;">操作</th>
							</tr>
							<tr v-for="(tab,index) in fileList" v-if="fileList.length > 0" class="fileListTr">
								<td class="td" style="border-left: 1px solid #d3d3d3;border-bottom: 1px solid #d3d3d3;"></td>
								<td class="td">
									<el-tooltip class="item" effect="dark" :open-delay="300" :content="tab.name" placement="top" v-show='!tab.change'>
										<p style="overflow: hidden;text-overflow:ellipsis;white-space: nowrap;max-width: 220px;">{{tab.name}}</p>
									</el-tooltip>
									<div v-if='tab.change'>
										<input type="text" v-model='fileName' />
										<span class="file-new" @click="fileNameInput(true,index,tab.id,tab.folderId)">
		                     		 <i class="el-icon-check"></i>
		                    	</span>
										<span class="file-new" style="padding: 1px 3px;" @click="fileNameInput(false,index,tab.id,tab.folderId)">
		                       		<i class="el-icon-close"></i>
		                    	</span>
									</div>
								</td>
								<td class="td">
									<el-tooltip class="item" effect="dark" :open-delay="300" :content="tab.time1" placement="top">
										<span>{{tab.time}}</span>
									</el-tooltip>
								</td>
								<td class="td">{{tab.size}}</td>
								<td class="td" style="border-right: 1px solid #d3d3d3;">
									<i title="查看" @click='lookFileMethod(tab.token,tab.name)' class="icon iconfont icon-eyes alibabaicon"></i>
								</td>
							</tr>
							<tr v-if="fileList.length == 0">
								<td colspan="100%" class="noData-box">
									<div class="noData-bg">
									</div>
									<span class="noData">暂无数据</span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
          <div class="btn-box">
            <el-button  @click="cntClose">取 消</el-button>
          </div>
        </span>
		</el-dialog>

		<!-- 预览 -->
		<div>
			<transition name="fade">
				<div class="lookFj" v-show='lookFile'>
					<div class="lf-header w100">
						<div class="lfh-title" :title="looFileName" style="overflow: hidden;text-overflow:ellipsis;white-space: nowrap;max-width:800px;">{{looFileName}}</div>
						<div class="lfh-download ">
							<i class="icon iconfont icon-download" style="font-size: 18px;" @click='downloadCommonMethod([fileToken,0])'></i>
							<span>下载</span>
						</div>
						<div class="lfh-close " @click='lookFile = false'>
							<i class="icon iconfont icon-close color-white"></i>
							<span>关闭</span>
						</div>
					</div>
					<div class="lf-content w100">
						<iframe :src="lookFileUrl" width="90%" height="100%" class="lfc-main"></iframe>
					</div>
				</div>
			</transition>
		</div>
	</div>
</template>

<script>
	import store from '../../../vuex/store.js'
	import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
	import '../../../vuex/mutations.js';
	export default {
		props: [
			'saleId', 'customerId'
		],
		data() {
			return {
				test: true,
				fileList: [],//上传文件的集合
				lookFile: false,//文件预览是否显示
				looFileName: "",//文件名称
				lookFileUrl: "",//文件链接
				fileLoading: true,
				fileToken: "",
			}
		},
		computed: {
			...mapState({
				userinfo: state => state.userinfo,
				userparamlist: state => state.userparamlist,
			}),
		},
		methods: {
			...mapMutations({
				downloadCommonMethod: 'downloadCommonMethod'
			}),
			//触发父组件关闭弹窗
			cntClose: function() {
				this.$emit('cntClose', false)
			},
			//查看附件
			lookFileMethod(fileToken, name) {
				this.fileToken = fileToken;
				this.looFileName = name
				let self = this;
				$.ajax({
					type: "POST",
					url: diskUrl + "/file/previewUrl/get",
					async: false,
					cache: false,
					data: {
						type: 0,
						token: fileToken,
					},
					success: function(msg) {
						self.lookFileUrl = msg.data
					}
				});
				this.lookFile = true
			},
		},
		mounted() {
			//加载文件列表
			var self = this;
			$.ajax({
				type: "get",
				url: diskUrl + "/file/list",
				async: true,
				cache: false,
				data: {
					LoginUserToken: token,
					companyId: this.userinfo.company_id,
					folderId: -1,
					currentPage: 1,
					pageSize: 5,
					customerId: this.customerId,
					targetId: this.saleId,
				},
				beforeSend: function() {
					self.fileLoading = true;
				},
				complete: function() {
					self.fileLoading = false;
				},
				success: function(msg) {
					if(msg.code == 200) {
						self.fileList = []
						$.each(msg.data.list, (i, v) => {
							//文件大小
							let filesizeM = v.size / 1024 / 1024,
								failsize, filesizeK = v.size / 1024;
							if(filesizeM > 1) {
								failsize = filesizeM.toFixed(2) + 'M';
							} else {
								failsize = filesizeK.toFixed(2) + 'K';
							}

							self.fileList.push({
								name: v.name,
								time: v.createTime.substring(0, 10),
								time1: v.createTime,
								size: failsize,
								token: v.token,
								id: v.id,
								folderId: v.folderId,
								change: false
							})
						})
					}
				}
			});
		}
	}
</script>

<style>
	.lookFj {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.2);
		z-index: 99999;
	}
	
	.lf-header {
		height: 54px;
		background: #20A0FF;
	}
	
	.lfh-title {
		color: #fff;
		position: absolute;
		left: 20px;
		top: 18px;
		font-size: 16px;
		max-width: 1100px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	
	.lfh-download {
		position: absolute;
		top: 18px;
		right: 100px;
		font-size: 16px;
		color: #fff;
		cursor: pointer;
	}
	
	.lfh-close {
		position: absolute;
		top: 18px;
		font-size: 16px;
		right: 15px;
		width: 60px;
		height: 30px;
		color: #fff;
		text-align: center;
		cursor: pointer;
	}
	
	.lf-content {
		position: absolute;
		top: 56px;
		bottom: 0;
		text-align: center;
	}
	
	.lfc-main {
		background-color: #fff;
		border: none;
	}
</style>