<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：客户·全部客户/客户状态/我的共享客户/同事共享给我的页面中的客户详细信息弹窗
开始时间：2017-11-9
开发人员：陈立珍
最后修改：2019-01-11
备注说明：如需修改请联系开发人员
-->

<template>
	<!--客户详细信息弹窗中的企业信息页面-->
	<div id="newClientByhand" class="createTab-content">
			<div class="crmPopupLoading"  v-if='crmPopupLoading || companyLoad ||emailLoad'>
      <div class="plMain">
        <i class="el-icon-loading plIcon"></i>
        <p class="plTip">{{loadingTitle}}</p>
      </div>
	  </div>
		<div class="company-box">
			<form method="post" action="" id="cus_updatecustomerinfoForm">
				<input type="hidden" name="crmcustomer2.customer_id" id="customer_id" :value="customerInformation.customer_id" />
				<input type="hidden" name="crmlinkman.linkman_id" :value="customerInformation.linkman.linkman_id" />
				<input type="hidden" name="crmcustomer2.current_status" :value="customerInformation.current_status" />
				<input type="hidden" name="crmcustomer2.sale_process" :value="customerInformation.sales_status" />
				<input type="hidden" name="crmcustomer2.enrol_time" :value="customerInformation.enrol_time" />
				<input type="hidden" name="crmcustomer2.create_way" :value="customerInformation.create_way" />
				<input type="hidden" name="crmcustomer2.last_contact_date" :value="customerInformation.last_contact_date" />
				<input type="hidden" name="crmcustomer2.organization_code" :value="customerInformation.organization_code" />
				<input type="hidden" name="crmcustomer2.black_flag" :value="customerInformation.black_flag" />
				<input type="hidden" name="old_customer_name" :value="oldCusName" />
				<table width="100%" cellpadding="0" cellspacing="0" class="cu_tbcs">
					<tbody>
						<tr>
							<td class="ft2 cu-t"><b>
			          <span>基本信息</span>
			        </b></td>
						</tr>
						<tr>
							<td class="cu-empty" colspan="4"></td>
						</tr>
						<tr>
							<td class="cu_tbg" width="80">
								<span>企业全称</span>
							</td>
							<td width="250">
								<input name="crmcustomer2.customer_name" :disabled="!hasEditbtn" id="cusM_name" type="text" class="custx" @blur="cusname_varify(0)" v-model="cus_name">
								<span class="red" title="必填项" style="cursor: pointer;">*</span>
								<span v-show="hasVertify1" :title="correct1?correctTitle:errorTitle1" class="rightError" :class="correct1?'right-span':'error-span'"></span>
							</td>
							<td class="cu_tbg" width="80">
								<span>联系人姓名</span>
							</td>
							<td width="250">
								<input name="crmlinkman.full_name" :disabled="!hasEditbtn" id="cusM_fullname" type="text" class="custx" @blur="linkname_verify()" v-model="link_name">
								<span class="red" title="必填项" style="cursor: pointer;">*</span>
								<span v-show="hasVertify2" :title="correct2?correctTitle:errorTitle2" class="rightError" :class="correct2?'right-span':'error-span'"></span>
							</td>
						</tr>
						<tr>
							<td class="cu_tbg">
								<span>所在国家</span>
							</td>
							<td style="position:relative">
								<all-country ></all-country>
							</td>
							<td class="cu_tbg">
								<span>联系人邮箱</span>
							</td>
							<td>
								<input name="crmcustomer2.email" :disabled="!hasEditbtn" id="cusM_email" type="text" class="custx" @blur="email_verify(0)" v-model="email">
								<span class="red" title="必填项" style="cursor: pointer;">*</span>
								<span v-show="hasVertify4" :title="correct4?correctTitle:errorTitle4" class="rightError" :class="correct4?'right-span':'error-span'"></span>
							</td>
						</tr>
						<tr>
							<td class="cu_tbg">
								<span>首次联系</span>
							</td>
							<td>
								<div class="block hasDatepicker">
									<el-date-picker :disabled="!hasEditbtn" v-model="dateValue" type="date" placeholder="选择日期时间" :clearable="false" :editable="false">
									</el-date-picker>
								</div>
								<span class="red"></span>
							</td>
							<td class="cu_tbg">
								<span>联系人电话</span>
							</td>
							<td>
								<input name="crmcustomer2.phone" :disabled="!hasEditbtn" type="text" class="custx" id="cusM_mobile" v-model="phone">
								<span class="red" v-show="isShow2" title="必填项" style="cursor: pointer;">*</span>
								<span v-show="hasVertify5" :title="correct5?correctTitle:errorTitle5" class="rightError" :class="correct5?'right-span':'error-span'"></span>
							</td>
						</tr>
					</tbody>
					<tbody class="re-info">
						<tr>
							<td class="ft2 cu-t cu-info" colspan="100%">
								<span> <b>自定义字段</b> </span>
								<el-switch class="crm-witch" v-model="showF" on-text="展开" off-text="收缩">
								</el-switch>
							</td>
							<td class="cu-t" colspan="3"></td>
						</tr>
						<tr>
							<td class="cu-empty" colspan="4"></td>
						</tr>
						<tr class="myfield" v-show="showF">
							<td class="cu_tbg">
								<span v-show="hasEditbtn" class="o_add" @click="addfield($event)" title="添加自定义字段" style="margin-left: 5px;"></span>
							</td>
							<td class="cu-t" colspan="3"></td>
						</tr>
						<tr class="addNum" v-show="showF && definedShow" v-for="(field,index) in cusFieldList" :addNum='index'>
							<td class="cu_tbg">
								<span>名称</span>
							</td>
							<td>
								<input type='text' :disabled="!hasEditbtn" :value="field.defined_key" class="custx medefinedname" style='width: 182px;' />&nbsp;</td>
							</td>
							<td class="cu_tbg">
								<span>对应值</span>
							</td>
							<td>
								<input type='text' :disabled="!hasEditbtn" :value="field.defined_value" class="custx medefinedvalue" />
								<span v-show="hasEditbtn" class='o_recycle' @click="removefield($event)" title='删除自定义字段' style="margin-left: 5px;"></span>
							</td>
						</tr>
					</tbody>

					<tbody class="re-info">
						<tr>
							<td class="ft2 cu-t cu-info" colspan="100%">
								<span> <b>其它信息</b> </span>
								<el-switch class="crm-witch" v-model="showW" on-text="展开" off-text="收缩">
								</el-switch>
							</td>
							<td class="cu-t" colspan="3"></td>
						</tr>
						<tr>
							<td class="cu-empty" colspan="4"></td>
						</tr>
						<tr v-show="showW">
							<td class="cu_tbg" width="82">
								<span>省份/州/区</span>
							</td>
							<td>
								<input :disabled="!hasEditbtn" name="crmcustomer2.province" type="text" class="custx" v-model="province" id="cusM_province">
								<span class="red" v-show="isShow3" title="必填项" style="cursor: pointer;">*</span>
								<span v-show="hasVertify6" :title="correct6?correctTitle:errorTitle6" class="rightError" :class="correct6?'right-span':'error-span'"></span>
							</td>
							<td class="cu_tbg">
								<span>城市</span>
							</td>
							<td>
								<input :disabled="!hasEditbtn" name="crmcustomer2.city" type="text" class="custx" v-model="city" id="cusM_city">
								<span class="red" v-show="isShow4" title="必填项" style="cursor: pointer;">*</span>
								<span v-show="hasVertify7" :title="correct7?correctTitle:errorTitle7" class="rightError" :class="correct7?'right-span':'error-span'"></span>
							</td>
						</tr>
						<tr v-show="showW">
							<td class="cu_tbg">
								<span>企业简称</span>
							</td>
							<td>
								<input :disabled="!hasEditbtn" name="crmcustomer2.simp_name" type="text" class="custx" v-model="sname" id="cusM_tomersimp">
								<span class="red" v-show="isShow5" title="必填项" style="cursor: pointer;">*</span>
								<span v-show="hasVertify8" :title="correct8?correctTitle:errorTitle8" class="rightError" :class="correct8?'right-span':'error-span'"></span>
							</td>
							<td class="cu_tbg">
								<span>员工规模</span>
							</td>
							<td>
								<select :disabled="!hasEditbtn" name="crmcustomer2.persons_scale" class="custx" id="cusM_persons_scale">
									<option value="1-50">1-50</option>
									<option value="50-500">50-500</option>
									<option value="500-1000">500-1000</option>
									<option value="1000以上">1000以上</option>
								</select>
							</td>
						</tr>
						<tr v-show="showW">
							<td class="cu_tbg">
								<span>手机</span>
							</td>
							<td>
								<input :disabled="!hasEditbtn" name="crmcustomer2.mobile" type="text" class="custx" v-model="mobile" id="cusM_mobilephone">
								<span class="red" v-show="isShow6" title="必填项" style="cursor: pointer;">*</span>
								<span v-show="hasVertify9" :title="correct9?correctTitle:errorTitle9" class="rightError" :class="correct9?'right-span':'error-span'"></span>
							</td>
							<td class="cu_tbg">
								<span>职位</span>
							</td>
							<td>
								<input :disabled="!hasEditbtn" name="crmlinkman.post" type="text" class="custx" v-model="post" id="cusM_post">
								<span class="red" v-show="isShow7" title="必填项" style="cursor: pointer;">*</span>
								<span v-show="hasVertify10" :title="correct10?correctTitle:errorTitle10" class="rightError" :class="correct10?'right-span':'error-span'"></span>
							</td>
						</tr>
						<tr v-show="showW">
							<td class="cu_tbg">
								<span>传真</span>
							</td>
							<td>
								<input :disabled="!hasEditbtn" name="crmcustomer2.fax" type="text" class="custx" v-model="fax" id="cusM_fax">
								<span class="red" v-show="isShow8" title="必填项" style="cursor: pointer;">*</span>
								<span v-show="hasVertify11" :title="correct11?correctTitle:errorTitle11" class="rightError" :class="correct11?'right-span':'error-span'"></span>
							</td>
							<td class="cu_tbg">
								<span>网址</span>
							</td>
							<td>
								<input :disabled="!hasEditbtn" name="crmcustomer2.website" type="text" class="custx" v-model="web" id="cusM_website">
								<span class="red" v-show="isShow9" title="必填项" style="cursor: pointer;">*</span>
								<span v-show="hasVertify12" :title="correct12?correctTitle:errorTitle12" class="rightError" :class="correct12?'right-span':'error-span'"></span>
							</td>
						</tr>
						<tr v-show="showW">
							<td class="cu_tbg">
								<span>地址</span>
							</td>
							<td colspan="3">
								<input :disabled="!hasEditbtn" name="crmcustomer2.address" type="text" class="custx" v-model="address" style="width: 544px;" id="cusM_address">
								<span class="red" v-show="isShow10" title="必填项" style="cursor: pointer;">*</span>
								<span v-show="hasVertify13" :title="correct13?correctTitle:errorTitle13" class="rightError" :class="correct13?'right-span':'error-span'"></span>
							</td>
						</tr>
					</tbody>
					<tbody>
						<tr>
							<td class="ft2 cu-t" colspan="100%">
								<div>
									<b>
			              <span>社交信息</span>
			            </b>
									<el-switch class="crm-witch" v-model="showS" on-text="展开" off-text="收缩">
									</el-switch>
								</div>
							</td>
							<td class="cu-t" colspan="3"></td>
						</tr>
						<tr>
							<td class="cu-empty" colspan="4"></td>
						</tr>
						<tr v-show="showS">
							<td class="cu_tbg">
								<span>Facebook</span>
							</td>
							<td>
								<input :disabled="!hasEditbtn" type="text" class="custx" name="crmcustomer2.facebook" id="facebook" :value="customerInformation.facebook">
							</td>
							<td class="cu_tbg">
								<span>Twitter</span>
							</td>
							<td>
								<input :disabled="!hasEditbtn" type="text" class="custx" name="crmcustomer2.twitter" id="twitter" :value="customerInformation.twitter">
							</td>
						</tr>
						<tr v-show="showS">
							<td class="cu_tbg">
								<span>LinkedIn</span>
							</td>
							<td>
								<input :disabled="!hasEditbtn" type="text" class="custx" name="crmcustomer2.linkedin" id="linkedin" :value="customerInformation.linkedin"></td>
							<td class="cu_tbg">
								<span>Pinterest</span>
							</td>
							<td>
								<input :disabled="!hasEditbtn" type="text" class="custx" name="crmcustomer2.pinterest" id="pinterest" :value="customerInformation.pinterest"></td>
						</tr>
						<tr v-show="showS">
							<td class="cu_tbg">
								<span>Google+</span>
							</td>
							<td>
								<input :disabled="!hasEditbtn" type="text" class="custx" name="crmcustomer2.google" id="google" :value="customerInformation.google"></td>
							<td class="cu_tbg">
								<span>Tumblr</span>
							</td>
							<td>
								<input :disabled="!hasEditbtn" type="text" class="custx" name="crmcustomer2.tumblr" id="tumblr" :value="customerInformation.tumblr"></td>
						</tr>
						<tr v-show="showS">
							<td class="cu_tbg">
								<span>Instagram</span>
							</td>
							<td>
								<input :disabled="!hasEditbtn" type="text" class="custx" name="crmcustomer2.instagram" id="instagram" :value="customerInformation.instagram"></td>
							<td class="cu_tbg">
								<span>VK</span>
							</td>
							<td>
								<input :disabled="!hasEditbtn" type="text" class="custx" name="crmcustomer2.vk" id="vk" :value="customerInformation.vk"></td>
						</tr>
						<tr v-show="showS">
							<td class="cu_tbg" width="114">
								<span>Made In China</span>
							</td>
							<td>
								<input :disabled="!hasEditbtn" type="text" class="custx" name="crmcustomer2.make_in_china" id="make_in_china" :value="customerInformation.make_in_china"></td>
							<td class="cu_tbg">
								<span>Alibaba</span>
							</td>
							<td>
								<input :disabled="!hasEditbtn" type="text" class="custx" name="crmcustomer2.alibaba" id="alibaba" :value="customerInformation.alibaba"></td>
						</tr>
						<tr v-show="showS">
							<td class="cu_tbg">
								<span>Skype</span>
							</td>
							<td>
								<input :disabled="!hasEditbtn" type="text" class="custx" id="skype" name="crmcustomer2.skype"></td>
							<td class="cu_tbg">
								<span>Youtube</span>
							</td>
							<td>
								<input :disabled="!hasEditbtn" type="text" class="custx" id="youtube" name="crmcustomer2.youtube"></td>
						</tr>
					</tbody>
				</table>
			</form>
		</div>
		<div v-show="hasEditbtn && canEditCus">
			<span class="confirm-btn" @click="submitCusInfo">提交</span>
		</div>
		<div v-show="!hasEditbtn && canEditCus">
			<span class="confirm-btn" @click="editCusInfo">编辑</span>
		</div>
	</div>

</template>
<script>
	import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
	import allCountry from '@/components/crm/country/country'
	import '../../../vuex/mutations.js';
	export default({
		name: 'newClientByhand',
		props: [
			'hasBusinessInfo',
			'showE',
			'hasEditbtn',
		],
		data() {
			return {
				showF: false, //展开自定义字段
				showW: false, //展开其它信息
				showS: false, //展开社交信息
				//必填项的显示隐藏标识(必填项*)
				isShow1: false, //所在国家
				isShow2: false, //联系人电话
				isShow3: false, //省份/州/区
				isShow4: false, //城市
				isShow5: false, //企业简称
				isShow6: false, //手机
				isShow7: false, //职位
				isShow8: false, //传真
				isShow9: false, //网址
				isShow10: false, //地址
				canEditCus: false,//别人共享给我的客户不能编辑
				//各验证显示隐藏标识
				hasVertify1: false,
				correct1: false,
				errorTitle1: "",
				cus_name: '',
				companyLoad:false,

				hasVertify2: false,
				correct2: false,
				errorTitle2: "",
				link_name: '',

				hasVertify3: false,
				correct3: false,
				errorTitle3: "",
				country: '',

				hasVertify4: false,
				correct4: false,
				errorTitle4: "",
				email: '',
				emailLoad:false,

				hasVertify5: false,
				correct5: false,
				errorTitle5: "",
				phone: '',

				hasVertify6: false,
				correct6: false,
				errorTitle6: "",
				province: '',

				hasVertify7: false,
				correct7: false,
				errorTitle7: "",
				city: '',

				hasVertify8: false,
				correct8: false,
				errorTitle8: "",
				sname: '',

				hasVertify9: false,
				correct9: false,
				errorTitle9: "",
				mobile: '',

				hasVertify10: false,
				correct10: false,
				errorTitle10: "",
				post: '',

				hasVertify11: false,
				correct11: false,
				errorTitle11: "",
				fax: '',

				hasVertify12: false,
				correct12: false,
				errorTitle12: "",
				web: '',

				hasVertify13: false,
				correct13: false,
				errorTitle13: "",
				address: '',

				correctTitle: "恭喜，输入正确！",

				oldCusName: '', //编辑前客户名称
				oldLinkEmail: '', //编辑前邮件地址
				dateValue: '', //首次联系时间

				pickerOptions1: {
					shortcuts: [{
						text: '今天',
						onClick(picker) {
							picker.$emit('pick', new Date());
						}
					}]
				},
				counts: 0, //添加自定义次数
				cusFieldList: [], //自定义字段集合
				definedShow: false,
				companyTure:false,
				emailTrue:false,
				loadingTitle:'',//当前正在验证title
				crmPopupLoading:false,//当前crm的loading弹框
				confirmLoad:false,//当前是否正在雁阵
			}
		},
		components:{allCountry},
		computed: {
			...mapState({
				userinfo: state => state.userinfo,
				customerInformation: state => state.customerInformation,
				necessaryitemall: state => state.necessaryitemall,
				countryArr: state => state.countryArr,
				companyparamlist: state => state.companyparamlist,
				userlistall: state => state.userlistall,
			})
		},
		methods: {
			...mapMutations({
				getCustomerInformation: 'getCustomerInformation',
				getNecessaryitemAll: 'getNecessaryitemAll',
			}),
			//初始化客户信息
			initCustomerInfo() {
				this.cus_name = this.customerInformation.customer_name;
				this.link_name = this.customerInformation.linkman.full_name;
				this.post = this.customerInformation.linkman.post;
				this.country = this.customerInformation.country;
				this.$store.commit('CUR_COUNTRY',this.customerInformation.country)
				this.email = this.customerInformation.linkman.email;
				this.phone = this.customerInformation.phone;
				this.province = this.customerInformation.province;
				this.city = this.customerInformation.city;
				this.sname = this.customerInformation.simp_name;
				this.mobile = this.customerInformation.mobile;
				this.fax = this.customerInformation.fax;
				this.web = this.customerInformation.website;
				this.address = this.customerInformation.address;
				if(this.customerInformation.operate_time != null && this.customerInformation.operate_time != "") {
					this.dateValue = this.customerInformation.operate_time.split(" ")[0];
				}
				this.oldCusName = this.cus_name;
				this.oldLinkEmail = this.email;
				var lef = this;
				if(this.customerInformation.customer_field != "" && this.customerInformation.customer_field != null && this.customerInformation.customer_field != undefined) {
					this.showF = true;
					this.definedShow = true;
					var defineds = lef.customerInformation.customer_field.split("@@G@@");
					$.each(defineds, function(index, row1) {
						var defined = row1.split('###');
						lef.cusFieldList.push({
							defined_key: defined[0],
							defined_value: defined[1]
						})
					})
				} else {
					this.cusFieldList = [];
					this.showF = false;
					this.definedShow = false;
				}
				if(this.customerInformation.user_id == this.userinfo.user_id){
					this.canEditCus = true;
				}else{
					this.canEditCus = false;
				}
			},
			//处理必填项
			handleNecessary() {
				//加载必填项信息
				if(this.necessaryitemall == '') {
					this.getNecessaryitemAll();
				}
				//无必填项则不展示其它信息
				if(this.necessaryitemall.length == 0) {
					this.showW = false;
				} else { //有必填项则展示其它信息
					var lef = this;
					$.each(this.necessaryitemall, function(index, row) {
						if(row.necessary_item.trim() == '所在国家') {
							lef.isShow1 = true;
							$("#cusM_Country").blur(function() {
								lef.country_verify();
							});
						} else if(row.necessary_item.trim() == '联系人电话') {
							lef.isShow2 = true;
							$("#cusM_mobile").blur(function() {
								lef.phone_verify();
							});
						} else if(row.necessary_item.trim() == '省份/州/区') {
							lef.isShow3 = true;
							$("#cusM_province").blur(function() {
								lef.province_verify();
							});
						} else if(row.necessary_item.trim() == '城市') {
							lef.isShow4 = true;
							$("#cusM_city").blur(function() {
								lef.city_verify();
							});
						} else if(row.necessary_item.trim() == '企业简称') {
							lef.isShow5 = true;
							$("#cusM_tomersimp").blur(function() {
								lef.sname_verify();
							});
						} else if(row.necessary_item.trim() == '手机') {
							lef.isShow6 = true;
							$("#cusM_mobilephone").blur(function() {
								lef.mobile_verify();
							});
						} else if(row.necessary_item.trim() == '职位') {
							lef.isShow7 = true;
							$("#cusM_post").blur(function() {
								lef.post_verify();
							});
						} else if(row.necessary_item.trim() == '传真') {
							lef.isShow8 = true;
							$("#cusM_fax").blur(function() {
								lef.fax_verify();
							});
						} else if(row.necessary_item.trim() == '网址') {
							lef.isShow9 = true;
							$("#cusM_website").blur(function() {
								lef.web_verify();
							});
						} else if(row.necessary_item.trim() == '地址') {
							lef.isShow10 = true;
							$("#cusM_address").blur(function() {
								lef.address_verify();
							});
						}
					});
					//判断是否只有所在国家和联系电话为必填项
					if(!this.isShow3 && !this.isShow4 && !this.isShow5 && !this.isShow6 &&
						!this.isShow7 && !this.isShow8 && !this.isShow9 && !this.isShow10) {
						this.showW = false;
					} else {
						this.showW = true;
					}
				}
			},
			//编辑客户信息
			editCusInfo() {
				this.$emit('changeState'); //触发父组件的方法
			},
			 submitCusInfo(){
					this.confirmLoad=true
					this.loadingTitle='正在添加客户'
						//验证客户全称
					if(!this.companyTure){
						this.cusname_varify();
					}
					//验证邮箱
					if(!this.emailTrue){
						this.email_verify()
					}
					if(this.emailTrue && this.companyTure && this.confirmLoad ){
						this.addAjax()
					}
			},
			//提交客户信息
			addAjax() {
				 this.loadingTitle='正在添加客户'
				var lef = this;
				 if (!this.correct1) {
					this.$message({
					type: "warning",
					showClose: true,
					message: this.errorTitle1,
					duration: 2000
					});
					return;
				}
				//验证姓名
				this.linkname_verify();
				if(!this.correct2) {
					this.$message({
						type: 'warning',
						showClose: true,
						message: this.errorTitle2,
						duration: 2000
					});
					return;
				}
				//验证邮箱
				if(!this.correct4) {
					this.$message({
						type: 'warning',
						showClose: true,
						message: this.errorTitle4,
						duration: 2000
					});
					return;
				}
				//判断企业设置的必填项
				if(this.isShow1) { //国家必填
					this.country_verify();
					if(!this.correct3) {
						this.$message({
							type: 'warning',
							showClose: true,
							message: this.errorTitle3,
							duration: 2000
						});
						return;
					}
				}
				if(this.isShow2) { //联系人电话必填
					this.country_verify();
					if(!this.correct4) {
						this.$message({
							type: 'warning',
							showClose: true,
							message: this.errorTitle4,
							duration: 2000
						});
						return;
					}
				}
				if(this.isShow3) { //省份/州/区必填
					this.province_verify();
					if(!this.correct6) {
						this.$message({
							type: 'warning',
							showClose: true,
							message: this.errorTitle6,
							duration: 2000
						});
						return;
					}
				}
				if(this.isShow4) { //城市必填
					this.city_verify();
					if(!this.correct7) {
						this.$message({
							type: 'warning',
							showClose: true,
							message: this.errorTitle7,
							duration: 2000
						});
						return;
					}
				}
				if(this.isShow5) { //企业简称必填
					this.sname_verify();
					if(!this.correct8) {
						this.$message({
							type: 'warning',
							showClose: true,
							message: this.errorTitle8,
							duration: 2000
						});
						return;
					}
				}
				if(this.isShow6) { //手机必填
					this.mobile_verify();
					if(!this.correct9) {
						this.$message({
							type: 'warning',
							showClose: true,
							message: this.errorTitle9,
							duration: 2000
						});
						return;
					}
				}
				if(this.isShow7) { //职位必填
					this.post_verify();
					if(!this.correct10) {
						this.$message({
							type: 'warning',
							showClose: true,
							message: this.errorTitle10,
							duration: 2000
						});
						return;
					}
				}
				if(this.isShow8) { //传真必填
					this.fax_verify();
					if(!this.correct11) {
						this.$message({
							type: 'warning',
							showClose: true,
							message: this.errorTitle11,
							duration: 2000
						});
						return;
					}
				}
				if(this.isShow9) { //网址必填
					this.web_verify();
					if(!this.correct12) {
						this.$message({
							type: 'warning',
							showClose: true,
							message: this.errorTitle12,
							duration: 2000
						});
						return;
					}
				}
				if(this.isShow10) { //地址必填
					this.address_verify();
					if(!this.correct13) {
						this.$message({
							type: 'warning',
							showClose: true,
							message: this.errorTitle13,
							duration: 2000
						});
						return;
					}
				}

				if(this.dateValue != '') {
					var myDate = new Date(this.dateValue)
					var year = myDate.getFullYear().toString();
					var month = (myDate.getMonth() + 1).toString();
					var day = myDate.getDate().toString();
					if(month.length == 1) {
						month = "0" + month;
					}
					if(day.length == 1) {
						day = "0" + day;
					}
					this.dateValue = year + "-" + month + "-" + day;
				}
				//输入的国家名称
				if($.trim($("#os_name").val()) != "") {
					if($.trim($("#os_name").val()).length > 200) {
						this.$message({
							type: 'warning',
							showClose: true,
							message: '国家不能超过200个字符！',
							duration: 2000
						});
						return;
					}
				}

				//输入的电话长度
				if($.trim(this.phone) != "") {
					if($.trim(this.phone).length > 100) {
						this.$message({
							type: 'warning',
							showClose: true,
							message: '电话不能超过100个字符！',
							duration: 2000
						});
						return;
					}
				}

				//输入的省份/州/区
				if($.trim(this.province) != "") {
					if($.trim(this.province).length > 100) {
						this.$message({
							type: 'warning',
							showClose: true,
							message: '省份/州/区不能超过100个字符！',
							duration: 2000
						});
						return;
					}
				}

				//输入的城市
				if($.trim(this.city) != "") {
					if($.trim(this.city).length > 100) {
						this.$message({
							type: 'warning',
							showClose: true,
							message: '城市不能超过100个字符！',
							duration: 2000
						});
						return;
					}
				}

				//输入的企业简称
				if($.trim(this.sname) != "") {
					if($.trim(this.sname).length > 50) {
						this.$message({
							type: 'warning',
							showClose: true,
							message: '企业简称不能超过50个字符！',
							duration: 2000
						});
						return;
					}
				}

				//输入的手机
				if($.trim(this.mobile) != "") {
					if($.trim(this.mobile).length > 50) {
						this.$message({
							type: 'warning',
							showClose: true,
							message: '手机号不能超过50个字符！',
							duration: 2000
						});
						return;
					}
				}

				//职位
				if($.trim(this.post) != "") {
					if($.trim(this.post).length > 50) {
						this.$message({
							type: 'warning',
							showClose: true,
							message: '职位不能超过50个字符！',
							duration: 2000
						});
						return;
					}
				}
				//传真
				if($.trim(this.fax) != "") {
					if($.trim(this.fax).length > 50) {
						this.$message({
							type: 'warning',
							showClose: true,
							message: '传真不能超过50个字符！',
							duration: 2000
						});
						return;
					}
				}
				//网址
				if($.trim(this.web) != "") {
					if($.trim(this.web).length > 100) {
						this.$message({
							type: 'warning',
							showClose: true,
							message: '网址不能超过100个字符！',
							duration: 2000
						});
						return;
					}
				}
				//地址
				if($.trim(this.address) != "") {
					if($.trim(this.address).length > 300) {
						this.$message({
							type: 'warning',
							showClose: true,
							message: '地址不能超过300个字符！',
							duration: 2000
						});
						return;
					}
				}
				var cusField = $(".addNum");
				var arr = [];
				if(cusField.length > 0) {
					var obj = {};
					var err = 0;
					for(var j = 0, len = cusField.length; j < len; j++) {
						obj = {};
						obj.name = cusField.eq(j).find(".medefinedname").val();
						obj.value = cusField.eq(j).find(".medefinedvalue").val();
						arr.push(obj);
						if((obj.name.trim().length > 0 && obj.value.trim().length == 0) ||
							(obj.name.trim().length == 0 && obj.value.trim().length > 0)) {
							lef.$message({
								showClose: true,
								message: '自定义字段名称和对应值必须同时填写!',
								type: 'warning',
								duration: 2000
							});
							err = 1;
							break;
						}
					}
					if(err == 1) {
						return;
					}
				}
				//自定义字段拼接
				var nameStr = "";
				if(arr.length != 0){
					$.each(arr,function(i,row){
						nameStr += row.name + "###" + row.value+ "@@G@@";
					})
					nameStr = nameStr.substring(0, nameStr.length - 5); //截取最后5个字符
				}
				//facebook
				var facebook = $("#facebook").val();
				if($.trim(facebook) != "") {
					if($.trim(facebook).length > 500) {
						this.$message({
							type: 'warning',
							showClose: true,
							message: 'facebook不能超过500个字符！',
							duration: 2000
						});
						return;
					}
				}
				//twitter
				var twitter = $("#twitter").val();
				if($.trim(twitter) != "") {
					if($.trim(twitter).length > 500) {
						this.$message({
							type: 'warning',
							showClose: true,
							message: 'twitter不能超过500个字符！',
							duration: 2000
						});
						return;
					}
				}
				//linkedin
				var linkedin = $("#linkedin").val();
				if($.trim(linkedin) != "") {
					if($.trim(linkedin).length > 500) {
						this.$message({
							type: 'warning',
							showClose: true,
							message: 'linkedin不能超过500个字符！',
							duration: 2000
						});
						return;
					}
				}
				//pinterest
				var pinterest = $("#pinterest").val();
				if($.trim(pinterest) != "") {
					if($.trim(pinterest).length > 500) {
						this.$message({
							type: 'warning',
							showClose: true,
							message: 'pinterest不能超过500个字符！',
							duration: 2000
						});
						return;
					}
				}
				//google
				var google = $("#google").val();
				if($.trim(google) != "") {
					if($.trim(google).length > 500) {
						this.$message({
							type: 'warning',
							showClose: true,
							message: 'google不能超过500个字符！',
							duration: 2000
						});
						return;
					}
				}
				//tumblr
				var tumblr = $("#tumblr").val();
				if($.trim(tumblr) != "") {
					if($.trim(tumblr).length > 500) {
						this.$message({
							type: 'warning',
							showClose: true,
							message: 'tumblr不能超过500个字符！',
							duration: 2000
						});
						return;
					}
				}
				//instagram
				var instagram = $("#instagram").val();
				if($.trim(instagram) != "") {
					if($.trim(instagram).length > 500) {
						this.$message({
							type: 'warning',
							showClose: true,
							message: 'instagram不能超过500个字符！',
							duration: 2000
						});
						return;
					}
				}
				//vk
				var vk = $("#vk").val();
				if($.trim(vk) != "") {
					if($.trim(vk).length > 500) {
						this.$message({
							type: 'warning',
							showClose: true,
							message: 'vk不能超过500个字符！',
							duration: 2000
						});
						return;
					}
				}
				//make_in_china
				var make_in_china = $("#make_in_china").val();
				if($.trim(make_in_china) != "") {
					if($.trim(make_in_china).length > 500) {
						this.$message({
							type: 'warning',
							showClose: true,
							message: 'make_in_china不能超过500个字符！',
							duration: 2000
						});
						return;
					}
				}
				//alibaba
				var alibaba = $("#alibaba").val();
				if($.trim(alibaba) != "") {
					if($.trim(alibaba).length > 500) {
						this.$message({
							type: 'warning',
							showClose: true,
							message: 'alibaba不能超过500个字符！',
							duration: 2000
						});
						return;
					}
				}
				//skype
				var skype = $("#skype").val();
				if($.trim(skype) != "") {
					if($.trim(skype).length > 500) {
						this.$message({
							type: 'warning',
							showClose: true,
							message: 'skype不能超过500个字符！',
							duration: 2000
						});
						return;
					}
				}
				//youtube
				var youtube = $("#youtube").val();
				if($.trim(youtube) != "") {
					if($.trim(youtube).length > 500) {
						this.$message({
							type: 'warning',
							showClose: true,
							message: 'youtube不能超过500个字符！',
							duration: 2000
						});
						return;
					}
				}
				var option = {
					url: backUrl + 'cus_updateCustomerInfo.action',
					dataType: 'json',
					// async: false,
					data: {
						token: token,
						"crmcustomer2.operate_time": lef.dateValue,
						"crmcustomer2.customer_field": nameStr,
						imgUrl: lef.customerInformation.img_url,
						dept_id: lef.userinfo.dept_id,
						old_cusemail: lef.oldLinkEmail,
						star: lef.customerInformation.important_star,
						fenlei: lef.customerInformation.classify_id, //客户分类
						chanpin: lef.customerInformation.prductid, //主营产品
						zhuantai: lef.customerInformation.customer_status, //业务状态
						guanxi: lef.customerInformation.partnership, //客户关系
						hangye: lef.customerInformation.calling, //所属行业
						laiyuan: lef.customerInformation.source_from_id //客户来源
					},
					beforeSend: function() {
						lef.crmPopupLoading=true
						},
						complete: function() {
						lef.crmPopupLoading=false
						},
					success: function(msg) {
						if(msg == 0) {
							lef.$message({
								type: 'warning',
								showClose: true,
								message: '编辑失败！',
								duration: 2000
							});
						} else {
							lef.hasVertify1 = false;
							lef.hasVertify2 = false;
							lef.hasVertify3 = false;
							lef.hasVertify4 = false;
							lef.hasVertify5 = false;
							lef.hasVertify6 = false;
							lef.hasVertify7 = false;
							lef.hasVertify8 = false;
							lef.hasVertify9 = false;
							lef.hasVertify10 = false;
							lef.hasVertify11 = false;
							lef.hasVertify12 = false;
							lef.hasVertify13 = false;
							lef.$message({
								type: 'success',
								showClose: true,
								message: '编辑成功！',
								duration: 2000
							});

							lef.getCustomerInformation($("#customer_id").val());
							lef.$emit('updateSuccess');
						}
					}
				};
				myAjaxSubmit('cus_updatecustomerinfoForm', option);
			},
			//验证客户全称
			cusname_varify(type) {
				if(this.cus_name.trim().length < 4 || this.cus_name.trim().length > 200) {
					this.hasVertify1 = true;
					this.correct1 = false;
					this.errorTitle1 = '企业全称4~200个字符之间!';
					this.$message({
						type: "warning",
						message:this.errorTitle1,
						duration: 2000
						});
					return;
				}
				if(type==0){
						this.loadingTitle='正在验证企业全称，请稍后！'
					}
				var lef = this;
				if(lef.oldCusName.trim() != lef.cus_name.trim()) {
					$.ajax({
						type: "POST",
						url: backUrl + "xcus_isCustomerNameExist2.action",
						dataType: "json",
						cache: false,
						// async: false,
						data: {
							token: token,
							verifystr: lef.cus_name.trim(),
							classify_id: lef.customerInformation.classify_id,
							sysenablerepeat: lef.companyparamlist.param159
						},
						 beforeSend: function() {
							lef.companyLoad=true
							},
							complete: function() {
							lef.companyLoad=false
							},
						success: function(msg) {
							if(msg.result == -1) { //不存在
								lef.hasVertify1 = true;
								lef.correct1 = true;
							} else { //存在
								if(lef.userlistall == '') {
									lef.getUserlistAll();
								}
								var username = "其它同事";
								if(msg.result==0){
									username='我'
								}else if(msg.result>0){
									$.each(lef.userlistall, function(index, user) {
									if(msg.result == user.user_id) {
										username = user.user_name;
										return false;
									}
									});
								}
								let tion=''
								if(msg.type==0){
									tion='正常库'
								}else if(msg.type==1){
									tion='公海'
								}else if(msg.type==2){
									tion='已删除'
								}
								lef.hasVertify1 = true;
								lef.correct1 = false;
								lef.errorTitle1 = '该企业全称对应的客户已存在于 [' + username + ' ]的客户中，位置['+tion+']';
								lef.$message({
									message:'该企业全称对应的客户已存在于 [' + username + ' ]的客户中，位置['+tion+']',
									type:'warning'
								})
								lef.confirmLoad=false
							}
						},
						error: function(e) {
							lef.hasVertify1 = true;
							lef.correct1 = false;
							lef.errorTitle1 = '检查企业全称重复性网络异常，请刷新界面后重试！';
							lef.confirmLoad=false
						}
					});
				} else {
					lef.hasVertify1 = true;
					lef.correct1 = true;
					lef.companyTure=true
					lef.confirmLoad=false
				}
			},
			//验证姓名
			linkname_verify() {
				if(this.link_name.trim().length < 2 || this.link_name.trim().length > 50) {
					this.hasVertify2 = true;
					this.correct2 = false;
					this.errorTitle2 = '联系人姓名2~50个字符之间!';
					return;
				}
				this.hasVertify2 = true;
				this.correct2 = true;
			},
			//验证邮箱
			email_verify(type) {
				var mailreg = /^\w+([\.\'-]?[\w+]?)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
				if(mailreg.test(this.email.trim())) {
					if(this.oldLinkEmail.trim() != this.email.trim()) {
						this.hasVertify4 = true;
						this.correct4 = true;
						var lef = this;
						if(type==0){
						this.loadingTitle='正在验证联系人邮箱，请稍后！'
						}
						$.ajax({
							type: "POST",
							url: backUrl + "xcus_isCustomerEmailExist2.action",
							dataType: "json",
							cache: false,
							// async: false,
							data: {
								token: token,
								verifystr: lef.email.trim(),
								classify_id: lef.customerInformation.classify_id,
								sysenablerepeat: lef.companyparamlist.param159
							},
							beforeSend: function() {
							lef.emailLoad=true
							},
							complete: function() {
							lef.emailLoad=false
							},
							success: function(msg) {
								if(msg.result == -1) { //不存在
									lef.hasVertify4 = true;
									lef.correct4 = true;
								} else { //存在
									if(lef.userlistall == '') {
										lef.getUserlistAll();
									}
									var username = "其它同事";
									 if(msg.result==0){
									username='我'
								}else if(msg.result>0){
									$.each(lef.userlistall, function(index, user) {
									if(msg.result == user.user_id) {
										username = user.user_name;
										return false;
									}
									});
								}
									lef.hasVertify4 = true;
									lef.correct4 = false;
									let tion=''
							if(msg.type==0){
									tion='正常库'
								}else if(msg.type==1){
									tion='公海'
								}else if(msg.type==2){
									tion='已删除'
								}
								lef.errorTitle4 = '该邮件地址对应的客户已存在于 [' + username + '] 的客户中，位置 ['+tion+']';
							lef.$message({
								showClose: true,
								message: '该邮件地址对应的客户已存在于 [' + username + '] 的客户中，位置 ['+tion+']',
								type: 'warning',
								duration: 2000
							});
							lef.confirmLoad=false
								}
							},
							error: function(e) {
								lef.hasVertify4 = true;
								lef.correct4 = false;
								lef.errorTitle4 = '检查联系人邮箱重复性网络异常，请刷新界面后重试！';
								lef.confirmLoad=false
							}
						});
					} else {
						this.hasVertify4 = true;
						this.correct4 = true;
						this.emailTrue=true
					}
				} else {
					this.hasVertify4 = true;
					this.correct4 = false;
					this.errorTitle4 = '联系人邮箱格式不正确!';
					this.$message({
					message:this.errorTitle4,
					type:'warning'
					})
					return;
				}
			},
			country_init() {
				this.country = $("#cusM_Country").val();
			},
			//验证国家
			country_verify() {
				if($("#os_name").val() != null && $("#os_name").val().trim().length == 0) {
					this.hasVertify3 = true;
					this.correct3 = false;
					this.errorTitle3 = '国家不能为空!';
					return;
				}
				this.hasVertify3 = true;
				this.correct3 = true;
			},
			//验证电话
			phone_verify() {
				if(this.phone != null && this.phone.trim().length == 0) {
					this.hasVertify5 = true;
					this.correct5 = false;
					this.errorTitle5 = '联系人电话不能为空!';
					return;
				}
				this.hasVertify5 = true;
				this.correct5 = true;
			},
			//验证省份
			province_verify() {
				if(this.province != null && this.province.trim().length == 0) {
					this.hasVertify6 = true;
					this.correct6 = false;
					this.errorTitle6 = '省份/州/区不能为空!';
					return;
				}
				this.hasVertify6 = true;
				this.correct6 = true;
			},
			//验证城市
			city_verify() {
				if(this.city != null && this.city.trim().length == 0) {
					this.hasVertify7 = true;
					this.correct7 = false;
					this.errorTitle7 = '城市不能为空!';
					return;
				}
				this.hasVertify7 = true;
				this.correct7 = true;
			},
			//验证企业简称
			sname_verify() {
				if(this.sname != null && this.sname.trim().length == 0) {
					this.hasVertify8 = true;
					this.correct8 = false;
					this.errorTitle8 = '企业简称不能为空!';
					return;
				}
				this.hasVertify8 = true;
				this.correct8 = true;
			},
			//验证手机
			mobile_verify() {
				if(this.mobile != null && this.mobile.trim().length == 0) {
					this.hasVertify9 = true;
					this.correct9 = false;
					this.errorTitle9 = '手机不能为空!';
					return;
				}
				this.hasVertify9 = true;
				this.correct9 = true;
			},
			//验证职位
			post_verify() {
				if(this.post != null && this.post.trim().length == 0) {
					this.hasVertify10 = true;
					this.correct10 = false;
					this.errorTitle10 = '职位不能为空!';
					return;
				}
				this.hasVertify10 = true;
				this.correct10 = true;
			},
			//验证传真
			fax_verify() {
				if(this.fax != null && this.fax.trim().length == 0) {
					this.hasVertify11 = true;
					this.correct11 = false;
					this.errorTitle11 = '传真不能为空!';
					return;
				}
				this.hasVertify11 = true;
				this.correct11 = true;
			},
			//验证网址
			web_verify() {
				if(this.web != null && this.web.trim().length == 0) {
					this.hasVertify12 = true;
					this.correct12 = false;
					this.errorTitle12 = '网址不能为空!';
					return;
				}
				this.hasVertify12 = true;
				this.correct12 = true;
			},
			//验证地址
			address_verify() {
				if(this.address != null && this.address.trim().length == 0) {
					this.hasVertify13 = true;
					this.correct13 = false;
					this.errorTitle13 = '地址不能为空!';
					return;
				}
				this.hasVertify13 = true;
				this.correct13 = true;
			},

			//添加自定义字段
			addfield(event) {
				var self = this;
				var target = $(event.currentTarget);
				var target_tr = target.parent().parent().siblings();
				if(target_tr.hasClass('addNum')) {
					this.counts++;
				} else {
					this.counts = 0;
				}
				var _html = "";
				_html = '<tr class="addNum" addNum="' + this.counts + '">' +
					'<td class="cu_tbg"><span>名称</span></td>' +
					'<td class="cu_tbg"><input type="text" class="custx medefinedname" value="" style="float: left;"/></td>' +
					'<td class="cu_tbg"><span>对应值</span></td>' +
					'<td class="cu_tbg"><input type="text" class="custx medefinedvalue" value="" style="float: left;"/>' +
					'<span class="o_recycle" id="addtrid' + this.counts + '" title="删除自定义字段" style="float: left;margin: 9px;"></span></td>' +
					'</tr>'
				target.parents().find(".myfield").after(_html);

				$("#addtrid" + this.counts).click(function(e) {
					var target2 = $(e.currentTarget).parent().parent();
					var addNum = target2.attr("addNum")
					target2.remove();
				})
			},
			//删除自定义字段
			removefield(e) {
				var target2 = $(e.currentTarget).parent().parent();
				target2.remove();
			}
		},
		mounted() {
			//初始化客户信息
			this.initCustomerInfo();
			// //加载国家
			// availableTags = [];
			// $.each(this.countryArr, function(index, row) {
			// 	availableTags.push(row.name);
			// })
			// autoComp();
			this.handleNecessary(); //处理必填项
			this.$store.commit('CUR_COUNTRY_EDIT',false)
		},
		watch:{
			'hasEditbtn':function(val){
				this.$store.commit('CUR_COUNTRY_EDIT',val)
			},
			 companyLoad:function(val){
				if(!val){
					this.companyTure=true
					if(this.emailTrue && this.companyTure && this.confirmLoad){
					this.addAjax()
					}
				}else{
					this.companyTure=false
				}
				},
				emailLoad:function(val){
				if(!val){
					this.emailTrue=true
					if(this.emailTrue && this.companyTure && this.confirmLoad){
					this.addAjax()
					}
				}else{
					this.emailTrue=false
				}
				}
		}
	})
</script>
<style lang="less">
	.createTab-content {
		.el-input__icon {
			right: 6px;
		}
	}
</style>
<style lang="less" scoped>
	.confirm-btn {
		font-size: 14px;
		height: 30px;
		cursor: pointer;
		padding: 6px 13px;
		display: inline-block;
		margin: 0px 37px 10px 5px;
		background: #20a0ff;
		border-radius: 2px;
		color: #fff;
		float: right;
		&:hover {
			background: #4db3ff;
			border-color: #4db3ff;
			color: #fff;
		}
	}
	
	.cu_tbg {
		width: 114px;
	}
	
	.createTab-content {
		.company-box {
			height: 280px;
			overflow: auto;
			//横向滚动不需要
			overflow-x: hidden;
		}
	}
</style>
<style>
	.o_add {
		user-select: none;
		cursor: pointer;
		display: inline-block;
		width: 12px;
		height: 12px;
		background: url("../../../../static/img/crm_images/icon/right.png");
	}
	
	.o_recycle {
		user-select: none;
		cursor: pointer;
		display: inline-block;
		width: 12px;
		height: 12px;
		background: url("../../../../static/img/crm_images/icon/wrong.png");
	}
</style>