<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：团队·组织架构·添加部门弹窗
开始时间：2017-8-8
开发人员：陈立珍
最后修改：2018-7-20
备注说明：如需修改请联系开发人员
-->

<template>
	<div>
		<el-dialog :title="loadingTitle" :visible.sync="loadingTest" size="tiny" class="oc-popup5 dialogOne" :before-close="loadingClose" :modal-append-to-body="true" :lock-scroll="true" :close-on-click-modal="false">
			<div style="width: 100%;height:100%;overflow:hidden;text-align: center;color:#20A0FF;">
				<i class="el-icon-loading " style="margin-top: 40px; font-size: 35px;display: block;"></i>
				<p style="line-height: 25px;">{{loadingContent}}</p>
				<p style="line-height: 25px;">请稍后...</p>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	export default {
		props: ['loadingTitle','loadingContent'],
		data() {
			return {
				loadingTest: true,

			}
		},
		methods: {
			loadingClose() {
				this.$emit('loadingClose');
			},
		},
		mounted() {
			function clearTransform() {
				$('.el-dialog').css({
					'transform': 'none',
				})
			};
			dialogDrag();
			clearTransform(dialogCenter());
		}
	}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
	.oc-popup5 .el-dialog {
		width: 400px;
	}
	.oc-popup5 .el-dialog__body {
		height: 200px;
	}
	.oc-popup5 .el-dialog__headerbtn {
		display: none;
	}
</style>